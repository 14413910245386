import { Types } from '@remarkable/rm-store-types';
import { CheckoutReducer } from 'src/typings/checkoutTypes';

export const START_CHECKOUT = 'AFFIRM/START_CHECKOUT';
export const CHECKOUT_FAILED = 'AFFRIM/CHECKOUT_FAILED';
export const CHECKOUT = 'AFFIRM/CHECKOUT';

export const checkout = (
  customer: CheckoutReducer.Customer,
  shippingAddress: CheckoutReducer.ShippingAddress,
  billingAddress: CheckoutReducer.BillingAddress,
  cart: Types.Store.Cart,
  currency: string,
  orderDetails: CheckoutReducer.State
) => ({
  type: CHECKOUT,
  customer,
  shippingAddress,
  billingAddress,
  cart,
  currency,
  orderDetails,
});

export const startCheckout = () => ({
  type: START_CHECKOUT,
});

export const checkoutFailed = () => ({
  type: CHECKOUT_FAILED,
});
