import { Button, Link, Typography } from '@remarkable/ark-web';
import { SlidersHorizontal } from 'phosphor-react';
import { useConsentBannerReducer } from 'src/components/CookieConsent/consentBannerState';

export const ConsentBannerDefaultView: React.FC<{ openConsentSettings(): void }> = ({ openConsentSettings }) => {
  const { dispatch } = useConsentBannerReducer();
  return (
    <div className={'my-24 md:my-48 xl:my-64 mx-24 md:mx-32 xl:mx-48'}>
      <Typography
        variant={'heading-subtle-lg'}
        as={'h1'}
        className={'text-center mb-16 max-md:mb-8 md:heading-subtle-xl'}
      >
        We value your privacy
      </Typography>
      <Typography variant={'body-md-book'} as={'p'} className={'text-center md:body-lg-book'}>
        We and our partners use cookies and similar technologies to personalize content and ads, provide social media
        features and analyze our traffic. See our{' '}
        <Link inline href={'https://support.remarkable.com/s/article/reMarkable-s-cookie-policy'} target={'_blank'}>
          cookie policy
        </Link>
        .
      </Typography>
      <div
        className={
          'flex flex-row max-sm:flex-col sm:justify-center md:justify-center mt-[32px] max-md:mt-24 max-sm:gap-16'
        }
      >
        <Button
          variant={'tertiary-neutral'}
          size={'medium'}
          className={'max-sm:order-2 max-sm:w-full max-md:mx-0 max-md:justify-center'}
          onClick={openConsentSettings}
        >
          <SlidersHorizontal />
          <Typography variant={'interface-md-demi'} className={'inline-block'}>
            Change settings
          </Typography>
        </Button>

        <Button
          size={'medium'}
          className={'ml-[24px] max-sm:w-full max-sm:mx-0 max-sm:order-1'}
          onClick={() => {
            dispatch({
              type: 'ACCEPT_ALL',
            });
          }}
        >
          Accept all
        </Button>
      </div>
    </div>
  );
};
