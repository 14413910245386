import { Adapters, Types } from '@remarkable/rm-store-types';
import { useSelector } from 'react-redux';
import { ALL_COUNTRIES } from 'src/data/countryData';
import { getCurrentStore } from 'src/state/store';
import { SanityCurrency } from 'src/typings/sanityTypes';
import { Logger } from 'src/utils/logger';

import * as regionsData from '../data/regionsData';
import { State } from '../redux/reducers';
import { getCountryInfoByValue } from './storeHelpers';

/**
 * Creates a price formatter that hides decimals if they are zero
 * @param locale formatting locale for currency
 * @param exponent number of decimal places in the currency
 */
function createTruncatedPriceFormatter(locale: string, exponent: number): Types.Common.PriceFormatterFunction {
  const hiddenDecimalFormatter = Adapters.Common.createLocalizedPriceFormatter(locale, exponent, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  const shownDecimalFormatter = Adapters.Common.createLocalizedPriceFormatter(locale, exponent);
  return function (amount, currency) {
    const fractionalAmount = amount / Math.pow(10, exponent);
    const hasFractionalUnits = Math.round(fractionalAmount) !== fractionalAmount;
    const truncatedPriceFormatter = hasFractionalUnits ? shownDecimalFormatter : hiddenDecimalFormatter;
    return truncatedPriceFormatter(amount, currency);
  };
}

export function formatLocalizedPrice(
  amount: number,
  lang: string,
  country: string,
  currency: { value: string; exponent: number },
  replaceZeroWithFree = false
) {
  if (amount === 0 && replaceZeroWithFree) {
    return 'Free';
  }

  const locale = `${lang}-${country}`;
  const formatter = createTruncatedPriceFormatter(locale, currency.exponent);
  return formatter(amount, currency.value).formatted;
}

export function useFormattedLocalizedPrice(amount: number, replaceZeroWithFree = true): string {
  const country = useSelector((state: State) => state.countryDetector.country);
  const sanityCountryData = useSelector((state: State) => state.staticQuery.countryData);
  const countryData = getCountryInfoByValue(sanityCountryData, country);
  return formatLocalizedPrice(amount, countryData.lang, country, countryData.currency, replaceZeroWithFree);
}

export function usePriceFormatter() {
  const country = useSelector((state: State) => state.countryDetector.country);
  const sanityCountryData = useSelector((state: State) => state.staticQuery.countryData);
  const countryData = getCountryInfoByValue(sanityCountryData, country);
  const locale = `${countryData.lang}-${countryData.value}`;
  const priceFormatter = createTruncatedPriceFormatter(locale, countryData.currency.exponent);
  return priceFormatter;
}

export function getCountryNameForCode(code: string) {
  const state = ALL_COUNTRIES.find((s) => s.code === code);
  if (state && state.name) {
    return state.name;
  }
  return null;
}

export function getCountryNameForCodeDefaultToUS(code: string) {
  return getCountryNameForCode(code) || 'United States';
}

export function getStateNameForStateCode(country: string, state: string) {
  const regions = regionsData[country];

  if (regions) {
    return regions[state];
  }
  return null;
}

export function getPhoneNumberCountryCode(country: string) {
  const sanityCountryData = getCurrentStore().getState().staticQuery.countryData;
  const data = getCountryInfoByValue(sanityCountryData, country);
  if (data) {
    return data.countryCode;
  }
}

export const formatPhoneNumber = (countryCode: string, phoneNumber: string) => {
  const fmtPhoneNumber = phoneNumber.replace(/\D+/gm, '');
  const fmtCountryCode = countryCode ? `+${countryCode}` : null;

  if (!countryCode) {
    Logger.warn({
      category: Logger.Category.BREADCRUMB_DEBUG,
      message: 'Phone number country code is undefined',
    });
  }

  if (phoneNumber.indexOf('+') === 0) {
    return fmtPhoneNumber;
  } else {
    return fmtCountryCode ? `${fmtCountryCode} ${fmtPhoneNumber}` : fmtPhoneNumber;
  }
};

export const isCountryNorthAmerica = (country: string) => ['US', 'CA'].includes(country);

export function getCurrencyForCountry(country: string): SanityCurrency | null {
  const sanityCountryData = getCurrentStore().getState().staticQuery.countryData;
  const countryData = getCountryInfoByValue(sanityCountryData, country);
  if (countryData) {
    return countryData.currency;
  }
  return null;
}

export function isRefExternal(ref: string) {
  if (ref) {
    const str = ref.toLowerCase();
    if (str.startsWith('https://')) {
      return true;
    } else if (str.startsWith('http://')) {
      return true;
    } else if (str.startsWith('//')) {
      return true;
    }
  }
  return false;
}

export function getValueFromLocationSearch(search: string, match: string) {
  if (search) {
    const clean = search.slice(1);
    const list = clean && clean.split('&');
    if (list && list.length > 0) {
      for (let i = 0; i <= list.length; i++) {
        const x = list[i] && list[i].split('=');
        if (x && x.length > 1 && x[0] === match) {
          return x[1];
        }
      }
    }
    return undefined;
  }
  return undefined;
}
