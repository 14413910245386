// import { pageType, pushVirtualPageViewToDataLayer } from 'src/services/googleTagManager';
import {
  eventActionEnum,
  eventCategoryEnum,
  pageType,
  pushGAEventToDataLayer,
  pushVirtualPageViewToDataLayer,
} from 'src/services/googleTagManager';

import { StoreActionTypes } from '../actions/storeActions2';

export interface State {
  cartVisible: boolean;
  showCountrySelector: boolean;
  referralCode: string;
  promotionCode: string;
  rm110ReferralDiscounts?: { currency: string; amount: number };
  connectPromotionDiscount?: Record<string, number>;
}

const initialState: State = {
  cartVisible: false,
  referralCode: '',
  promotionCode: '',
  showCountrySelector: false,
};

const actionsMap: {
  [key in string]: (state: State, action: any) => State;
} = {
  [StoreActionTypes.SET_REFERRAL_CODE]: (state: State, { referralCode }: State) => ({
    ...state,
    referralCode,
  }),
  [StoreActionTypes.SET_PROMOTION_CODE]: (state: State, { promotionCode }: State) => ({
    ...state,
    promotionCode,
  }),
  [StoreActionTypes.SET_RM110_REFERRAL_DISCOUNTS]: (state: State, { rm110ReferralDiscounts }: State) => ({
    ...state,
    rm110ReferralDiscounts,
  }),
  [StoreActionTypes.SET_PROMOTION_DISCOUNTS]: (state: State, { connectPromotionDiscount }: State) => ({
    ...state,
    connectPromotionDiscount,
  }),
  [StoreActionTypes.SET_CART_VISIBLE]: (state: State, { cartVisible }: State) => {
    if (cartVisible) {
      pushGAEventToDataLayer(eventCategoryEnum.PRODUCT_PAGE_NAVIGATION, eventActionEnum.NAVIGATION, 'Open cart');
      pushVirtualPageViewToDataLayer('/cart', pageType.CHECKOUT);
    } else {
      pushVirtualPageViewToDataLayer(window.location.pathname);
    }
    return {
      ...state,
      cartVisible,
    };
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (state = initialState, action: any = {}): State => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
