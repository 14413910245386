/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull remarkable_com_backend'
 *
 * Required dependencies: @amplitude/analytics-node@^1.0.0
 * Tracking Plan Version: 4
 * Build: 1.0.0
 * Runtime: node.js:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/remarkable/Store/implementation/remarkable_com_backend)
 */

import * as amplitude from '@amplitude/analytics-node';

export type NodeClient = amplitude.Types.NodeClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
export type NodeOptions = amplitude.Types.NodeOptions;

export type Environment = 'production' | 'dev';

export const ApiKey: Record<Environment, string> = {
  production: '',
  dev: '',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: NodeOptions = {
  plan: {
    version: '4',
    branch: 'main',
    source: 'remarkable_com_backend',
    versionId: '50e3e9f8-7a2d-41bc-bbf1-3cca82011a37',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'node.js-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
  serverZone: amplitude.Types.ServerZone.EU,
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: NodeOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: NodeOptions } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: NodeClient } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * array of objects \[{id: *exp1234, name: name of experiment: variant: CG*}\]
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  experiments?: {
    id?: string;
    name?: string;
    variant?: string;
  }[];
  /**
   * google ad id
   */
  gclid?: string;
  myrm_utm_campaign?: string;
  myrm_utm_content?: string;
  myrm_utm_id?: string;
  myrm_utm_medium?: string;
  myrm_utm_source?: string;
  myrm_utm_term?: string;
  myrm_utm_variant?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  utm_variant?: string;
}

export interface ButtonClickedProperties {
  /**
   * Used to highlight that a badge (i.e MOST POPULAR) was shown to the user, and that they selected a variant with that badge..
   */
  badge?: string;
  /**
   * Used to group different kinds of button types for easier analyzing. Purchase, Learn More, Men..,
   */
  button_type: string;
  /**
   * Important property describing what component on a page the user interacted with. For example: Header, footer, hero banner...
   */
  component_location: string;
  /**
   * the destination for the user when clicking a link
   */
  link_source?: string;
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  pathname?: string;
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  referrer?: string;
  sku?: string;
  /**
   * Text on button / links shown to the end user
   */
  text: string;
}

export interface BuyNowClickedProperties {
  button_type: string;
  /**
   * Important property describing what component on a page the user interacted with. For example: Header, footer, hero banner...
   */
  component_location: string;
  link_source?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
  text: string;
}

export interface CartInteractionProperties {
  /**
   * different types of actions a user can take, i.e for cart.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | checkout clicked, close, edit bundle, add to cart, decrease quantity, configure another bundle, delete bundle, open, increase quantity, delete item |
   */
  action:
    | 'checkout clicked'
    | 'close'
    | 'edit bundle'
    | 'add to cart'
    | 'decrease quantity'
    | 'configure another bundle'
    | 'delete bundle'
    | 'open'
    | 'increase quantity'
    | 'delete item';
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  cart_products: {
    /**
     * Result of splitting cart_products in Amplitude. Should not be set in frontend
     */
    name?: string;
    /**
     * Result of splitting cart_products in Amplitude. Should not be set in frontend
     */
    price?: number;
    /**
     * Result of splitting cart_products in Amplitude. Should not be set in frontend
     */
    price_formatted?: string;
    /**
     * Result of splitting cart_products in Amplitude. Should not be set in frontend
     */
    quantity?: number;
    /**
     * Result of splitting cart_products in Amplitude. Should not be set in frontend
     */
    sku?: string;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cart_value?: number;
  /**
   * Important property describing what component on a page the user interacted with. For example: Header, footer, hero banner...
   */
  component_location: string;
  link_source?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Generated at tracking backend
   */
  pathname?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  quantity?: number;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
  sku?: string;
}

export interface CheckoutStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  cart_value?: number;
  /**
   * Name of the step in the checkout funnel
   */
  checkout_step: string;
  /**
   * index / number of the checkout step (used in code)
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  checkout_step_number: number;
  currency: string;
  info_message?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  payment_method?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  products: {
    /**
     * Result of splitting Products in Amplitude. Should not be set from frontend
     */
    name?: string;
    /**
     * Result of splitting Products in Amplitude. Should not be set from frontend
     */
    price?: number;
    /**
     * Result of splitting Products in Amplitude. Should not be set from frontend
     */
    quantity?: number;
    /**
     * Result of splitting Products in Amplitude. Should not be set from frontend
     */
    sku?: string;
  }[];
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
  /**
   * What methods do we support to pay with?
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  supported_methods?: string[];
}

export interface CheckoutStepFailedProperties {
  error?: {
    message?: string;
    name?: string;
  };
  error_message?: string;
  error_name?: string;
  info_message: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
}

export interface ElementClickedProperties {
  /**
   * id of the element / html object being clicked.
   */
  element_id: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
}

export interface ExperimentAppliedProperties {
  /**
   * id of the experiment
   */
  experiment_id: string;
  /**
   * the variant a user was assigned to for the experiment. i.e CG / A / B
   */
  experiment_variant: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  referrer?: string;
}

export interface NavigateProperties {
  /**
   * different types of actions a user can take, i.e for cart.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | click, go back, close, filter, scroll, next, search, open |
   */
  action: 'click' | 'go back' | 'close' | 'filter' | 'scroll' | 'next' | 'search' | 'open';
  /**
   * Important property describing what component on a page the user interacted with. For example: Header, footer, hero banner...
   */
  component_location: string;
  link_source?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
  text?: string;
  /**
   * type of navigation
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | page, logo, menu, tab, link, review |
   */
  type: 'page' | 'logo' | 'menu' | 'tab' | 'link' | 'review';
}

export interface PageviewProperties {
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
}

export interface PaymentFailedProperties {
  card_brand?: string;
  charge_id?: string;
  decline_code?: string;
  error?: any;
  error_code?: string;
  error_message: string;
  error_type?: string;
  intent_id?: string;
  /**
   * Error message rasied from our codebase
   */
  internal_error_message: string;
  order_id?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  payment_intent_status?: string;
  payment_method?: string;
  payment_method_id?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
  /**
   * what payment provider (source) did we get a payment fail from?
   */
  source: string;
  /**
   * What methods do we support to pay with?
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  supported_methods?: string[];
}

export interface PaymentSucceededProperties {
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  $price?: string;
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  $quantity?: string;
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  $revenue?: string;
  country?: string;
  county?: string;
  currency: string;
  order_id: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  payment_method?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  products: {
    /**
     * Result of splitting Products in Amplitude. Should not be set from frontend
     */
    name?: string;
    /**
     * Result of splitting Products in Amplitude. Should not be set from frontend
     */
    price?: number;
    /**
     * Result of splitting Products in Amplitude. Should not be set from frontend
     */
    quantity?: number;
    /**
     * Result of splitting Products in Amplitude. Should not be set from frontend
     */
    sku?: string;
  }[];
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
  /**
   * This is not used for analytics in Amplitude, but used to send from frontend to our tracking SDK. Amplitude will parse this value and send it as the special $revenue property instead
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  revenue?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  shipping_cost: number;
  /**
   * taxable amount on payment succeeded events
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tax: number;
}

export interface ProductConfiguredProperties {
  /**
   * Used to highlight that a badge (i.e MOST POPULAR) was shown to the user, and that they selected a variant with that badge..
   */
  badge?: string;
  /**
   * Important property describing what component on a page the user interacted with. For example: Header, footer, hero banner...
   */
  component_location: string;
  language?: string;
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by tracking SDK, should not be set from frontend
   */
  pathname?: string;
  product_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | marker tip, cable, subscription, marker, folio, paper tablet |
   */
  product_type: 'marker tip' | 'cable' | 'subscription' | 'marker' | 'folio' | 'paper tablet';
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
  sku?: string;
  text?: string;
}

export interface VideoInteractionProperties {
  /**
   * different types of actions a user can take, i.e for cart.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | play, stop, search, fullscreen_open, fullscreen_close, mute, unmute |
   */
  action: 'play' | 'stop' | 'search' | 'fullscreen_open' | 'fullscreen_close' | 'mute' | 'unmute';
  /**
   * Important property describing what component on a page the user interacted with. For example: Header, footer, hero banner...
   */
  component_location: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  origin?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  pathname?: string;
  /**
   * Automatically added by the tracking SDK, should not be set from frontend
   */
  referrer?: string;
  /**
   * what payment provider (source) did we get a payment fail from?
   */
  source: string;
  title: string;
  /**
   * Video playtime when interaction occured. Unit `seconds`
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  view_time?: number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class ButtonClicked implements BaseEvent {
  event_type = 'Button Clicked';

  constructor(public event_properties: ButtonClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class BuyNowClicked implements BaseEvent {
  event_type = 'Buy Now Clicked';

  constructor(public event_properties: BuyNowClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class CartInteraction implements BaseEvent {
  event_type = 'Cart Interaction';

  constructor(public event_properties: CartInteractionProperties) {
    this.event_properties = event_properties;
  }
}

export class CheckoutStepCompleted implements BaseEvent {
  event_type = 'Checkout Step Completed';

  constructor(public event_properties: CheckoutStepCompletedProperties) {
    this.event_properties = event_properties;
  }
}

export class CheckoutStepFailed implements BaseEvent {
  event_type = 'Checkout Step Failed';

  constructor(public event_properties: CheckoutStepFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class ElementClicked implements BaseEvent {
  event_type = 'Element Clicked';

  constructor(public event_properties: ElementClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperimentApplied implements BaseEvent {
  event_type = 'Experiment Applied';

  constructor(public event_properties: ExperimentAppliedProperties) {
    this.event_properties = event_properties;
  }
}

export class Navigate implements BaseEvent {
  event_type = 'Navigate';

  constructor(public event_properties: NavigateProperties) {
    this.event_properties = event_properties;
  }
}

export class Pageview implements BaseEvent {
  event_type = 'Pageview';

  constructor(public event_properties?: PageviewProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentFailed implements BaseEvent {
  event_type = 'Payment Failed';

  constructor(public event_properties: PaymentFailedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentSucceeded implements BaseEvent {
  event_type = 'Payment Succeeded';

  constructor(public event_properties: PaymentSucceededProperties) {
    this.event_properties = event_properties;
  }
}

export class ProductConfigured implements BaseEvent {
  event_type = 'Product Configured';

  constructor(public event_properties: ProductConfiguredProperties) {
    this.event_properties = event_properties;
  }
}

export class VideoInteraction implements BaseEvent {
  event_type = 'Video Interaction';

  constructor(public event_properties: VideoInteractionProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: NodeClient;

  get client(): NodeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param userId The user's id.
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(userId: string | undefined, event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    return this.amplitude!.track(event, undefined, options);
  }

  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Button Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Button%20Clicked)
   *
   * User clicked a button
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. badge)
   * @param options Amplitude event options.
   */
  buttonClicked(
    userId: string | undefined,
    properties: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new ButtonClicked(properties), options);
  }

  /**
   * Buy Now Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Buy%20Now%20Clicked)
   *
   * User clicked a "Buy Now" button. These buttons takes a user into the purchase funnel
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. button_type)
   * @param options Amplitude event options.
   */
  buyNowClicked(
    userId: string | undefined,
    properties: BuyNowClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new BuyNowClicked(properties), options);
  }

  /**
   * Cart Interaction
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Cart%20Interaction)
   *
   * The user interacted with cart. Add product to cart, open cart, close cart, increase/decrease quantity++.
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  cartInteraction(
    userId: string | undefined,
    properties: CartInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new CartInteraction(properties), options);
  }

  /**
   * Checkout Step Completed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Checkout%20Step%20Completed)
   *
   * User performed a step in the checkout funnel
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. cart_value)
   * @param options Amplitude event options.
   */
  checkoutStepCompleted(
    userId: string | undefined,
    properties: CheckoutStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new CheckoutStepCompleted(properties), options);
  }

  /**
   * Checkout Step Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Checkout%20Step%20Failed)
   *
   * User experienced an error when checking out
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. error)
   * @param options Amplitude event options.
   */
  checkoutStepFailed(
    userId: string | undefined,
    properties: CheckoutStepFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new CheckoutStepFailed(properties), options);
  }

  /**
   * Element Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Element%20Clicked)
   *
   * Not every click is tracked explicitly. Some of the components on the website track clicks, but have not been assigned to a specific event with properties. This is a catch all for all elements with an ID that are not explicitly tracked. An example is a user clicking the fields when inputting shipping info on the checkout pages.
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. element_id)
   * @param options Amplitude event options.
   */
  elementClicked(
    userId: string | undefined,
    properties: ElementClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new ElementClicked(properties), options);
  }

  /**
   * Experiment Applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Experiment%20Applied)
   *
   * This event means that the application has applied some variation of A/B experiment because new or old experiment cookies have been discovered.
   *
   * Normally experiment variant is recorded in user properties.
   * This event is needed so that user that opens page, sees experiment and leaves page without producing more tracking event is still registered with the experiment.
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. experiment_id)
   * @param options Amplitude event options.
   */
  experimentApplied(
    userId: string | undefined,
    properties: ExperimentAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new ExperimentApplied(properties), options);
  }

  /**
   * Navigate
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Navigate)
   *
   * Navigation covers several navigation types, it can be clicking on links, navigating in a menu, moving to the next page in the configuration flow...
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  navigate(
    userId: string | undefined,
    properties: NavigateProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new Navigate(properties), options);
  }

  /**
   * Pageview
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Pageview)
   *
   * A page was loaded / refreshed and shown to the user
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. origin)
   * @param options Amplitude event options.
   */
  pageview(
    userId: string | undefined,
    properties?: PageviewProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new Pageview(properties), options);
  }

  /**
   * Payment Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Payment%20Failed)
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. card_brand)
   * @param options Amplitude event options.
   */
  paymentFailed(
    userId: string | undefined,
    properties: PaymentFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new PaymentFailed(properties), options);
  }

  /**
   * Payment Succeeded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Payment%20Succeeded)
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. $price)
   * @param options Amplitude event options.
   */
  paymentSucceeded(
    userId: string | undefined,
    properties: PaymentSucceededProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new PaymentSucceeded(properties), options);
  }

  /**
   * Product Configured
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Product%20Configured)
   *
   * Event triggered when configuring a product, selecting variant in MPF ++.
   *
   * Owner: data@amplitude.com
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. badge)
   * @param options Amplitude event options.
   */
  productConfigured(
    userId: string | undefined,
    properties: ProductConfiguredProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new ProductConfigured(properties), options);
  }

  /**
   * Video Interaction
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/remarkable/Store/events/main/latest/Video%20Interaction)
   *
   * Event has no description in tracking plan.
   *
   * @param userId The user's ID.
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  videoInteraction(
    userId: string | undefined,
    properties: VideoInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(userId, new VideoInteraction(properties), options);
  }
}

export const ampli = new Ampli();
