import {
  Actions,
  POST_FAILED,
  POST_REQUESTED,
  POST_SUCCEEDED,
  SET_DATA,
  SHOW,
} from '../actions/noShippingModalActions';

export interface State {
  show: boolean;
  isNoShipping: boolean;
  country: string;
  isLoading: boolean;
  success?: boolean;
}

const initialState: State = {
  show: false,
  isNoShipping: false,
  country: '',
  isLoading: false,
  success: undefined,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_DATA: {
      return { ...state, ...action.payload };
    }
    case POST_REQUESTED: {
      return { ...state, isLoading: true };
    }
    case POST_SUCCEEDED: {
      return { ...state, isLoading: false, success: true };
    }
    case POST_FAILED: {
      return { ...state, isLoading: false, success: false };
    }
    case SHOW: {
      return { ...state, ...action.payload, success: action.payload.show ? undefined : state.success };
    }
    default: {
      return state;
    }
  }
}
