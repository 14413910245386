import { saveState, StorageKeys } from 'src/helpers/localStorage';
import { getNewCart } from 'src/helpers/MPFCartHelpers';

import { MpfState } from '../../typings/mpfTypes';
import { MPFActionTypes } from '../actions/managedPurchaseFlowActions';

const initialState: MpfState = {
  cartInitiated: false,
  currentStep: 0,
  editingBundleSku: null,
  quantity: 1,
  cart: { skus: ['RM110'] },
  stepValidations: {
    '1': {
      optOut: false,
    },
    '2': {
      optOut: false,
    },
  },
};

export default function reducer(state = initialState, action): MpfState {
  switch (action.type) {
    case MPFActionTypes.RESET_MPF_STATE: {
      const newState = {
        ...state,
        editingBundleSku: null,
        cart: getNewCart(),
      };
      saveState(StorageKeys.MPF_CONFIG_021020, newState);
      return newState;
    }

    case MPFActionTypes.SET_MPF_EDIT_MODE: {
      const newState = {
        ...state,
        editingBundleSku: action.editingBundleSku,
      };
      saveState(StorageKeys.MPF_CONFIG_021020, newState);
      return newState;
    }

    default: {
      return state;
    }
  }
}
