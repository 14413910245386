const plugin = require('tailwindcss/plugin');
// add svg in here if possible?

module.exports = plugin(({ addComponents, theme }) => {
  addComponents({
    '[data-clp-theme="green"]': {
      '--clp-color-50': theme('colors.brand.green.50'),
      '--clp-color-100': theme('colors.brand.green.100'),
      '--clp-color-200': theme('colors.brand.green.200'),
      '--clp-color-300': theme('colors.brand.green.300'),
      '--clp-color-400': theme('colors.brand.green.400'),
      '--clp-color-500': theme('colors.brand.green.500'),
      '--clp-color-600': theme('colors.brand.green.600'),
      '--clp-color-700': theme('colors.brand.green.700'),
      '--clp-color-800': theme('colors.brand.green.800'),
      '--clp-color-900': theme('colors.brand.green.900'),
    },
    '[data-clp-theme="blue"]': {
      '--clp-color-50': theme('colors.brand.blue.50'),
      '--clp-color-100': theme('colors.brand.blue.100'),
      '--clp-color-200': theme('colors.brand.blue.200'),
      '--clp-color-300': theme('colors.brand.blue.300'),
      '--clp-color-400': theme('colors.brand.blue.400'),
      '--clp-color-500': theme('colors.brand.blue.500'),
      '--clp-color-600': theme('colors.brand.blue.600'),
      '--clp-color-700': theme('colors.brand.blue.700'),
      '--clp-color-800': theme('colors.brand.blue.800'),
      '--clp-color-900': theme('colors.brand.blue.900'),
    },
    '[data-clp-theme="gray"]': {
      '--clp-color-50': theme('colors.grayscale.gray.50'),
      '--clp-color-100': theme('colors.grayscale.gray.100'),
      '--clp-color-200': theme('colors.grayscale.gray.200'),
      '--clp-color-300': theme('colors.grayscale.gray.300'),
      '--clp-color-400': theme('colors.grayscale.gray.400'),
      '--clp-color-500': theme('colors.grayscale.gray.500'),
      '--clp-color-600': theme('colors.grayscale.gray.600'),
      '--clp-color-700': theme('colors.grayscale.gray.700'),
      '--clp-color-800': theme('colors.grayscale.gray.800'),
      '--clp-color-900': theme('colors.grayscale.gray.900'),
    },
    '[data-clp-theme="sand"]': {
      // for simplicity, Sand theme wants to go from light root to dark sections,
      // so we can just flip so that clp-900 = sand.50 => clp-50 = sand.900
      // This is so that the function colors.txt.getNextContrastLevel, dosent have to take into account
      // if theme is sand (where we go from light to dark, instead of dark to light).
      '--clp-color-50': theme('colors.brand.sand.900'),
      '--clp-color-100': theme('colors.brand.sand.800'),
      '--clp-color-200': theme('colors.brand.sand.700'),
      '--clp-color-300': theme('colors.brand.sand.600'),
      '--clp-color-400': theme('colors.brand.sand.500'),
      '--clp-color-500': theme('colors.brand.sand.400'),
      '--clp-color-600': theme('colors.brand.sand.300'),
      '--clp-color-700': theme('colors.brand.sand.200'),
      '--clp-color-800': theme('colors.brand.sand.100'),
      '--clp-color-900': theme('colors.brand.sand.50'),
    },
    '.clp-50': { backgroundColor: 'var(--clp-color-50)' },
    '.clp-100': { backgroundColor: 'var(--clp-color-100)' },
    '.clp-200': { backgroundColor: 'var(--clp-color-200)' },
    '.clp-300': { backgroundColor: 'var(--clp-color-300)' },
    '.clp-400': { backgroundColor: 'var(--clp-color-400)' },
    '.clp-500': { backgroundColor: 'var(--clp-color-500)' },
    '.clp-600': { backgroundColor: 'var(--clp-color-600)' },
    '.clp-700': { backgroundColor: 'var(--clp-color-700)' },
    '.clp-800': { backgroundColor: 'var(--clp-color-800)' },
    '.clp-900': { backgroundColor: 'var(--clp-color-900)' },
  });
});
