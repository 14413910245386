export const US = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const US_SHIPPING = [
  { abbreviation: 'AL', name: 'Alabama', hasSalesTax: false },
  { abbreviation: 'AK', name: 'Alaska', hasSalesTax: false },
  { abbreviation: 'AZ', name: 'Arizona', hasSalesTax: false },
  { abbreviation: 'AR', name: 'Arkansas', hasSalesTax: false },
  { abbreviation: 'CA', name: 'California', hasSalesTax: true },
  { abbreviation: 'CO', name: 'Colorado', hasSalesTax: true },
  { abbreviation: 'CT', name: 'Connecticut', hasSalesTax: true },
  { abbreviation: 'DE', name: 'Delaware', hasSalesTax: false },
  { abbreviation: 'DC', name: 'District of Columbia', hasSalesTax: false },
  { abbreviation: 'FL', name: 'Florida', hasSalesTax: true },
  { abbreviation: 'GA', name: 'Georgia', hasSalesTax: true },
  { abbreviation: 'HI', name: 'Hawaii', hasSalesTax: false },
  { abbreviation: 'ID', name: 'Idaho', hasSalesTax: false },
  { abbreviation: 'IL', name: 'Illinois', hasSalesTax: true },
  { abbreviation: 'IN', name: 'Indiana', hasSalesTax: true },
  { abbreviation: 'IA', name: 'Iowa', hasSalesTax: false },
  { abbreviation: 'KS', name: 'Kansas', hasSalesTax: false },
  { abbreviation: 'KY', name: 'Kentucky', hasSalesTax: false },
  { abbreviation: 'LA', name: 'Louisiana', hasSalesTax: false },
  { abbreviation: 'ME', name: 'Maine', hasSalesTax: false },
  { abbreviation: 'MD', name: 'Maryland', hasSalesTax: true },
  { abbreviation: 'MA', name: 'Massachusetts', hasSalesTax: true },
  { abbreviation: 'MI', name: 'Michigan', hasSalesTax: true },
  { abbreviation: 'MN', name: 'Minnesota', hasSalesTax: true },
  { abbreviation: 'MS', name: 'Mississippi', hasSalesTax: false },
  { abbreviation: 'MO', name: 'Missouri', hasSalesTax: false },
  { abbreviation: 'MT', name: 'Montana', hasSalesTax: false },
  { abbreviation: 'NE', name: 'Nebraska', hasSalesTax: false },
  { abbreviation: 'NV', name: 'Nevada', hasSalesTax: false },
  { abbreviation: 'NH', name: 'New Hampshire', hasSalesTax: false },
  { abbreviation: 'NJ', name: 'New Jersey', hasSalesTax: true },
  { abbreviation: 'NM', name: 'New Mexico', hasSalesTax: false },
  { abbreviation: 'NY', name: 'New York', hasSalesTax: true },
  { abbreviation: 'NC', name: 'North Carolina', hasSalesTax: true },
  { abbreviation: 'ND', name: 'North Dakota', hasSalesTax: false },
  { abbreviation: 'OH', name: 'Ohio', hasSalesTax: false },
  { abbreviation: 'OK', name: 'Oklahoma', hasSalesTax: true },
  { abbreviation: 'OR', name: 'Oregon', hasSalesTax: false },
  { abbreviation: 'PA', name: 'Pennsylvania', hasSalesTax: true },
  { abbreviation: 'RI', name: 'Rhode Island', hasSalesTax: true },
  { abbreviation: 'SC', name: 'South Carolina', hasSalesTax: false },
  { abbreviation: 'SD', name: 'South Dakota', hasSalesTax: false },
  { abbreviation: 'TN', name: 'Tennessee', hasSalesTax: false },
  { abbreviation: 'TX', name: 'Texas', hasSalesTax: true },
  { abbreviation: 'UT', name: 'Utah', hasSalesTax: true },
  { abbreviation: 'VT', name: 'Vermont', hasSalesTax: false },
  { abbreviation: 'VA', name: 'Virginia', hasSalesTax: true },
  { abbreviation: 'WA', name: 'Washington', hasSalesTax: true },
  { abbreviation: 'WV', name: 'West Virginia', hasSalesTax: false },
  { abbreviation: 'WI', name: 'Wisconsin', hasSalesTax: true },
  { abbreviation: 'WY', name: 'Wyoming', hasSalesTax: false },
];

export const CA = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Québec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};
