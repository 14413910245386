import { Adapters, Types } from '@remarkable/rm-store-types';
import { getFlattenedItems } from 'src/helpers/cartHelpers';
import { CheckoutReducer } from 'src/typings/checkoutTypes';
import { MoltinTypes } from 'src/typings/moltinTypes';

export const getFormattedOrderItems = (cart: Types.Store.Cart) => {
  const discardPriceFormatter: Types.Common.PriceFormatterFunction = (amount, currency) => ({
    amount,
    currency,
    formatted: '',
  });
  return getFlattenedItems(cart, discardPriceFormatter)
    .filter((item) => !Adapters.Moltin.Products.isShipping(item) && item.price.unit.amount > 0)
    .map((item) => ({
      display_name: item.name,
      item_image_url: item.image,
      item_url: '',
      qty: item.quantity,
      sku: item.sku,
      unit_price: item.price.unit.amount,
    }));
};

export const getShippingAddress = (shipping: CheckoutReducer.ShippingAddress) => ({
  city: shipping.city.value,
  country: shipping.country.value,
  line1: shipping.addressLine1.value,
  line2: shipping.addressLine2.value,
  state: shipping.state.value,
  zipcode: shipping.zipCode.value,
});

export const getBillingAddress = (
  billing: CheckoutReducer.BillingAddress,
  shipping: CheckoutReducer.ShippingAddress
) => {
  if (billing.billingAddressSameAsShipping.value) {
    return getShippingAddress(shipping);
  }
  return {
    city: billing.billingCity.value,
    country: billing.billingCountry.value,
    line1: billing.billingAddressLine1.value,
    line2: billing.billingAddressLine2.value,
    state: billing.billingState.value,
    zipcode: billing.billingZipCode.value,
  };
};

export const getBillingAddressName = (
  billing: CheckoutReducer.BillingAddress,
  shipping: CheckoutReducer.ShippingAddress
) => {
  if (billing.billingAddressSameAsShipping.value) {
    return {
      first: shipping.firstName.value,
      last: shipping.lastName.value,
    };
  }
  return {
    first: billing.billingFirstName.value,
    last: billing.billingLastName.value,
  };
};

export const getPromotion = (cart: Types.Store.Cart) => {
  // TODO: update this if/when multiple discounts are supported
  const promotionItem = cart.discounts.applied[0];
  if (promotionItem) {
    return {
      Promo: {
        discount_amount: Math.abs(promotionItem.price.amount),
        discount_display_name: promotionItem.name,
      },
    };
  }
  return undefined;
};

export const getShipping = (cart: Types.Store.Cart) => {
  return cart.shipping?.selected?.price.amount ?? 0;
};

export const getCheckoutObject = async (
  customer: CheckoutReducer.Customer,
  shipping: CheckoutReducer.ShippingAddress,
  billing: CheckoutReducer.BillingAddress,
  cart: Types.Store.Cart,
  moltinCheckoutResponse: MoltinTypes.CheckoutResponse
) => {
  if (window.affirm) {
    const orderId = moltinCheckoutResponse.data?.id as string;
    const siteURL = `${window.location.protocol}//${window.location.hostname}:${window.location.port || ''}`;
    const backendURL = process.env.NEXT_PUBLIC_RM_STORE_API_URL;
    const confirmationURL = `${siteURL}/store/order-confirmation?orderId=${orderId}&type=affirm`;
    const failureURL = `${siteURL}/store/checkout/affirm_failed`;
    const cancelURL = `${siteURL}/checkout/payment`;

    return window.affirm.checkout({
      merchant: {
        name: 'reMarkable',
        user_cancel_url: cancelURL,
        user_confirmation_url: `${backendURL}/affirm?success_redirect=${encodeURIComponent(
          confirmationURL
        )}&fail_redirect=${encodeURIComponent(failureURL)}/`,
        user_confirmation_url_action: 'POST',
      },
      shipping: {
        address: getShippingAddress(shipping),
        email: customer.email.value,
        name: {
          first: shipping.firstName.value,
          last: shipping.lastName.value,
        },
        phone_number: customer.phoneNumber.value,
      },
      billing: {
        address: getBillingAddress(billing, shipping),
        name: getBillingAddressName(billing, shipping),
      },
      items: getFormattedOrderItems(cart),
      discounts: getPromotion(cart),
      order_id: orderId,
      shipping_amount: getShipping(cart),
      tax_amount: cart.tax.amount,
      total: moltinCheckoutResponse?.data?.meta.display_price.with_tax.amount,
    });
  }
};

export const checkout = () => {
  if (window.affirm) {
    window.affirm.checkout.open();
  }
};

export function updateAffirmWidget() {
  // console.log('Update affirm widget...');
  if (window.affirm && window.affirm.ui && window.affirm.ui.refresh) {
    window.affirm.ui.refresh();
  }
}
