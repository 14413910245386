import { createContext, Dispatch, useContext } from 'react';
import { isBrowser } from 'src/utils';

/**
 * Consent is stored in localStorage.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 */

const CONSENT_BANNER_LOCAL_STORAGE_KEY = 'rmCookieConsent';

export type ConsentBannerReducerState = {
  cookieGroupsConsentState: {
    necessary: boolean;
    performance: boolean;
    marketing: boolean;
  };
  // False until user has clicked on 'Accept cookies' or 'Save cookie settings'
  consentAccepted: boolean;
};

const setConsentStateInLocalStorage: (state: ConsentBannerReducerState) => void = (state) =>
  window.localStorage.setItem(CONSENT_BANNER_LOCAL_STORAGE_KEY, JSON.stringify(state));

export const readConsentBannerReducerStateFromLocalStorage: () => ConsentBannerReducerState | null = () => {
  if (!isBrowser()) {
    return null;
  }
  return JSON.parse(window.localStorage.getItem(CONSENT_BANNER_LOCAL_STORAGE_KEY) ?? 'null');
};

export const defaultConsentBannerReducerState: ConsentBannerReducerState = {
  consentAccepted: false,
  cookieGroupsConsentState: {
    necessary: true,
    performance: true,
    marketing: true,
  },
};

type ConsentBannerAction =
  | {
      type: 'UPDATE_COOKIE_GROUPS_CONSENT_STATE';
      // Subset of cookieGroupsConsentState properties to overwrite
      payload: Partial<ConsentBannerReducerState['cookieGroupsConsentState']>;
    }
  | {
      type: 'ACCEPT_ALL';
    }
  | {
      type: 'SAVE_CONSENT_SETTINGS';
    };

export const consentBannerReducer: (
  state: ConsentBannerReducerState,
  action: ConsentBannerAction
) => ConsentBannerReducerState = (state, action) => {
  if (action.type === 'UPDATE_COOKIE_GROUPS_CONSENT_STATE') {
    return {
      ...state,
      // Note that 'necessary' cookie group is always enabled
      cookieGroupsConsentState: { ...state.cookieGroupsConsentState, ...action.payload, necessary: true },
    };
  }
  if (action.type === 'ACCEPT_ALL') {
    return {
      ...state,
      cookieGroupsConsentState: {
        necessary: true,
        marketing: true,
        performance: true,
      },
      consentAccepted: true,
    };
  }
  if (action.type === 'SAVE_CONSENT_SETTINGS') {
    return {
      ...state,
      consentAccepted: true,
    };
  }
  return state;
};

// Does same as consentBannerReducer, but also saves state in local storage
export const consentBannerReducerWithLocalStorage: typeof consentBannerReducer = (state, action) => {
  const nextState = consentBannerReducer(state, action);
  setConsentStateInLocalStorage(nextState);
  return nextState;
};

export const ConsentBannerStateProvider = createContext<{
  state: ConsentBannerReducerState;
  dispatch: Dispatch<ConsentBannerAction>;
}>({ state: defaultConsentBannerReducerState, dispatch: Function.prototype as Dispatch<ConsentBannerAction> });

export const useConsentBannerReducer = () => useContext(ConsentBannerStateProvider);
