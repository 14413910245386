import { CaptureConsole } from '@sentry/integrations';

const dsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const debug = !!process.env.SENTRY_DEBUG || !!process.env.NEXT_PUBLIC_SENTRY_DEBUG;
// Enable Sentry in environment=`production`, environment=`preview` or when `SENTRY_DEBUG` is set
const enabled =
  debug ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ||
  process.env.VERCEL_ENV == 'production' ||
  process.env.VERCEL_ENV == 'preview';
const environment = (process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV) ?? 'local';

export default {
  dsn,
  environment,
  // Attach error stack traces to events
  attachStacktrace: true,
  integrations: (integrations) =>
    [
      // Skip capturing uncaught exceptions and breadcrumbs (handled in src/pages/_app.tsx)
      ...integrations.filter(function (integration) {
        return !['GlobalHandlers', 'Breadcrumbs'].includes(integration.name);
      }),
      // Capture errors thrown through console, useful for triggering errors anywhere
      new CaptureConsole({
        levels: ['error']
      })
    ],
  // Depth of nested objects to keep in event contexts
  normalizeDepth: 10,
  // Only send a portion of error events to Sentry to limit
  sampleRate: debug ? 1.0 : 0.1,
  enabled,
  debug,
  // Filter out info events
  beforeSend(event) {
    return event?.level == 'info' ? null : event;
  },
};
