/** @type {import('tailwindcss').Config} */

module.exports = {
  presets: [require('@remarkable/ark-web/preset')],
  content: ['./src/**/*.{js,jsx,ts,tsx}', './node_modules/@remarkable/ark-web/dist/*.js'],
  // https://tailwindcss.com/docs/theme#configuration-reference
  theme: {
    extend: {
      colors: {
        current: 'currentColor',
        transparent: 'transparent',
        white: '#FFFFFF',
        black: '#000000',
        dark: '#222222',
        light: '#EFEFEF',
        materials: {
          'polymer-weave': '#B3B3B3',
          'brown-leather': '#996849',
          'black-leather': '#4E4E4E',
        },
      },
      letterSpacing: {
        wide: '0.025em',
        wider: '0.05em',
        widest: '0.1em',
      },
      opacity: {
        0: 0,
        10: 0.1,
        20: 0.2,
        50: 0.5,
        60: 0.6,
        95: 0.95,
        100: 1,
      },
      animation: {
        'bounce-slow': 'bounce 3s infinite',
        'scroll-icon': 'initial-move 0.5s 4s forwards, jump 2s 4.5s ease-out infinite',
        'hero-content-fade-in': 'hero-fade-in 0.7s 6.8s forwards',
        'cart-spinner': 'cart-spinner-fade-in 0.4s cubic-bezier(0.25,0.46,0.45,0.94) both',
        'fade-in': 'fade-in 300ms 0s forwards',
        'fade-out': 'fade-out 300ms 0s forwards',
        'fade-in-order-confirmation': 'fade-in 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both',
        'security-screen': 'security-screen 0.4s 1.5s forwards',
        'security-screen-touch': 'security-screen-touch 0.4s var(--delay,0s)',
        'security-screen-input': 'security-screen-input 0.15s var(--delay,0s) forwards',
        'fade-in-modal': 'fade-in-modal 0.5s forwards',
        'fade-out-inline': 'fade-out-inline 0.5s forwards',
        'fade-out-modal': 'fade-out-modal 0.5s forwards',
      },
      keyframes: {
        'fade-in-order-confirmation': {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
        'security-screen': {
          from: { opacity: '1' },
          to: { opacity: '0' },
        },
        'security-screen-input': {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
        'security-screen-touch': {
          '0%, 100%': { opacity: 0 },
          '50%': { opacity: 1 },
        },
        'initial-move': {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(3px)' },
        },
        jump: {
          '0%, 100%': { transform: 'translateY(3px)' },
          '50%': { transform: 'translateY(-3px)' },
        },
        'hero-fade-in': {
          from: { opacity: '0', top: '5px' },
          to: { opacity: '1', top: '-5px' },
        },
        'cart-spinner-fade-in': {
          from: { opacity: '0' },
          to: { opacity: '0.7' },
        },
        'fade-in': {
          from: { opacity: '0', visibility: 'hidden' },
          to: { opacity: '1', visibility: 'visible' },
        },
        'fade-out': {
          from: { opacity: '1', visibility: 'visible' },
          to: { opacity: '0', visibility: 'hidden' },
        },
        'fade-in-modal': {
          to: {
            display: 'flex',
            opacity: '1',
            transform: 'translateY(0)',
          },
        },
        'fade-out-inline': {
          to: {
            opacity: '0',
            transform: 'translateY(10px)',
          },
        },
        'fade-out-modal': {
          to: {
            opacity: '0',
            display: 'none',
          },
        },
      },
      // boxShadow values is only used in checkout
      // TODO: This should be removed when the checkout form is using ark-web input fields
      boxShadow: {
        'default-input': 'inset 0 0 0 1px rgba(86, 86, 86, 1)',
        'error-input': 'inset 0 0 0 2px rgba(220, 95, 73, 1)',
        'valid-input': 'inset 0 0 0 1px rgba(104, 183, 129, 1)',
        'carousel-button': 'inset 0 0 0 0 0px white',
      },
    },
  },
  plugins: [
    require('./src/components/CustomLandingPage/clp.plugin'),
    require('./src/components/FeedbackContent/feedbackContentTheme.plugin'),
    require('./src/components/PromotionBanner/promotionBannerTheme.plugin'),
    require('./src/components/PillNav/pillNavTheme.plugin'),
  ],
  corePlugins: {
    preflight: true,
  },
};
