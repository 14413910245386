import { Switch, Typography } from '@remarkable/ark-web';

export const CookieGroupControl: React.FC<{
  name: string;
  description: string;
  checked: boolean;
  controlDisabled?: boolean;
  updateChecked?(newValue: boolean): void;
}> = ({ name, description, checked, controlDisabled, updateChecked }) => {
  return (
    <div className={'mt-24 mb-32'}>
      <div className={'flex justify-between mb-8'}>
        <Typography variant={'interface-md-demi'} className="leading-[initial]">
          {name}
        </Typography>
        <Switch
          disabled={controlDisabled ?? false}
          className={'w-48'}
          checked={checked}
          onChange={(e) => {
            if (updateChecked !== undefined) {
              updateChecked(e.target.checked);
            }
          }}
        />
      </div>
      <Typography variant={'body-sm-book'} className={'md:pr-[84px]'}>
        {description}
      </Typography>
    </div>
  );
};
