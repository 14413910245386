const AMPLITUDE_DEVICE_ID_COOKIE = 'rmMetaV2-device_id';

export const getAmplitudeLink = () => {
  const cookie = document.cookie;

  const amplitudeDeviceId = cookie.split(';').find((c) => c.trim().startsWith(AMPLITUDE_DEVICE_ID_COOKIE));

  if (amplitudeDeviceId) {
    const deviceId = amplitudeDeviceId.split('=')[1];
    return deviceId
      ? `https://analytics.eu.amplitude.com/remarkable/project/100001435/search/${deviceId}?deviceIdFilter=${deviceId}`
      : null;
  }

  return null;
};
