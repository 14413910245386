import { DetailedHTMLProps, HTMLAttributes } from 'react';

export const DevButton = ({
  children,
  className = '',
  active = false,
  ...rest
}: DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  active?: boolean;
}) => {
  const activeClasses = active
    ? 'border-solid border-[#222222] border-grayscale-gray-800 bg-grayscale-gray-800 text-white hover:bg-grayscale-gray-900 '
    : 'border-solid border-[#b5b5b5] bg-grayscale-gray-50 hover:bg-grayscale-gray-100 ';

  return (
    <button className={'rounded border p-16 ' + activeClasses + className} {...rest}>
      {children}
    </button>
  );
};
