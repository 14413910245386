import { ChartBar } from 'phosphor-react';
import { FC, useState } from 'react';
import { AppContainer } from 'src/helpers/devtool/components/AppContainer';
import { DevButton } from 'src/helpers/devtool/components/DevButton';
import { getAmplitudeLink } from 'src/helpers/devtool/utils/getAmplitudeLink';
import { useAppKeyboardToggle } from 'src/helpers/devtool/utils/useAppKeyboardToggle';
import { DevtoolsAppStateContext } from './stores/appStore';

const DevtoolApp = () => {
  useAppKeyboardToggle();

  const openAmplitude = () => {
    const amplitudeLink = getAmplitudeLink();
    if (!amplitudeLink) return;

    window.open(amplitudeLink);
  };

  return (
    <AppContainer>
      <DevButton onClick={openAmplitude}>
        <ChartBar weight="fill" />
      </DevButton>
    </AppContainer>
  );
};

const DevtoolAppWithContext: FC = () => {
  const [isAppVisible, setIsAppVisible] = useState(false);
  return (
    <DevtoolsAppStateContext.Provider
      value={{
        isAppVisible,
        setIsAppVisible,
      }}
    >
      <DevtoolApp />
    </DevtoolsAppStateContext.Provider>
  );
};

export { DevtoolAppWithContext as DevtoolsApp };
