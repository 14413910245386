import { useCallback, useEffect } from 'react';
import { isToggleAppEvent } from 'src/helpers/devtool/config/keyboard';
import { useDevtoolsAppState } from 'src/helpers/devtool/stores/appStore';

export const useAppKeyboardToggle = () => {
  const { isAppVisible, setIsAppVisible } = useDevtoolsAppState();

  // Listen to ctrl+d or ctrl+m keypress
  const onKeyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (isToggleAppEvent(event)) {
        setIsAppVisible(!isAppVisible);
      }
    },
    [isAppVisible, setIsAppVisible]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDownHandler);
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [onKeyDownHandler]);
};
