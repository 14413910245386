import { NextRouter } from 'next/router';
import { UseCartAPI } from 'src/hooks/cart/useCart';

export const isBrowser: () => boolean = () => typeof window !== 'undefined';

export const groupBy: <T>(array: T[], key: string) => Record<string, T[]> = (array, key) =>
  array.reduce((acc, curr) => {
    (acc[curr[key]] = acc[curr[key]] || []).push(curr);
    return acc;
  }, {});

const refurbishedMultipleDiscountDisclaimer = (
  <p data-cy="no-discounts-message">
    Refurbished devices <strong>does not trigger multiple discount.</strong>
  </p>
);

const radioLicenseDisclaimer = (
  <p>
    If purchasing multiple devices, <strong>you must have an import license</strong>, as defined by the Radio Waves Act.
    This also applies to orders being processed and shipped, so make sure that you only have one active order if you
    don't have an import license.
  </p>
);

const valueLimitDisclaimer = (
  <p>
    Please register with customs for clearance if the value of your order exceeds ฿40,000. Visit support.remarkable.com
    for more information.
  </p>
);

const poBoxDisclaimer = <p>Please note that we don’t deliver to PO boxes or addresses within free-trade zones (FZ).</p>;

const infoBoxDivider = (
  <div className="my-16 border border-b-0 border-l-0 border-r-0 border-solid border-grayscale-gray-100 broder-t-1"></div>
);

export const getInfoBoxContent = (
  country: string,
  deviceLimit: {
    limit: number;
    disclaimer: string | null;
  } | null,
  activeReferral: boolean | null,
  cart: UseCartAPI
): JSX.Element | null => {
  const refurbDeviceQuantity = cart.helpers.getNumberOfRerubishedDevicesInCart();
  const totalDeviceQuantity = cart.helpers.getNumberOfNewDevicesInCart() + refurbDeviceQuantity;

  // Construct array of applicable messages for given cart
  const infoBoxMessages = [
    deviceLimit && totalDeviceQuantity >= deviceLimit.limit ? deviceLimit.disclaimer : undefined,
    refurbDeviceQuantity > 1 ? refurbishedMultipleDiscountDisclaimer : undefined,
    country === 'KR' && totalDeviceQuantity > 1 ? radioLicenseDisclaimer : undefined,
    country === 'TH' ? valueLimitDisclaimer : undefined,
    country === 'AE' || country == 'QA' ? poBoxDisclaimer : undefined,
  ];

  const separatedMessages = infoBoxMessages
    .filter((msg) => !!msg)
    // Insert a divider in between each message, and remove the leading divider with slice
    .flatMap((msg) => [infoBoxDivider, msg])
    .slice(1);

  return separatedMessages.length ? <>{separatedMessages}</> : null;
};

export const getAbsoluteUrl = (router: NextRouter): string => {
  // TODO: Make base path an .env variable
  return 'https://remarkable.com' + router.asPath;
};
