/**
 * Executes promises passed in a serial ordering (queue). This prevents out-of-order requests.
 * Currently needed for cart requests to ensure atomicity. Should be removed in the future.
 * Returns a new promise that will remain unresolved until all promises before it in the queue
 * and the current promise are resolved. Resolves with the return value of provided thunk
 */
export const promiseInOrder = (function () {
  type QueueEntry<T> = {
    resolver: () => Promise<T>;
    started: boolean;
    result?: Promise<T>;
  };
  const queueMap: { [key: string]: QueueEntry<any>[] } = {};
  return async function <T>(queueKey: string, thunk: () => Promise<T>): Promise<T> {
    const newPromiseIdx = queueMap[queueKey]?.length ?? 0;
    queueMap[queueKey] = queueMap[queueKey] ?? [];
    queueMap[queueKey].push({
      resolver: thunk,
      started: false,
      result: undefined,
    });
    // Wait until all promises in queue before this one are resolved
    // (can be either fulfilled _or_ rejected -- doesn't matter)
    for (let i = 0; i <= newPromiseIdx; i++) {
      const task = queueMap[queueKey][i];
      if (!task.started) {
        task.started = true;
        task.result = task.resolver();
      }
      try {
        await task.result;
      } catch (_) {}
    }
    return await queueMap[queueKey][newPromiseIdx].result;
  };
})();
