import { connectPPSku, connectSku } from 'src/helpers/connectHelpers';

export enum deviceVariants {
  NEW = 'New',
  REFURBISHED = 'Refurbished',
}
export enum markerVariants {
  MARKER = 'Marker',
  MARKER_PLUS = 'Marker plus',
}

export enum markerTipsVariants {
  RM1_WHITE = 'Marker tips white',
  RM1_BLACK = 'Marker tips black',
}

export const limitations = {
  defaultProductMax: 24,
};

export const ALL_DEVICE_SKUS = [
  'RM110', // reMarkable 2
  'RM111', // reMarkable 2 Refurbished
] as const;

export const ALL_MARKER_SKUS = [
  'RM210', // Marker
  'RM212', // Marker Plus
] as const;

export const ALL_MARKER_TIP_SKUS = [
  'RM410', // 9x Tips
  'RM413', // 25x Tips refill
] as const;

export const TYPE_FOLIO_SKUS = [
  'RM710', // Type Folio - Sepia Brown - English US
  'RM711', // Type Folio - Sepia Brown - English UK
  'RM712', // Type Folio - Sepia Brown - German DE
  'RM713', // Type Folio - Sepia Brown - Spanish ES
  'RM714', // Type Folio - Sepia Brown - French FR
  'RM715', // Type Folio - Sepia Brown - Nordic SE/DK/NO/FI
  'RM810', // Type Folio - Ink Black - English US
  'RM811', // Type Folio - Ink Black - English UK
  'RM812', // Type Folio - Ink Black - German DE
  'RM813', // Type Folio - Ink Black - Spanish ES
  'RM814', // Type Folio - Ink Black - French FR
  'RM815', // Type Folio - Ink Black - Nordic SE/DK/NO/FI
] as const;

export const ORDINARY_FOLIO_SKUS = [
  'RM312', // Book folio, leather black
  'RM313', // Book folio, leather brown
  'RM314', // Book folio, weave
  'RM310', // Folio, polymer gray
] as const;

// Order matters when selecting default SKU for grouped picker (model picker) in the MPF
export const ALL_FOLIO_SKUS = [...ORDINARY_FOLIO_SKUS, ...TYPE_FOLIO_SKUS] as const;

export const ALL_CABLE_SKUS = [
  'RM610', // USB-A
  'RM612', // USB-C
] as const;

export type DeviceSkus = (typeof ALL_DEVICE_SKUS)[number];
export type MarkerSkus = (typeof ALL_MARKER_SKUS)[number];
export type MarkerTipSkus = (typeof ALL_MARKER_TIP_SKUS)[number];
export type FolioSkus = (typeof ALL_FOLIO_SKUS)[number];
export type CableSkus = (typeof ALL_CABLE_SKUS)[number];

export type TypeFolioSkus = Extract<
  FolioSkus,
  'RM710' | 'RM711' | 'RM712' | 'RM713' | 'RM714' | 'RM715' | 'RM810' | 'RM811' | 'RM812' | 'RM813' | 'RM814' | 'RM815'
>;

export type OrdinaryFolioSkus = Extract<FolioSkus, 'RM310' | 'RM312' | 'RM313' | 'RM314'>;

export type DeviceVariant = DeviceSkus;
export type MarkerVariant = MarkerSkus | 'none';
export type FolioVariant = FolioSkus | 'none';

export const FolioVariantNames: { [key in FolioVariant]: string } = {
  RM310: 'Folio - polymer gray',
  RM312: 'Book folio - leather black',
  RM313: 'Book folio - leather brown',
  RM314: 'Book folio - weave',
  RM710: 'Type Folio - Sepia Brown - English US',
  RM711: 'Type Folio - Sepia Brown - English UK',
  RM712: 'Type Folio - Sepia Brown - German DE',
  RM713: 'Type Folio - Sepia Brown - Spanish ES',
  RM714: 'Type Folio - Sepia Brown - French FR',
  RM715: 'Type Folio - Sepia Brown - Nordic SE/DK/NO/FI',
  RM810: 'Type Folio - Ink Black - English US',
  RM811: 'Type Folio - Ink Black - English UK',
  RM812: 'Type Folio - Ink Black - German DE',
  RM813: 'Type Folio - Ink Black - Spanish ES',
  RM814: 'Type Folio - Ink Black - French FR',
  RM815: 'Type Folio - Ink Black - Nordic SE/DK/NO/FI',
  none: 'no folio',
};

export type ConnectPPSku = typeof connectPPSku;
export type ConnectSku = typeof connectSku;
