import { Component } from 'react';
import { Logger } from 'src/utils/logger';

export class ConsentBannerErrorBoundary extends Component<object, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    Logger.error({
      category: Logger.Category.CONSENT_BANNER_ERROR,
      message: 'Consent banner has encountered an error and is removed from the DOM',
      exception: error,
    });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}
