export const YOTPO_SCRIPT_LOAD_START = 'YOTPO_SCRIPT_LOAD_START';
export const YOTPO_SCRIPT_LOAD_END = 'YOTPO_SCRIPT_LOAD_END';
export const YOTPO_SCRIPT_LOAD = 'YOTPO_SCRIPT_LOAD';

export const yotpoScriptLoadStart = () => ({
  type: YOTPO_SCRIPT_LOAD_START,
});

export const yotpoScriptLoadEnd = () => ({
  type: YOTPO_SCRIPT_LOAD_END,
});

export const yotpoScriptLoad = () => ({
  type: YOTPO_SCRIPT_LOAD,
});
