export const compareObjects = (value: any, expectation: any) => {
  let equal = true;

  const compareLevel = (a, b) => {
    for (const i in a) {
      if (!b.hasOwnProperty(i)) {
        equal = false;
        return;
      } else {
        if (typeof a[i] === 'object') {
          compareLevel(a[i], b[i]);
        }
      }
    }
  };

  compareLevel(value, expectation);

  return equal;
};
