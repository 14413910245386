export enum MPFActionTypes {
  SET_MPF_EDIT_MODE = 'MPF/SET_EDIT_MODE',
  RESET_MPF_STATE = 'MPF/RESET_MPF_STATE',
}

export function setMPFEditBundle(bundleSku: string | null) {
  return {
    type: MPFActionTypes.SET_MPF_EDIT_MODE,
    editingBundleSku: bundleSku,
  };
}

export function resetMPFState() {
  return {
    type: MPFActionTypes.RESET_MPF_STATE,
  };
}
