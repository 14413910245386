import 'src/styles/tailwind.css';

import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { useApplySmoothScrollPolyfill } from 'src/hooks/useApplySmoothScrollPolyfill';
import { useTracking } from 'src/hooks/useTracking';
import { CommonProviderData } from 'src/queries/groq/commonProviderData';
import { Logger } from 'src/utils/logger';

import { Analytics } from '@vercel/analytics/react';
import { ConsentBanner } from 'src/components/CookieConsent/ConsentBanner';
import { FEATURE_CONSENT_2023_ENABLED } from 'src/components/CookieConsent/featureFlag';
import { DevtoolsApp } from 'src/helpers/devtool/DevtoolApp';
import { CommonProvider } from '../contexts/CommonProvider';
import { pushVirtualPageViewToDataLayer } from '../services/googleTagManager';

const App: React.FC<AppProps<{ commonProviderData: CommonProviderData }>> = ({ Component, pageProps }) => {
  useTracking();
  const router = useRouter();

  // Register common entry for all uncaught errors
  useEffect(() => {
    return Logger.registerUnhandledExceptionHandler();
  }, []);

  // Handle side-effects for route changes & initial load
  useEffect(() => {
    /** Fires when a route changed completely */
    function onRouteChangeComplete(url: string) {
      pushVirtualPageViewToDataLayer(url);
    }

    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => router.events.off('routeChangeComplete', onRouteChangeComplete);
  }, [router]);
  useApplySmoothScrollPolyfill();

  const commonProviderData = pageProps?.commonProviderData;
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

  return (
    /** NB: Error boundary should always be at the top */
    <Logger.ErrorBoundary>
      {isProduction && (
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
        </Script>
      )}
      <CommonProvider commonProviderData={commonProviderData}>
        <Component {...pageProps} />
        {!isProduction && process.env.NEXT_PUBLIC_RM_DEVTOOL === 'true' && <DevtoolsApp />}
        {FEATURE_CONSENT_2023_ENABLED && <ConsentBanner />}
      </CommonProvider>
      <Analytics />
    </Logger.ErrorBoundary>
  );
};

export default App;
