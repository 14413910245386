import React, { useEffect } from 'react';
import { useDevtoolsAppState } from 'src/helpers/devtool/stores/appStore';

export const AppContainer = ({ children }: { children: React.ReactNode }) => {
  const { isAppVisible, setIsAppVisible } = useDevtoolsAppState();

  // Auto hide the app when clicking outside, unless it is pinned
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isAppVisible) {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setIsAppVisible(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [ref, isAppVisible, setIsAppVisible]);

  const classes = isAppVisible
    ? 'opacity-100 [&>*]:pointer-events-auto' // Allow pointer events on all children
    : 'opacity-0 -translate-x-32';

  return (
    <div
      className={
        'pointer-events-none fixed left-0 top-0 z-[9999] flex h-full flex-col items-start justify-center gap-4 px-8 pb-8 pt-[72px] transition-all ' +
        classes
      }
      ref={ref}
    >
      {children}
    </div>
  );
};
