import { useEffect } from 'react';

/**
 * Hook to lock the scroll behind drawers and modals
 * @param isOpen (boolean)
 */
export const useLockScroll = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);
};
