import * as Sentry from '@sentry/nextjs';
import * as React from 'react';
import { State } from 'src/redux/reducers';
import { getCurrentStore } from 'src/state/store';

import { getCartId } from '../hooks/cart/util';

export enum Category {
  UNCAUGHT_EXCEPTION = 'Uncaught Exception',
  SENTRY_CAPTURE = 'Capture Sentry Event',
  API_REQUEST = 'API Request',
  CHECKOUT = 'Checkout',
  ADD_TO_CART = 'Add to cart',
  ORDER_CONFIRMATION = 'Order Confirmation',
  RECEIPT_LOADER = 'Receipt Loader',
  REDUX_SAGAS = 'Redux Sagas',
  GOOGLE_TAG_MANAGER = 'Google Tag Manager',
  MANAGED_PURCHASE_FLOW = 'Managed Purchase Flow',
  CART_OPERATION = 'Cart Operation',
  /* Breadcrumb types */
  BREADCRUMB_DEFAULT = 'breadcrumb_default',
  BREADCRUMB_DEBUG = 'breadcrumb_debug',
  BREADCRUMB_ERROR = 'breadcrumb_error',
  BREADCRUMB_NAVIGATION = 'breadcrumb_navigation',
  BREADCRUMB_HTTP = 'breadcrumb_http',
  BREADCRUMB_UI = 'breadcrumb_ui',

  MPF = 'MPF',
  PRODUCT_PAGES = 'product_pages',
  MISSING_CONTEXT_PROVIDER = 'missing_context_provider',
  CONSENT_BANNER_ERROR = 'consent_banner_error',
}

export enum Severity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}

/**
 * Basic list of known object key names that contain PII
 */
export const PII_OBJECT_KEYS = [
  /* Name keys */
  'name',
  'first',
  'last',
  'first_name',
  'firstName',
  'last_name',
  'lastName',
  'full_name',
  'fullName',
  /* Street address keys */
  'street',
  'address',
  'line1',
  'line2',
  'line3',
  'line4',
  'line_1',
  'line_2',
  'line_3',
  'line_4',
  'address_line_1',
  'addressLine1',
  'address_line_2',
  'addressLine2',
  'address_line_3',
  'addressLine3',
  'address_line_4',
  'addressLine4',
  /* Location keys */
  'lat',
  'latitude',
  'long',
  'longitude',
  /* Email keys */
  'email',
  /* Phone number keys */
  'phone',
  'phone_number',
  'phoneNumber',
  'number',
  /* Stripe keys */
  'card',
  'last4',
  'cvv',
  /* User data keys */
  'birth',
  'birth_date',
  'birth_year',
  'birth_month',
  'birth_day',
  'birthday',
];

/**
 * Basic list of Regex matchers that check for PII values in strings
 */
export const PII_STRING_MATCHERS = [
  /* Basic email matcher */
  /^\S+@\S+\.\S+$/,
  /* Basic phone number matcher */
  /^(\+)?([-()]*[ 0-9][-()]*){7,16}$/,
];

/**
 * Intentionally basic recursive PII remover based on JSON key names and basic Regex detection
 * @param input Value to strip PII from
 * @returns Value with PII stripped out
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function basicStripPII(input: any) {
  if (Array.isArray(input)) {
    // Recurse deeply into arrays
    return input.map(basicStripPII);
  } else if (typeof input === 'object' && input !== null) {
    // Recurse deeply into objects, redacting keys that commonly contain PII
    const transformedEntries = Object.entries(input).map(([key, value]) => {
      if (PII_OBJECT_KEYS.some((piiKey) => key.toLowerCase().includes(piiKey.toLowerCase()))) {
        return [key, '<REDACTED>'];
      } else {
        return [key, basicStripPII(value)];
      }
    });
    return Object.fromEntries(transformedEntries);
  } else if (typeof input === 'string') {
    // String base-case
    for (const matcher of PII_STRING_MATCHERS) {
      if (matcher.test(input)) {
        return '<REDACTED>';
      }
    }
    return input;
  } else {
    // All other values pass through as-is
    return input;
  }
}

type CaptureContextAny = {
  [key: string]: unknown;
};
type CaptureContextNavigation = {
  from: string;
  to: string;
};
type CaptureContextHttp = {
  url?: string;
  method?: string;
  status_code?: string;
  reason?: string;
};

export type CaptureOptions<C extends Category> = {
  category: C;
  message: string;
  severity: Severity;
  exception?: Error;
  context?: C extends Category.BREADCRUMB_HTTP
    ? CaptureContextHttp
    : C extends Category.BREADCRUMB_NAVIGATION
    ? CaptureContextNavigation
    : CaptureContextAny;
  tags?: {
    [key: string]: number | string | boolean | undefined;
  };
};

/**
 * Captures a log event and returns a throwable error
 * @effect Sends the event to Sentry
 * @effect Logs the event to the browser console
 * @param options Event configuration
 * @returns Either the provided exception or a newly created exception object
 */
function capture<C extends Category = Category>(options: CaptureOptions<C>): Error {
  const error = new Error();
  error.name = options.category.toString();
  error.message =
    options.exception?.message && options.exception?.message !== options.message
      ? `${options.message}: ${options.exception?.message}`
      : options.message;
  error.stack =
    options.exception?.stack ??
    error.stack
      ?.split('\n')
      /* Remove this utility function call from the stack frame for readability */
      .filter((_, idx, frames) => idx !== frames.findIndex((f) => /:\d+:\d+/gi.test(f)))
      .join('\n');

  // Retrieve current redux state to attach to event
  let currentReduxState: State | undefined;
  try {
    currentReduxState = getCurrentStore().getState();
  } catch (_) {}

  // Define event's context leading up to capture
  const originalEventContext = {
    ['Original Exception']: options.exception ?? error,
    ['Cart']: {
      id: getCartId(),
    },
    ['Redux State']: basicStripPII({
      checkout: currentReduxState?.checkout,
      countryDetector: currentReduxState?.countryDetector,
    }),
    ...(options.context && {
      ['Event Context']: basicStripPII({
        ...options.context,
      }),
    }),
  };
  let eventContext: typeof originalEventContext | Record<string, never> = originalEventContext;

  // Events larger than 1MB silently fail to send to Sentry. Prevent this possibility.
  if (JSON.stringify(eventContext).length > 50000) {
    eventContext = {};
    Sentry?.withScope((scope) => {
      const error = new Error();
      error.name = Category.SENTRY_CAPTURE;
      error.message = 'Event context too large to attach';
      scope.setLevel(Severity.Warning);
      Sentry?.captureException(error);
    });
  }

  // Send event to sentry
  if (options.category.startsWith('breadcrumb_')) {
    const breadcrumbType = options.category.replace('breadcrumb_', '');
    Sentry?.addBreadcrumb({
      type: breadcrumbType,
      category: breadcrumbType,
      message: error.message,
      data: options.context,
      level: options.severity,
    });
  } else {
    Sentry?.withScope((scope) => {
      scope.setLevel(options.severity);
      for (const [name, value] of Object.entries(eventContext)) {
        scope.setContext(name, value);
      }
      for (const [name, value] of Object.entries(options.tags ?? {})) {
        scope.setTag(name, value);
      }
      scope.setTag('cartId', getCartId());
      scope.setTag('country', currentReduxState?.countryDetector.country);
      scope.setTag('region', currentReduxState?.countryDetector.region);
      scope.setTag(
        'currency',
        currentReduxState?.staticQuery.countryData.find((c) => c.value === currentReduxState?.countryDetector.country)
          ?.currency.value
      );
      scope.setTag('framework', 'nextjs');
      scope.setTag('userEnvironment', 'client');
      Sentry?.captureException(error);
    });
  }

  // Send event to the console for development
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    const consoleLogger =
      {
        [Severity.Info]: console.info,
        [Severity.Warning]: console.warn,
        [Severity.Error]: console.error,
      }[options.severity] ?? console.log;
    consoleLogger(error, eventContext);
  }

  // Return either the provided exception, or the newly created error
  return options.exception ?? error;
}

/**
 * Logs an info event and returns a throwable error
 * @effect Sends the event to Sentry
 * @effect Logs the event to the browser console
 * @param options Event configuration
 * @returns Either the provided exception or a newly created exception object
 */
export function info<C extends Category = Category>(options: Omit<CaptureOptions<C>, 'severity'>): Error {
  return capture({ ...options, severity: Severity.Info });
}
/**
 * Logs a warning event and returns a throwable error
 * @effect Sends the event to Sentry
 * @effect Logs the event to the browser console
 * @param options Event configuration
 * @returns Either the provided exception or a newly created exception object
 */
export function warn<C extends Category = Category>(options: Omit<CaptureOptions<C>, 'severity'>): Error {
  return capture({ ...options, severity: Severity.Warning });
}
/**
 * Logs an error event with and returns a throwable error
 * @effect Sends the event to Sentry
 * @effect Logs the event to the browser console
 * @param options Event configuration
 * @returns Either the provided exception or a newly created exception object
 */
export function error<C extends Category = Category>(options: Omit<CaptureOptions<C>, 'severity'>): Error {
  return capture({ ...options, severity: Severity.Error });
}

let isUnhandledExceptionHandlerRegistered = false;

/**
 * Registers the logging capturer on global unhandled exception events
 * @returns Unregister function
 */
export function registerUnhandledExceptionHandler() {
  if (!isUnhandledExceptionHandlerRegistered) {
    const handleError = (event: ErrorEvent | PromiseRejectionEvent) => {
      const error = event instanceof ErrorEvent ? event.error : event.reason;

      /**
       * Handle chunk load erros
       */

      if ('reason' in event && /loading chunk \d* failed./i.test(event.reason)) {
        capture({
          category: Category.UNCAUGHT_EXCEPTION,
          severity: Severity.Info,
          message: 'Reloading page due to missing chunk load',
          exception: error,
        });

        window.location.reload();

        return;
      }

      /**
       * Log to Sentry
       */

      capture({
        severity: Severity.Error,
        category: Category.UNCAUGHT_EXCEPTION,
        message: error?.message ?? (event as ErrorEvent)?.message ?? error ?? event ?? 'No message provided',
        exception: error ?? event,
      });
    };
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);
    isUnhandledExceptionHandlerRegistered = true;
    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError);
    };
  }
}

interface ErrorBoundaryProps {
  fallbackComponent?: React.ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
}

/**
 * Captures unhandled exceptions generated by any child components during React UI rendering.
 * Captured errors are logged and reported to Sentry
 *
 * React error boundaries are only supported via class components as of this writing
 * This should be revisited in the future if React updates Error Boundaries for hooks.
 * @see https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: unknown) {
    capture({
      severity: Severity.Error,
      category: Category.UNCAUGHT_EXCEPTION,
      message: error.message,
      exception: error,
      context: { errorInfo },
    });
  }
  render() {
    if (this.state.hasError) {
      if (this.props.fallbackComponent) {
        return this.props.fallbackComponent;
      }

      // TODO: add error in a popup or somewhere visible to user with
      // prompt to contact support if issue continues.
      return this.props.children;
    }
    return this.props.children;
  }
}
export const Logger = {
  warn,
  error,
  info,
  Category,
  ErrorBoundary,
  PII_OBJECT_KEYS,
  PII_STRING_MATCHERS,
  registerUnhandledExceptionHandler,
};
