import { Types } from '@remarkable/rm-store-types';
import { StripeError } from '@stripe/stripe-js';
import { CheckoutStepCompleted, CheckoutStepFailed, PaymentFailed } from 'ampli-types';
import { GTMProductsData } from 'src/contexts/GTMProductsDataContext';
import { tracker } from 'src/services/tracker';

import { getTrackingProducts, getTrackingProductsTotal } from './utils';

//This is made to comply with current logic of progressing to checkout flow, (numerical increments)
export enum CheckoutSteps {
  CHECKOUT_PROCESS_STARTED = 0,
  CONTACT_INFORMATION_FILLED = 1,
  SHIPPING_AND_BILLING_FILLED = 2,
  CREATE_ORDER = 3,
  EXPRESS_CHECKOUT = 4,
}

export const getCheckoutStepName = (step: CheckoutSteps): string => {
  if (step === CheckoutSteps.CHECKOUT_PROCESS_STARTED) {
    return 'Started checkout process';
  } else if (step === CheckoutSteps.CONTACT_INFORMATION_FILLED) {
    return 'Contact information filled';
  } else if (step === CheckoutSteps.SHIPPING_AND_BILLING_FILLED) {
    return 'Shipping and billing address filled';
  } else if (step === CheckoutSteps.CREATE_ORDER) {
    return 'Create order';
  } else if (step === CheckoutSteps.EXPRESS_CHECKOUT) {
    return 'Express Checkout';
  } else {
    return 'Unknown step...';
  }
};

export const trackCheckoutStepExecuted = ({
  cart,
  stepNumber,
  gtmProductsData,
  info_message,
  payment_method,
  supported_methods,
}: {
  cart: Types.Store.Cart;
  gtmProductsData: GTMProductsData;
  stepNumber: CheckoutSteps;
  info_message?: string;
  payment_method?: Types.Moltin.Order.Payment.Methods;
  supported_methods: Types.Moltin.Order.Payment.Methods[];
}): void => {
  const trackingProducts = getTrackingProducts(cart, gtmProductsData);
  const checkoutStep: string = getCheckoutStepName(stepNumber);
  const amplitudeEvent = new CheckoutStepCompleted({
    checkout_step: checkoutStep,
    checkout_step_number: stepNumber,
    currency: cart.currency,
    products: trackingProducts,
    cart_value: getTrackingProductsTotal(trackingProducts),
    info_message: info_message ? info_message : undefined,
    payment_method: payment_method ? payment_method : undefined,
    supported_methods,
  });
  tracker.trackEvent(amplitudeEvent);
};

export const trackCheckoutError = (info_message: string, error: Error): void => {
  const amplitudeEvent = new CheckoutStepFailed({
    info_message,
    error_message: error.message,
    error_name: error.name,
  });
  tracker.trackEvent(amplitudeEvent);
};

//Paypal throws error with ANY value... No way of knowing what kind of error is returned?
export const trackStripePaymentFailed = (
  internal_error_message: string,
  error: StripeError | undefined,
  orderId: string,
  supportedMethods: Types.Moltin.Order.Payment.Methods[],
  payment_method: Types.Moltin.Order.Payment.Methods
): void => {
  const amplitudeEvent = new PaymentFailed({
    source: 'Stripe',
    internal_error_message,
    error_message: error?.message ?? '',
    error_code: error ? error.code : undefined,
    error_type: error ? error.type : undefined,
    decline_code: error ? error.decline_code : undefined,
    payment_intent_status: error?.payment_intent?.status,
    card_brand: error ? error.payment_method?.card?.brand : undefined,
    payment_method_id: error ? error.payment_method?.id : undefined,
    payment_method: payment_method,
    order_id: orderId,
    charge_id: error ? error.charge : undefined,
    supported_methods: supportedMethods,
    intent_id: error ? error.payment_intent?.id : undefined,
  });
  tracker.trackEvent(amplitudeEvent);
};

export const trackPaypalPaymentFailed = (
  internal_error_message: string,
  error_message: any | undefined,
  payment_method: Types.Moltin.Order.Payment.Methods,
  error?: Error
): void => {
  const amplitudeEvent = new PaymentFailed({
    source: 'PayPal',
    internal_error_message,
    error_message,
    error,
    payment_method: payment_method,
  });
  tracker.trackEvent(amplitudeEvent);
};

export const trackGenericPaymentFailed = (
  internal_error_message: string,
  error_message: string,
  payment_method: string,
  error?: Error | any
): void => {
  const amplitudeEvent = new PaymentFailed({
    source: 'unknown',
    internal_error_message,
    error_message: error_message,
    error: error,
    payment_method: payment_method,
  });
  tracker.trackEvent(amplitudeEvent);
};
