import { createContext, useState } from 'react';

// TODO: this might want to be a backend-frontend agreement.. move into store-types?
/** Enumeration of cart API error types */
export enum CartErrorType {
  TAX_INVALID_POSTAL_CODE = 'tax_invalid_postal_code',
  UNKNOWN_ERROR = 'unknown_error',
}

export interface CartContext {
  isLoading: boolean;
  incrementCartOperations: () => void;
  decrementCartOperations: () => void;
  error: CartErrorType | null;
  setError: (err: CartErrorType | null) => void;
}

export const CartContext = createContext<CartContext>({
  isLoading: true,
  decrementCartOperations: () => {
    return void 0;
  },
  incrementCartOperations: () => {
    return void 0;
  },
  error: null,
  setError: () => {
    return void 0;
  },
});

export const CartProvider: React.FC = ({ children }) => {
  const [error, setError] = useState<CartErrorType | null>(null);

  // Handle cart loading logic
  const [currentCartOperations, setCurrentCartOperations] = useState<number>(0);
  const incrementCartOperations = () => {
    setCurrentCartOperations(currentCartOperations + 1);
  };
  const decrementCartOperations = () => {
    if (currentCartOperations <= 0) {
      setCurrentCartOperations(0);
    } else {
      setCurrentCartOperations(currentCartOperations - 1);
    }
  };
  const isLoading = currentCartOperations > 0;

  const contextValue = {
    isLoading,
    decrementCartOperations,
    incrementCartOperations,
    error,
    setError,
  };

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};
