import { typedAction } from './typedAction';

export const SET_DATA = 'noShippingModal/SET_DATA';
export const POST_REQUESTED = 'noShippingModal/POST_REQUESTED';
export const POST_SUCCEEDED = 'noShippingModal/POST_SUCCEEDED';
export const POST_FAILED = 'noShippingModal/POST_FAILED';
export const SHOW = 'noShippingModal/SHOW';

export type Actions =
  | ReturnType<typeof setData>
  | ReturnType<typeof onRequested>
  | ReturnType<typeof onFailed>
  | ReturnType<typeof show>
  | ReturnType<typeof onSucceeded>;

export interface SetDataProps {
  show: boolean;
  country: any;
  isNoShipping: boolean;
}
export const setData = (payload: SetDataProps) => {
  return typedAction(SET_DATA, payload);
};

export const onRequested = () => {
  return typedAction(POST_REQUESTED);
};

export const onFailed = () => {
  return typedAction(POST_FAILED);
};

export const onSucceeded = () => {
  return typedAction(POST_SUCCEEDED);
};

export interface ShowProps {
  show: boolean;
}
export const show = (shouldShow: ShowProps) => {
  return typedAction(SHOW, shouldShow);
};
