import { Pageview } from 'ampli-types';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { tracker } from 'src/services/tracker';

export const useTracking = () => {
  const router = useRouter();

  useEffect(() => {
    //Currently send empty object as we need to do some fixing on page_path. pathname will be assigned when sending to Amplitude
    const handleRouteChange = (_url: string) => tracker.trackEvent(new Pageview({}));
    // Tracking initial page.
    // Fires two times in dev mode, but only once in production mode.
    // handleRouteChange(document.location.toString());
    router.events.on('routeChangeComplete', handleRouteChange); // Tracking any route changes
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    //track the initial pageview / page reloads
    tracker.trackEvent(new Pageview({}));
    document.addEventListener('click', tracker.trackClick);
    return () => {
      document.removeEventListener('click', tracker.trackClick);
    };
  }, []);

  useEffect(() => {
    const cleanUp = tracker.trackInViewport();
    return () => {
      if (cleanUp) {
        cleanUp();
      }
    };
  }, [router.pathname]);
};
