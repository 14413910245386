import { Logger } from 'src/utils/logger';

import { compareObjects } from './appHelpers';

export const StorageKeys = {
  CART_ID: 'newCartId',
  COOKIE_BAR_DISMISSED: 'cookiebarDismissed',
  UTM_CAMPAIGN: 'UTMCampaign',
  LAST_ORDER: 'lastOrder',
  CHECKOUT_DETAILS: 'checkoutDetails2',
  EMAIL_PROMT_SIGNED_UP: 'emailPromptSignedUp',
  MPF_CONFIG_021020: 'MpfConfig021020',
  MPF2: 'MPF2',
  REFERRAL: 'referral',
  SHOW_QUANTITY_DISCOUNT_BANNER: 'showQuantityDiscountBanner',
  MARKETING_OPT_IN_DISMISSED: 'marketingOptInDismissed',
  CUSTOMER_REVIEWS_MAIN_PAGE: 'customerReviewsMainPage',
  STRIPE_CHECKOUT_INTENT_RESPONSE: 'stripeCheckoutIntentResponse',
  LAST_CHECKOUT_PAYMENT_METHOD: 'lastCheckoutPaymentMethod',
};

const isSupported = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  try {
    const key = 'test_localStorage_key';
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    return true;
  } catch (err: any) {
    Logger.warn({
      category: Logger.Category.BREADCRUMB_DEBUG,
      message: 'Access denied to LocalStorage',
      exception: err,
    });
    return false;
  }
};

const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const isQuotaExceeded = (e: { code: number; name: string; number: number }) => {
  let quotaExceeded = false;
  if (e) {
    if (e.code) {
      switch (e.code) {
        case 22:
          quotaExceeded = true;
          break;
        case 1014:
          // Firefox
          if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            quotaExceeded = true;
          }
          break;
        default:
          quotaExceeded = false;
      }
    } else if (e.number === -2147024882) {
      // Internet Explorer 8
      quotaExceeded = true;
    }
  }
  return quotaExceeded;
};

export const loadState = (key: string, expectation?: any) => {
  if (isSupported()) {
    try {
      const serializedState = localStorage.getItem(key);

      if (serializedState === null) {
        return undefined;
      }

      const res = IsJsonString(serializedState) ? JSON.parse(serializedState) : serializedState;

      let stateIsEqual = true;
      if (expectation) {
        stateIsEqual = compareObjects(res, expectation);
      }

      if (!stateIsEqual) {
        localStorage.removeItem(key);
      }

      // console.log('^^--------------^^----------------^^');
      return stateIsEqual ? res : null;
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
};

export const clearState = (key: string) => {
  if (isSupported()) {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      return undefined;
    }
  }
  return undefined;
};

export const saveState = (key: string, value: string | any | boolean) => {
  if (isSupported()) {
    try {
      const serializedState = JSON.stringify(value);
      localStorage.setItem(key, serializedState);
    } catch (err: any) {
      Logger.warn({
        category: Logger.Category.BREADCRUMB_DEBUG,
        message: 'LocalStorage save failed',
        exception: err,
        context: { key, value, quota_exceeded: isQuotaExceeded(err) },
      });
    }
  }
};
