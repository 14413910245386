import { DEFAULT_FOLIO } from 'src/constants';
import { isTypeFolio } from 'src/helpers/typeFolioHelpers';
import { getStorageValue } from 'src/hooks/useQueryParamOrSessionStorage';
import { MPFsessionStorageKeys } from 'src/views/MPF/constants';
import { MPFStep } from 'src/views/MPF/types';
import { UrlObject } from 'url';

import {
  ALL_DEVICE_SKUS,
  ALL_FOLIO_SKUS,
  ALL_MARKER_SKUS,
  DeviceVariant,
  FolioVariant,
  MarkerVariant,
} from 'src/data/productData';
import { ProductPrice } from 'src/queries/elasticpath/getEPProducts';
import { MpfCart } from '../typings/mpfTypes';

export const getNewCart = () => {
  return {
    skus: [],
  };
};
export const clearCart = () => {
  return {
    skus: [],
  };
};
export const handleAddToCart = (cart: MpfCart, skusToAdd: string[]) => {
  const newCart = cart;
  const allSkus = newCart.skus.concat(skusToAdd);
  newCart.skus = allSkus;
  return newCart;
};

export const handleRemoveFromCart = (cart: MpfCart, skusToRemove: string[]) => {
  const newCart = cart;
  newCart.skus = cart.skus.filter((sku) => !skusToRemove.includes(sku));
  return cart;
};

export const DeviceChoices: DeviceVariant[] = [...ALL_DEVICE_SKUS];

export function isDeviceSku(sku: string): sku is DeviceVariant {
  return DeviceChoices.includes(sku as DeviceVariant);
}

export const MarkerChoices: MarkerVariant[] = [...ALL_MARKER_SKUS, 'none'];

export function isMarkerSku(sku: string): sku is MarkerVariant {
  return MarkerChoices.includes(sku as MarkerVariant);
}

export const FolioChoices = [
  ...new Set([DEFAULT_FOLIO.TYPE_FOLIO, ...ALL_FOLIO_SKUS, 'none']), // Ensure that the default sku is always first
] as FolioVariant[];

export function isFolioSku(sku: string): sku is FolioVariant {
  return FolioChoices.includes(sku as FolioVariant);
}

const validSteps = [...Object.keys(MPFsessionStorageKeys || {}), 'summary'];

export function getBundleSku(device: DeviceVariant, marker: MarkerVariant, folio: FolioVariant): string {
  const validSkus = [device, marker, folio].filter((sku) => sku !== 'none');
  return `BUNDLE_${validSkus.join('_')}`;
}

export const buildGenericBundleSku = (skus: string[]): string => {
  const validSkus = skus.filter((sku) => sku !== 'none');
  return `BUNDLE_${validSkus.join('_')}`;
};

export const getMPFPathUrlObject = (
  step?: MPFStep,
  device?: DeviceVariant,
  marker?: MarkerVariant,
  folio?: FolioVariant
): UrlObject => {
  const storedStep = getStorageValue(MPFsessionStorageKeys.step, 'step', validSteps);
  const firstStep = 'device';
  return {
    pathname: '/store/configure',
    query: {
      step: step || storedStep || firstStep,
      marker,
      folio,
      device,
    },
  };
};

export const isFolioBook = (selected: FolioVariant) =>
  selected === 'RM312' || selected === 'RM313' || selected === 'RM314';

export const greyFolioSku = (selected: FolioVariant) => (isFolioBook(selected) ? 'RM314' : 'RM310'); // if selected === 'none' this will default to RM310

type MPFProductType = 'none' | 'device' | 'marker' | 'type-folio' | 'book-folio' | 'folio' | 'unknown';

export const getProductType = (sku: string): MPFProductType => {
  if (sku === 'none') return 'none';
  if (isDeviceSku(sku)) return 'device';
  if (isMarkerSku(sku)) return 'marker';

  if (isFolioSku(sku)) {
    if (isTypeFolio(sku)) return 'type-folio';
    return isFolioBook(sku) ? 'book-folio' : 'folio';
  }

  return 'unknown';
};

export type PriceCollectionObject = { [key in string]: ProductPrice };

export const getCheapestRemarkable2Price = (
  prices: PriceCollectionObject,
  countrySellsRefurb: boolean
): { amount: number } => {
  if (!countrySellsRefurb) {
    return {
      amount: prices?.['RM110']?.amount,
    };
  }
  const rm2s = [prices?.['RM110'], prices?.['RM111']];
  const [cheapest] = rm2s.sort((a, b) => a?.amount - b?.amount);

  return {
    amount: cheapest?.amount as number,
  };
};
