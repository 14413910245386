import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config.js';

const BP = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
export type BP = (typeof BP)[number];

const resolveTwBreakpoints = () => {
  const screens = resolveConfig(tailwindConfig)?.theme?.screens;
  if (!screens) {
    throw Error(`Could not resolve screens in tailwind config theme. ${JSON.stringify(tailwindConfig)}`);
  }
  // Checks that all keys in screens from Tailwind are a BP
  const tailwindBreakPoints = Object.keys(screens);
  const tailwindScreenKeyNotInBp = tailwindBreakPoints.find((bp) => !(BP as readonly string[]).includes(bp));
  if (tailwindScreenKeyNotInBp) {
    throw Error(
      `Found key (${tailwindScreenKeyNotInBp}) in tailwind config that is not hardcoded (${BP.join(
        ','
      )})! Tailwind config and the BP values must match!`
    );
  }
  // Checks that all BPs exists in screens from Tailwind
  const bpNotInTailwindConfig = BP.find((bp) => !tailwindBreakPoints.includes(bp));
  if (bpNotInTailwindConfig) {
    throw Error(
      `Found hardcoded breakpoint (${bpNotInTailwindConfig}) that is not in tailwind config (${tailwindBreakPoints.join(
        ','
      )}). Tailwind config and the BP values must match!`
    );
  }
  // Checks that all values are a string
  const notAStringScreenValue = Object.values(screens).find((screen) => typeof screen !== 'string');
  if (notAStringScreenValue) {
    throw Error(`Found a tailwind config value that is not a string: ${notAStringScreenValue}`);
  }

  // We can safely do 'as' here because we checked above:
  return screens as { [bp in BP]: string };
};

// takes a bp object from TW, creates array of arrays, restructures to number and sorts it
// in ascending order. Media queries chooses the first fitting entry, so order matters
const sortTwBreakpoints = (bpObject: { [bp in BP]: string }) =>
  Object.entries(bpObject)
    .map(([bp, bpInPx]) => {
      const bpInt = parseInt(bpInPx, 10);
      if (!bpInt) {
        throw new Error('Tailwind breakpoints are off, cannot transform to int.');
      }
      return [bp, bpInt] as [BP, number];
    })
    .sort(([, a], [, b]) => a - b);

// takes a sorted bp array of arrays and returns an Object with media queries
// where a breakpoint can only ever trigger ONE breakpoint
const buildMqObj = (sortedBpObj: [BP, number][]) => {
  if (sortedBpObj.length <= 1) {
    throw new Error('You cannot build a full set of breakpoints with one breakpoint.');
  }
  return Object.fromEntries(
    sortedBpObj.map(([bp, size], i) => {
      if (i === 0) {
        const nextBp = sortedBpObj[i + 1][1];
        if (!nextBp) {
          throw Error(
            `Could not find next break point for ${bp} in first entry (index: ${i}) in ${JSON.stringify(sortedBpObj)}`
          );
        }
        return [bp, `(max-width: ${nextBp - 1}px)`];
      } else if (i + 1 === sortedBpObj.length) {
        return [bp, `(min-width: ${size}px)`];
      } else {
        const nextBp = sortedBpObj[i + 1][1];
        if (!nextBp) {
          throw Error(`Could not find next break point for ${bp}, index: ${i} in ${JSON.stringify(sortedBpObj)}`);
        }
        return [bp, `(min-width: ${size}px) and (max-width: ${nextBp - 1}px)`];
      }
    })
  ) as { [bp in BP]: string };
};

// Fetches breakpoints from tailwind, sorts and rebuilds to make sure we only ever hit one
// These are used as the default queries for the BreakpointProvider/Context
const twBreakpoints = resolveTwBreakpoints();
const sortedTwBps = sortTwBreakpoints(twBreakpoints);
export const sortedTwBpsObj: { [bp in BP]: number } = Object.fromEntries(sortedTwBps);
export const sortedTwBpSizes: number[] = Array.from(sortedTwBps, ([_, size]) => size);
export const tailwindMediaQueryObj: { [bp in BP]: string } = buildMqObj(sortedTwBps);
