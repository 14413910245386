import zip from 'lodash/zip';
import { NextRouter, useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useEffect, useMemo } from 'react';

export function isValidChoice<R extends string>(choices: R[], choice: string | string[] | undefined): choice is R {
  if (typeof choice !== 'string') {
    return false;
  }
  return (choices as string[]).includes(choice);
}

type ListOfParams = Array<{ name: string; value: string }>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function navigateTo(router: NextRouter, listOfParamsToUpdate: ListOfParams, params: ParsedUrlQuery | undefined) {
  const updateParamsObject = Object.fromEntries(listOfParamsToUpdate.map((param) => [param.name, param.value]));
  if (router.isReady && params) {
    router.replace({ query: { ...params, ...updateParamsObject } }, undefined, { shallow: true });
  }
}

export function getStorageValue<R extends string>(
  sessionStorageKey: string,
  name: string,
  choices: R[]
): R | undefined {
  try {
    const sessionValue = window.sessionStorage.getItem(sessionStorageKey);
    if (sessionValue) {
      if (isValidChoice(choices, sessionValue)) {
        console.debug(`Found value for ${name} in session storage: ${sessionValue}`);
        return sessionValue;
      } else {
        console.warn(
          `Found an illegal choice in session storage for ${name}: ${sessionValue}. Valid choices: ${choices.join(',')}`
        );
      }
    }
  } catch (err) {
    console.debug(`Could not get value with key ${sessionStorageKey} from sessionStorage`);
  }
}

type QueryParamDescription<R extends string> = {
  name: string;
  choices: R[];
  defaultChoice: R;
  sessionStorageKey?: string;
};
export type QueryParamState<R extends string> = [R, (newValue: R) => void];

/**
 * Typing note: typing on this function is incomplete. It would be too complicated to type it out completely.
 * When using this function, proper type assertions on return value is required.
 */
export const useQueryParamOrSessionStorageCommon = (
  queryParamDescriptions: QueryParamDescription<string>[]
): QueryParamState<string>[] => {
  const router = useRouter();
  const params = router.isReady ? router.query : undefined;

  const listOfParamsToUpdate: ListOfParams = [];
  const currentParamValues: string[] = [];
  // Build list of params that needs updating:
  for (const queryParamDescription of queryParamDescriptions) {
    const currentQueryParam = params && params[queryParamDescription.name]?.toString();
    const validQueryParam = isValidChoice(queryParamDescription.choices, currentQueryParam)
      ? currentQueryParam
      : undefined;
    const validSessionParam: string | undefined =
      queryParamDescription.sessionStorageKey &&
      getStorageValue(
        queryParamDescription.sessionStorageKey,
        queryParamDescription.name,
        queryParamDescription.choices
      );
    const currentParamValue = validQueryParam || validSessionParam || queryParamDescription.defaultChoice;
    currentParamValues.push(currentParamValue);
    if (!validQueryParam) {
      listOfParamsToUpdate.push({
        name: queryParamDescription.name,
        value: currentParamValue,
      });
    }
  }

  useEffect(() => {
    if (!router.isReady || listOfParamsToUpdate.length === 0) {
      return;
    }
    navigateTo(router, listOfParamsToUpdate, params);
  }, [queryParamDescriptions]);

  const queryParamSetters = useMemo(
    () =>
      queryParamDescriptions.map((queryParamDescription) => (newValue) => {
        if (!(!queryParamDescription.sessionStorageKey || !queryParamDescription.choices.includes(newValue))) {
          sessionStorage.setItem(queryParamDescription.sessionStorageKey, newValue);
        }
        navigateTo(router, [{ name: queryParamDescription.name, value: newValue }], params);
      }),
    [router, params, queryParamDescriptions]
  );

  return zip(currentParamValues, queryParamSetters) as QueryParamState<string>[];
};
