import { Types } from '@remarkable/rm-store-types';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { checkout, getCheckoutObject } from 'src/services/affirmApi';
import * as RmStoreAPI from 'src/services/rm-store-api';
import * as RmStoreAPIHelpers from 'src/services/rm-store-api/helpers';
import { trackCheckoutError } from 'src/services/tracking/trackEventMethods';
import { CheckoutReducer } from 'src/typings/checkoutTypes';
import { Logger } from 'src/utils/logger';

import * as actions from '../actions/affirmActions';
import * as checkoutActions from '../actions/checkoutActions';
import { State } from '../reducers';

const getCheckoutResponse = (state: State): any | undefined => state.checkout.checkoutResponse;

interface StartAffirmCheckoutProps {
  customer: CheckoutReducer.Customer;
  shippingAddress: CheckoutReducer.ShippingAddress;
  billingAddress: CheckoutReducer.BillingAddress;
  cart: Types.Store.Cart;
  currency: string;
  orderDetails: any;
}

export function* startAffirmCheckout({
  customer,
  shippingAddress,
  billingAddress,
  cart,
  currency,
  orderDetails,
}: StartAffirmCheckoutProps) {
  try {
    yield put(actions.startCheckout());
    let checkoutResponse;
    const checkoutResponseFromReducer = yield select(getCheckoutResponse);
    if (checkoutResponseFromReducer) {
      checkoutResponse = checkoutResponseFromReducer;
    } else {
      const checkoutCartBody = RmStoreAPIHelpers.formatCheckoutDetails(orderDetails);
      checkoutResponse = yield call(RmStoreAPI.checkoutCart, checkoutCartBody, currency);
      yield put(checkoutActions.setCheckoutResponse(checkoutResponse));
    }
    yield call(getCheckoutObject as any, customer, shippingAddress, billingAddress, cart, checkoutResponse);
    yield call(checkout);
  } catch (error: any) {
    yield put(actions.checkoutFailed());
    Logger.warn({
      category: Logger.Category.CHECKOUT,
      exception: error,
      message: 'Affirm checkout failed',
      context: { cart, currency, orderDetails },
    });
    trackCheckoutError('Affirm checkout failed', error);
  }
}

export default [takeEvery(actions.CHECKOUT as any, startAffirmCheckout)];
