import { createContext, useContext } from 'react';

export interface AppState {
  isAppVisible: boolean;
}

export const DevtoolsAppStateContext = createContext<AppState & { setIsAppVisible: (appVisible: boolean) => void }>({
  isAppVisible: false,
  setIsAppVisible: Function.prototype as any,
});
export const useDevtoolsAppState = () => useContext(DevtoolsAppStateContext);
