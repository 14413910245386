const plugin = require('tailwindcss/plugin');
// add svg in here if possible?

module.exports = plugin(({ addComponents, theme }) => {
  addComponents({
    '[data-pillNav-theme="blue"]': {
      '--pillNav-whole-bg': theme('colors.grayscale.gray.50'),
      '--pillNav-whole-bg-active': theme('colors.brand.blue.800'),
      '--pillNav-whole-color': theme('colors.brand.blue.800'),
      '--pillNav-whole-color-active': theme('colors.grayscale.gray.50'),
      '--pillNav-overflow-bg': 'transparent',
      '--pillNav-overflow-bg-active': theme('colors.grayscale.gray.50'),
      '--pillNav-overflow-color': theme('colors.grayscale.gray.50'),
      '--pillNav-overflow-color-active': theme('colors.brand.blue.800'),
    },
    '[data-pillNav-theme="green"]': {
      '--pillNav-whole-bg': theme('colors.grayscale.gray.50'),
      '--pillNav-whole-bg-active': theme('colors.brand.green.700'),
      '--pillNav-whole-color': theme('colors.brand.green.800'),
      '--pillNav-whole-color-active': theme('colors.grayscale.gray.50'),
      '--pillNav-overflow-bg': 'transparent',
      '--pillNav-overflow-bg-active': theme('colors.brand.green.700'),
      '--pillNav-overflow-color': theme('colors.brand.green.700'),
      '--pillNav-overflow-color-active': theme('colors.grayscale.gray.50'),
    },
    '.pillNav-whole-text': {
      color: 'var(--pillNav-whole-color)',
    },
    '.pillNav-whole-bg': {
      backgroundColor: 'var(--pillNav-whole-bg)',
      borderColor: 'var(--pillNav-whole-bg)',
    },
    '.pillNav-whole-text-active': {
      color: 'var(--pillNav-whole-color-active)',
    },
    '.pillNav-whole-bg-active': {
      backgroundColor: 'var(--pillNav-whole-bg-active)',
    },
    '.pillNav-overflow': {
      color: 'var(--pillNav-overflow-color)',
      backgroundColor: 'var(--pillNav-overflow-bg)',
    },
    '.pillNav-overflow-active': {
      color: 'var(--pillNav-overflow-color-active)',
      backgroundColor: 'var(--pillNav-overflow-bg-active)',
    },
  });
});
