import React, { useContext } from 'react';
import { getGTMProductsData } from 'src/queries/gtmProductsData';

/**
 * This data context contains all the data used by googleTagManager functions. See src/services/googleTagManager.ts
 *
 * Subset of this data is data needed by frequently used hook useSkuPrices, and this data store is used on all
 * pages because GTM is used on all pages. For these reasons, GTMProductsData store is used by useSkuPrices hook
 * as well, there is no need for separate data store.
 */
export type GTMProductsData = Awaited<ReturnType<typeof getGTMProductsData>>;
export type WithGTMProductsData = {
  gtmProductsData: GTMProductsData;
};

export const GTMProductsDataContext = React.createContext<GTMProductsData | null>(null);

export const useGTMProductsData = () => {
  const gtmProductsDataContext = useContext(GTMProductsDataContext);
  if (gtmProductsDataContext === null) {
    throw Error('useGTMProductsData did not find expected data, are we outside a RefurbishedDeviceCountryDataContext?');
  }
  return gtmProductsDataContext;
};
