import { DEFAULT_FOLIO } from 'src/constants';
import { TypeFolioSkus } from 'src/data/productData';

type TypeFolioLayout = 'US' | 'UK' | 'DE' | 'ES' | 'FR' | 'NO';
type TypeFolioLayoutConfig = {
  sku: TypeFolioSkus;
  layout: TypeFolioLayout;
  title: string;
  countries: string[];
};

const USCountries = [
  'AU',
  'BG',
  'CA',
  'HR',
  'CY',
  'EE',
  'GR',
  'HK',
  'HU',
  'IS',
  'JP',
  'IL',
  'LV',
  'LT',
  'MT',
  'NL',
  'NZ',
  'PL',
  'RO',
  'QA',
  'SG',
  'SK',
  'SI',
  'KR',
  'TW',
  'MY',
  'VN',
  'EG',
  'PK',
  'SA',
  'PH',
  'TR',
  'ID',
  'IN',
  'US',
  'AE',
  'TH',
];
const UKCountries = ['GB', 'JE', 'IE', 'CZ'];
const DECountries = ['CH', 'LU', 'LI', 'DE', 'AT'];
const ESCountries = ['AR', 'MX', 'BR', 'ES', 'PT', 'IT'];
const FRCountries = ['FR', 'BE'];
const NOCountries = ['SE', 'NO', 'FI', 'DK'];

const TYPE_FOLIO_LAYOUT_CONFIG: TypeFolioLayoutConfig[] = [
  {
    sku: 'RM710',
    layout: 'US',
    title: 'English - US',
    countries: USCountries,
  },
  {
    sku: 'RM711',
    layout: 'UK',
    title: 'English - UK',
    countries: UKCountries,
  },
  {
    sku: 'RM712',
    layout: 'DE',
    title: 'German - DE',
    countries: DECountries,
  },
  {
    sku: 'RM713',
    layout: 'ES',
    title: 'Spanish - ES',
    countries: ESCountries,
  },
  {
    sku: 'RM714',
    layout: 'FR',
    title: 'French - FR',
    countries: FRCountries,
  },
  {
    sku: 'RM715',
    layout: 'NO',
    title: 'Nordic - SE/DK/NO/FI',
    countries: NOCountries,
  },
  {
    sku: 'RM810',
    layout: 'US',
    title: 'English - US',
    countries: USCountries,
  },
  {
    sku: 'RM811',
    layout: 'UK',
    title: 'English - UK',
    countries: UKCountries,
  },
  {
    sku: 'RM812',
    layout: 'DE',
    title: 'German - DE',
    countries: DECountries,
  },
  {
    sku: 'RM813',
    layout: 'ES',
    title: 'Spanish - ES',
    countries: ESCountries,
  },
  {
    sku: 'RM814',
    layout: 'FR',
    title: 'French - FR',
    countries: FRCountries,
  },
  {
    sku: 'RM815',
    layout: 'NO',
    title: 'Nordic - SE/DK/NO/FI',
    countries: NOCountries,
  },
];

export function isTypeFolio(selected?: string | null): boolean {
  if (!selected) {
    return false;
  }
  return selected.match(/RM[78]\d{2}/)?.length === 1;
}

export function getTypeFolioColorBySku(selected: string): 'brown' | 'black' | undefined {
  return selected.startsWith('RM7') ? 'brown' : selected.startsWith('RM8') ? 'black' : undefined;
}

export function getTypeFolioLayoutConfigByColor(color: 'brown' | 'black'): TypeFolioLayoutConfig[] {
  return TYPE_FOLIO_LAYOUT_CONFIG.filter((config) => getTypeFolioColorBySku(config.sku) === color);
}

export function getOppositeColorForSku(sku: TypeFolioSkus): TypeFolioSkus {
  return sku.replace(sku[2], sku[2] === '7' ? '8' : '7') as TypeFolioSkus;
}

export function getTypeFolioSkuForCountry(country: string, color: 'brown' | 'black' = 'black'): TypeFolioSkus {
  const matchingTypeFolio = TYPE_FOLIO_LAYOUT_CONFIG.filter(
    (config) => getTypeFolioColorBySku(config.sku) === color
  ).find((config) => config.countries.includes(country));

  if (!matchingTypeFolio) {
    // Get the first for this color, or fall back to black-US
    return (
      TYPE_FOLIO_LAYOUT_CONFIG.find((config) => getTypeFolioColorBySku(config.sku) === color)?.sku ??
      DEFAULT_FOLIO.TYPE_FOLIO
    );
  }

  return matchingTypeFolio.sku;
}
