import debounce from 'lodash/debounce';
import React, { createContext, useEffect, useState } from 'react';
import { BP, tailwindMediaQueryObj } from 'src/helpers/tailwindBreakpointsHelpers';

const BreakpointContext = createContext<{ activeBreakpoint: BP | null }>({ activeBreakpoint: null });

const DEBOUNCE_TIMEOUT_MS = 100;

const BreakpointProvider: React.FC<{ queries?: { [bp in BP]: string } }> = ({
  children,
  queries = tailwindMediaQueryObj,
}) => {
  const [mediaListeners, setMediaListeners] = useState<
    { media: MediaQueryList; listener: (event: { matches: boolean }) => void }[]
  >([]);
  const [activeBreakpoint, setActiveBreakpoint] = useState<BP | null>(null);

  useEffect(() => {
    if (!window || !window.matchMedia) {
      console.warn('BreakPoint context: no matchMedia support');
      return;
    }

    // we have exactly one debounce in the entire context because we want to debounce whenever the activeBreakpoint changes
    const setActiveBreakpointDebounced = debounce((bp: BP) => {
      setActiveBreakpoint(bp);
    }, DEBOUNCE_TIMEOUT_MS);

    for (const bp of Object.keys(queries) as BP[]) {
      const mediaQueryListObj = window.matchMedia(queries[bp]);
      if (!mediaQueryListObj.addEventListener) {
        console.warn('BreakPoint context: no matchMedia addEventListener support', bp);
        continue;
      }

      // init before any events fires
      if (mediaQueryListObj.matches) {
        // NOTE: here and below, we assume there is ONE and ONLY ONE bp that will be hit
        setActiveBreakpoint(bp);
      }

      // create the actual event listener
      const onMediaQueryChangeEvent = (event: { matches: boolean }) => {
        if (event.matches) {
          setActiveBreakpointDebounced(bp);
        }
      };
      mediaQueryListObj.addEventListener('change', onMediaQueryChangeEvent);

      // store current mediaListeners to be able to clean up
      setMediaListeners(
        mediaListeners.concat({
          media: mediaQueryListObj,
          listener: onMediaQueryChangeEvent,
        })
      );
    }
    return () => {
      // remove all previously created event listeners to avoid having them hanging around
      mediaListeners.forEach(({ media, listener }) => {
        media.removeEventListener('change', listener);
      });
      setActiveBreakpointDebounced.cancel();
    };
    // We have no dep on mediaListeners since this effect is the only one that changes mediaListeners
    // and if we had it we would unnecessarily re-trigger this effect.
    // Change the deps if the assumption above is wrong
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  return <BreakpointContext.Provider value={{ activeBreakpoint }}>{children}</BreakpointContext.Provider>;
};

export { BreakpointContext, BreakpointProvider };
