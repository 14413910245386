import { Types } from '@remarkable/rm-store-types';
import { formatPhoneNumber, getPhoneNumberCountryCode } from 'src/helpers/formattingHelpers';
import { CheckoutReducer } from 'src/typings/checkoutTypes';

import { Payer, ShippingInfo } from 'src/views/Checkout/paypal/helpers';
import { snakeToCamelCase } from '../../utils/caseTransformer';
import { AddressFormData } from '../../views/Checkout/CheckoutSteps-v2/types';

const getShippingAddress = (orderDetails: CheckoutReducer.State): Types.Moltin.Core.ShippingAddress => ({
  first_name: orderDetails.shippingAddress.firstName.value,
  last_name: orderDetails.shippingAddress.lastName.value,
  line_1: orderDetails.shippingAddress.addressLine1.value,
  line_2: orderDetails.shippingAddress.addressLine2.value,
  city: orderDetails.shippingAddress.city.value,
  postcode: orderDetails.shippingAddress.zipCode.value,
  county: orderDetails.shippingAddress.state.value,
  country: orderDetails.shippingAddress.country.value,
  phone_number: formatPhoneNumber(
    orderDetails.customer.phoneNumberCountryCode.value,
    orderDetails.customer.phoneNumber.value
  ),
  company_name: orderDetails.shippingAddress.company?.value || '',
});

const getBillingAddress = (orderDetails: CheckoutReducer.State): Types.Moltin.Core.BillingAddress => {
  if (orderDetails.billingAddress.billingAddressSameAsShipping.value) {
    return getShippingAddress(orderDetails);
  }
  return {
    first_name: orderDetails.billingAddress.billingFirstName.value,
    last_name: orderDetails.billingAddress.billingLastName.value,
    line_1: orderDetails.billingAddress.billingAddressLine1.value,
    line_2: orderDetails.billingAddress.billingAddressLine2.value,
    city: orderDetails.billingAddress.billingCity.value,
    postcode: orderDetails.billingAddress.billingZipCode.value,
    county: orderDetails.billingAddress.billingState.value,
    country: orderDetails.billingAddress.billingCountry.value,
    company_name: orderDetails.billingAddress.billingCompany?.value || '',
  };
};

const formatCheckoutDetails = (
  orderDetails: CheckoutReducer.State
): Types.Moltin.Cart.Checkout.POST.Request['data'] => {
  const shippingAddress = getShippingAddress(orderDetails);
  const billingAddress = getBillingAddress(orderDetails);
  const customer = {
    name: `${orderDetails.shippingAddress.firstName.value} ${orderDetails.shippingAddress.lastName.value}`,
    email: orderDetails.customer.email.value,
  };

  return {
    customer,
    billing_address: billingAddress,
    shipping_address: {
      ...shippingAddress,
      VAT: orderDetails.shippingAddress?.VAT?.value || '',
      purchaseOrderNumber: orderDetails.shippingAddress?.purchaseOrderNumber?.value || '',
    },
  };
};

const formatPaypalExpressCheckoutDetails = (
  payer: Payer,
  shipping: NonNullable<ShippingInfo>
): Types.Moltin.Cart.Checkout.POST.Request['data'] => {
  const payerPhoneCountryCode = getPhoneNumberCountryCode(payer.address.country_code) ?? '';
  // !! Note: typing for payer phone number is incorrect in @paypal/react-paypal-js
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const payerPhoneNationalNumber = ((payer?.phone.phone_number as any).national_number as string) ?? '';
  const payerPhone = formatPhoneNumber(
    payerPhoneCountryCode,
    // NB: All non-US phone numbers include country code in national_number, removed here for consistency
    payerPhoneNationalNumber.slice(
      payerPhoneCountryCode !== '1' && payerPhoneNationalNumber.startsWith(payerPhoneCountryCode)
        ? payerPhoneCountryCode.length
        : 0
    )
  );

  return {
    customer: {
      email: payer.email_address,
      name: shipping.name.full_name,
    },
    shipping_address: {
      first_name: payer.name.given_name ?? '',
      last_name: payer.name.surname ?? '',
      line_1: shipping.address.address_line_1 ?? '',
      line_2: shipping.address.address_line_2 ?? '',
      city: shipping.address.admin_area_2 ?? '',
      county: shipping.address.admin_area_1 ?? '',
      country: shipping.address.country_code ?? '',
      postcode: shipping.address.postal_code ?? '',
      phone_number: payerPhone ?? '',
    },
    billing_address: {
      first_name: payer.name.given_name ?? '',
      last_name: payer.name.surname ?? '',
      line_1: payer.address.address_line_1 ?? shipping.address.address_line_1 ?? '',
      line_2: payer.address.address_line_2 ?? shipping.address.address_line_2 ?? '',
      city: payer.address.admin_area_2 ?? shipping.address.admin_area_2 ?? '',
      county: payer.address.admin_area_1 ?? shipping.address.admin_area_1 ?? '',
      country: payer.address.country_code ?? shipping.address.country_code ?? '',
      postcode: payer.address.postal_code ?? shipping.address.postal_code ?? '',
    },
    is_express_checkout: true,
  };
};

/**
 * Transform local format to backend format
 * @param address
 * @returns backend address
 */
const getGenericAddress = (
  address: AddressFormData
): Types.Store.Order.BillingAddress | Types.Store.Order.ShippingAddress => {
  return {
    name: {
      first: address.firstName,
      last: address.lastName,
    },
    company: address.company,
    country: address.country,
    county: address.state,
    city: address.city,
    line1: address.addressLine1,
    line2: address.addressLine2,
    postcode: address.zipCode,
    ...(address.phone ? { phoneNumber: address.phone } : {}),
  };
};

/**
 * Transform backend format to local format
 * @param name
 * @returns local field name
 */
const convertFieldNameToLocal = (name: string): string => {
  switch (name) {
    case 'postcode':
      return 'zipCode';
    case 'line1':
      return 'addressLine1';
    case 'phone_number':
      return 'phone';
    case 'line2':
      return 'addressLine2';
    case 'county':
      return 'state';
    default:
      return snakeToCamelCase(name);
  }
};

/**
 * Must be in sync with NEXT_PUBLIC_RM_STORE_API_URL, if that points to the new eCom loadbalancer, this must be set to true.
 * Also, if that is pointing to an rm-store-api instance directly, this has to be false.
 */
const isNewEcomEnabled = process.env.NEXT_PUBLIC_NEW_ECOM_ENABLED === 'true';

export {
  formatCheckoutDetails,
  formatPaypalExpressCheckoutDetails,
  getShippingAddress,
  getGenericAddress,
  convertFieldNameToLocal,
  isNewEcomEnabled,
};
