export enum StoreActionTypes {
  SET_CART_VISIBLE = 'STORE/SET_CART_VISIBLE',
  SET_REFERRAL_CODE = 'STORE/SET_REF_CODE',
  SET_PROMOTION_CODE = 'STORE/SET_PROMOTION_CODE',
  SET_RM110_REFERRAL_DISCOUNTS = 'STORE/SET_RM110_REFERRAL_DISCOUNTS',
  SET_PROMOTION_DISCOUNTS = 'STORE/SET_PROMOTION_DISCOUNTS',
}

export const setReferralCode = (referralCode: string): { type: string; referralCode: string } => {
  return {
    type: StoreActionTypes.SET_REFERRAL_CODE,
    referralCode,
  };
};

export const setPromotionCode = (promotionCode: string): { type: string; promotionCode: string } => {
  return {
    type: StoreActionTypes.SET_PROMOTION_CODE,
    promotionCode,
  };
};

export const setCartVisible = (cartVisible: boolean): { type: string; cartVisible: boolean } => {
  return {
    type: StoreActionTypes.SET_CART_VISIBLE,
    cartVisible,
  };
};
