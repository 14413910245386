import { call, put, select, takeEvery } from 'redux-saga/effects';
import { saveState } from 'src/helpers/localStorage';
import { getCountryInfoByValue } from 'src/helpers/storeHelpers';
import { State } from 'src/redux/reducers';
import { eventKeys, pushToDataLayer } from 'src/services/googleTagManager';
import { SanityCountry } from 'src/typings/sanityTypes';

import * as checkoutActions from '../actions/checkoutActions';
import * as actions from '../actions/countryDetectorActions';

const getStateCountry = (state: State): string => state.countryDetector.country;

export function* changeCountry({ country }: { country: string }) {
  try {
    const countryFromState: string = yield select(getStateCountry);
    if (countryFromState === country) {
      return;
    }

    pushToDataLayer({ event: eventKeys.PAGE_META, selectedCountry: country });

    const sanityCountryData: SanityCountry[] = yield select((state: State) => state.staticQuery.countryData);
    const sanityCountry = getCountryInfoByValue(sanityCountryData, country);

    yield put(checkoutActions.showCartSpinner());
    if (sanityCountry) {
      const zipCode = {
        value: '',
        errorMessage: '',
        isValid: true,
      };
      const region = {
        value: '',
        errorMessage: '',
        isValid: true,
      };

      if (country === 'HK') zipCode.value = '00000';

      yield put(
        actions.setCountryData({
          country: sanityCountry.value,
          region: '',
        })
      );
      yield put(
        checkoutActions.addShippingDetailsAndSaveToLocalStorage({
          country: {
            value: country,
            errorMessage: '',
            isValid: true,
          },
          zipCode: {
            ...zipCode,
          },
          state: {
            ...region,
          },
        } as any)
      );
      yield put(
        checkoutActions.addCustomerDetailsAndSaveToLocalStorage({
          phoneNumberCountry: {
            value: sanityCountry.value,
            errorMessage: '',
            isValid: true,
          },
          phoneNumberCountryCode: {
            value: sanityCountry.countryCode,
            errorMessage: '',
            isValid: true,
          },
        })
      );

      yield call(saveState, 'country', sanityCountry.value);
      yield put(checkoutActions.hideCartSpinner());
    }
  } catch (error) {
    console.trace('changeCountry', error);
  }
}

export default [takeEvery(actions.CHANGE_COUNTRY as any, changeCountry)];
