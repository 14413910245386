import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import FocusLockUI from 'react-focus-lock/UI';
import { useLockScroll } from 'src/hooks/useLockScroll';
import { twMerge } from 'tailwind-merge';
import { sidecar } from 'use-sidecar';

const FocusLockSidecar = sidecar(() => import('react-focus-lock/sidecar'));

import { Button } from '@remarkable/ark-web';
import { X } from 'phosphor-react';
import { useHandleEscKey } from 'src/hooks/useHandleEscKey';

type Props = {
  show: boolean;
  handleClose: () => void;
  hideCross?: boolean;
  mobilePositionStart?: boolean;
  mobilePositionCenter?: boolean;
  mobilePositionEnd?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const Modal: React.FunctionComponent<Props> = ({
  show,
  handleClose,
  hideCross,
  children,
  className,
  mobilePositionStart,
  mobilePositionCenter,
  mobilePositionEnd,
}) => {
  const backdropRef = useRef<HTMLDivElement>(null);
  useLockScroll(show);

  /* Close drawer when Esc is pressed */
  useHandleEscKey(handleClose, show);

  const closeOnClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (backdropRef.current === e.target) {
      e.preventDefault();
      handleClose();
    }
  };

  useEffect(() => {
    document.body.classList.toggle('modal-open', show);
  }, [show]);

  return (
    <FocusLockUI sideCar={FocusLockSidecar} disabled>
      <div
        ref={backdropRef}
        className={cx(
          'flex justify-center fixed top-0 bottom-0 left-0 right-0 z-[120] !bg-[rgba(34,34,34,0.75)] overflow-y-scroll opacity-0 overflow-x-hidden',
          {
            hidden: !show,
            'animate-fade-in': show,
            'items-start': mobilePositionStart,
            'items-center': mobilePositionCenter,
            'items-end': mobilePositionEnd,
          }
        )}
        onClick={closeOnClickOutside}
      >
        <div className="absolute md:top-[50%] md:translate-y-[-50%]">
          <div className="relative h-full">
            <article
              className={twMerge(
                'max-h-[calc(100%-24px)] bg-grayscale-white rounded-[9px] m-12 duration-[0.5s] md:inline-block md:w-auto md:m-auto',
                className
              )}
              role="dialog"
              aria-modal="true"
              data-open={show}
            >
              <div className="h-full overflow-y-scroll overscroll-contain md:overflow-auto">{children}</div>
              {!hideCross && (
                <>
                  <Button
                    as="button"
                    className="top-8 absolute z-50 right-20 rounded-[50%] bg-grayscale-gray-800 hover:bg-grayscale-gray-900 md:top-[-23px] md:right-[-23px]"
                    aria-label="Close"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <X size={24} fill="white" className="align-bottom" />
                  </Button>
                </>
              )}
            </article>
          </div>
        </div>
      </div>
    </FocusLockUI>
  );
};

export default Modal;
