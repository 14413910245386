import * as actions from '../actions/affirmActions';

export interface AffirmState {
  showSpinner: boolean;
}

const initialState: AffirmState = {
  showSpinner: false,
};

const actionsMap = {
  [actions.CHECKOUT]: (state) => ({
    ...state,
    showSpinner: true,
  }),
  [actions.CHECKOUT_FAILED]: (state) => ({
    ...state,
    showSpinner: false,
  }),
};

export default (state = initialState, action: any = {}): AffirmState => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
