import { ACTIVATE_NOTIFICATION_BAR, DISMISS_NOTIFICATION_BAR } from '../actions/NotificationBarActions';

export interface State {
  dismissed: boolean;
  activated: boolean;
}

const initialState: State = {
  dismissed: false,
  activated: false,
};

export default function reducer(state = initialState, action): State {
  switch (action.type) {
    case DISMISS_NOTIFICATION_BAR: {
      return { ...state, dismissed: true };
    }
    case ACTIVATE_NOTIFICATION_BAR: {
      return { ...state, activated: true };
    }

    default: {
      return state;
    }
  }
}
