import { useEffect } from 'react';
import smoothScroll from 'smoothscroll-polyfill';

/**
 * Scroll-behavior: smooth and many smooth-scrolling JS functions on window and dom elements don't work in Safari for
 * macOs or iOS.
 * This polyfill doesn't fix 'scroll-behavior' css attribute, but enables JS functions.
 * For example with polyfill, following works in all browsers: element.scrollIntoView({behavior: 'smooth'})
 *
 * Consult MDN for status on support of smooth scrolling features:
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 *
 * For overview of what this polyfill fixes:
 * https://www.npmjs.com/package/smoothscroll-polyfill
 * http://iamdustan.com/smoothscroll/
 */
export const useApplySmoothScrollPolyfill = () => {
  useEffect(() => {
    smoothScroll.polyfill();
  }, []);
};
