import { combineReducers } from 'redux';
import { MarketingData } from 'src/queries/groq/commonProviderData';
import { SanityCountry } from 'src/typings/sanityTypes';

import { MpfState } from '../../typings/mpfTypes';
import affirm, { AffirmState as affirmState } from './affirmReducer';
import checkout, { State as checkoutState } from './checkoutReducer';
import countryDetector, { State as countryDetectorState } from './countryDetectorReducer';
import managedPurchaseFlow from './managedPurchaseFlowReducer';
import noShippingModal, { State as noShippingState } from './noShippingModalReducer';
import notificationbar, { State as notificationbarState } from './NotificationBarReducer';
import store2, { State as storeState2 } from './storeReducer2';
import yotpo, { State as yotpoState } from './yotpoReducer';

export interface StaticQueryState {
  siteConfig: {
    trackOmittingVAT: boolean;
  };
  countryData: SanityCountry[];
  marketingOptIn: MarketingData;
}

export interface State {
  store2: storeState2;
  countryDetector: countryDetectorState;
  yotpo: yotpoState;
  checkout: checkoutState;
  affirm: affirmState;
  notificationbar: notificationbarState;
  managedPurchaseFlow: MpfState;
  noShippingModal: noShippingState;
  staticQuery: StaticQueryState;
}

type RootReducer = {
  [K in keyof State]: (...args: any) => State[K];
};

export const rootReducerFactory = (staticQueryData: StaticQueryState) =>
  combineReducers<RootReducer>({
    store2,
    countryDetector,
    yotpo,
    checkout,
    affirm,
    notificationbar,
    managedPurchaseFlow,
    noShippingModal,
    // Hardcoded reducer that always returns static query data
    staticQuery: () => staticQueryData,
  });
