import React, { useEffect, useReducer, useState } from 'react';
import { ConsentBannerDefaultView } from 'src/components/CookieConsent/ConsentBannerDefaultView';
import { ConsentBannerErrorBoundary } from 'src/components/CookieConsent/ConsentBannerErrorBoundary';
import { ConsentBannerSettingsView } from 'src/components/CookieConsent/ConsentBannerSettingsView';
import {
  consentBannerReducerWithLocalStorage,
  ConsentBannerStateProvider,
  defaultConsentBannerReducerState,
  readConsentBannerReducerStateFromLocalStorage,
} from 'src/components/CookieConsent/consentBannerState';
import Modal from 'src/components/Modal';
import { updateTrackerConsentStatusWithConsentBannerState } from 'src/services/tracker';

const COOKIE_BANNER_APPEARANCE_DELAY = 1000;
const COOKIE_BANNER_REMOVAL_FROM_DOM_DELAY = 2000;

export const ConsentBanner: React.FC = () => {
  const [cookieBannerVisible, setCookieBannerVisible] = useState(false);
  const [removeCookieBannerFromDOM, setRemoveCookieBannerFromDOM] = useState(false);
  const [state, dispatch] = useReducer(
    consentBannerReducerWithLocalStorage,
    readConsentBannerReducerStateFromLocalStorage() ?? defaultConsentBannerReducerState
  );

  useEffect(() => {
    /**
     * Delay the appearance of cookie banner with a short period for the sake of UX
     */
    if (!state.consentAccepted && !cookieBannerVisible) {
      const timeout = setTimeout(() => {
        setCookieBannerVisible(true);
      }, COOKIE_BANNER_APPEARANCE_DELAY);
      return () => clearTimeout(timeout);
    }
    if (cookieBannerVisible && state.consentAccepted) {
      setCookieBannerVisible(false);
    }
  }, [cookieBannerVisible, state.consentAccepted]);

  useEffect(() => {
    /**
     * Remove the Cookie Banner completely from DOM after it's finished animating.
     */
    if (state.consentAccepted && !removeCookieBannerFromDOM) {
      const timeout = setTimeout(() => {
        setRemoveCookieBannerFromDOM(true);
      }, COOKIE_BANNER_REMOVAL_FROM_DOM_DELAY);
      return () => clearTimeout(timeout);
    }
  }, [state.consentAccepted, removeCookieBannerFromDOM]);

  useEffect(() => {
    /**
     * Update consentStatus in tracking object from @remarkable/tracking-browser with updates to consent state
     */
    updateTrackerConsentStatusWithConsentBannerState(state);
  }, [state]);

  const [consentBannerView, setConsentBannerView] = useState<'main' | 'settings'>('main');

  return (
    <ConsentBannerErrorBoundary>
      <ConsentBannerStateProvider.Provider value={{ state, dispatch }}>
        {!removeCookieBannerFromDOM && (
          <Modal
            show={cookieBannerVisible}
            handleClose={Function.prototype as () => void}
            hideCross={true}
            className={
              'max-w-[640px] md:w-[640px] xl:max-w-[720px] xl:w-[720px] max-md:max-h-[calc(100dvh-16px)] md:max-h-[calc(100dvh-24px)] max-md:max-w-full m-auto max-md:mb-8 max-md:mx-8 rounded-[4px]'
            }
            mobilePositionEnd
          >
            {consentBannerView === 'main' && (
              <ConsentBannerDefaultView openConsentSettings={() => setConsentBannerView('settings')} />
            )}
            {consentBannerView === 'settings' && (
              <ConsentBannerSettingsView backToMainView={() => setConsentBannerView('main')} />
            )}
          </Modal>
        )}
      </ConsentBannerStateProvider.Provider>
    </ConsentBannerErrorBoundary>
  );
};
