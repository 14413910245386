const plugin = require('tailwindcss/plugin');

module.exports = plugin(({ addComponents, theme }) => {
  addComponents({
    // grayscale gray
    '[data-promotion-banner-theme="gray-9"]': {
      '--promotion-banner-text-color': theme('colors.light'),
      '--promotion-banner-background-color': theme('colors.grayscale.gray.900'),
    },

    // brand sand
    '[data-promotion-banner-theme="sand-3"]': {
      '--promotion-banner-text-color': theme('colors.dark'),
      '--promotion-banner-background-color': theme('colors.brand.sand.300'),
    },

    // brand blue
    '[data-promotion-banner-theme="blue-6"]': {
      '--promotion-banner-text-color': theme('colors.light'),
      '--promotion-banner-background-color': theme('colors.brand.blue.600'),
    },

    // brand green
    '[data-promotion-banner-theme="green-5"]': {
      '--promotion-banner-text-color': theme('colors.light'),
      '--promotion-banner-background-color': theme('colors.brand.green.500'),
    },
    '[data-promotion-banner-theme="green-6"]': {
      '--promotion-banner-text-color': theme('colors.light'),
      '--promotion-banner-background-color': theme('colors.brand.green.600'),
    },

    '.promotion-banner-container': {
      color: 'var(--promotion-banner-text-color)',
      backgroundColor: 'var(--promotion-banner-background-color)',
    },
  });
});
