import { CheckoutReducer } from 'src/typings/checkoutTypes';
import { MoltinTypes } from 'src/typings/moltinTypes';

export enum CheckoutActionTypes {
  ADD_CUSTOMER_DETAILS = 'checkout/ADD_CUSTOMER_DETAILS',
  ADD_SHIPPING_DETAILS = 'checkout/ADD_SHIPPING_DETAILS',
  ADD_BILLING_DETAILS = 'checkout/ADD_BILLING_DETAILS',
  ADD_CUSTOMER_DETAILS_AND_SAVE_TO_LOCAL_STORAGE = 'checkout/ADD_CUSTOMER_DETAILS_AND_SAVE_TO_LOCAL_STORAGE',
  ADD_SHIPPING_DETAILS_AND_SAVE_TO_LOCAL_STORAGE = 'checkout/ADD_SHIPPING_DETAILS_AND_SAVE_TO_LOCAL_STORAGE',
  ADD_BILLING_DETAILS_AND_SAVE_TO_LOCAL_STORAGE = 'checkout/ADD_BILLING_DETAILS_AND_SAVE_TO_LOCAL_STORAGE',
  CLEAN_STATE = 'checkout/CLEAN_STATE',
  SHOW_EXPRESS_CHECKOUT = 'checkout/SHOW_EXPRESS_CHECKOUT',
  HIDE_EXPRESS_CHECKOUT = 'checkout/HIDE_EXPRESS_CHECKOUT',
  MARK_IS_B2B = 'checkout/MARK_IS_B2B',
  UNMARK_IS_B2B = 'checkout/UNMARK_IS_B2B',
  SHOW_CART_SPINNER = 'checkout/SHOW_CART_SPINNER',
  HIDE_CART_SPINNER = 'checkout/HIDE_CART_SPINNER',
  SET_SOURCE_OBJECT = 'checkout/SET_SOURCE_OBJECT',
  SET_CHECKOUT_RESPONSE = 'checkout/SET_CHECKOUT_RESPONSE',
  START_SET_ACTIVE_CHECKOUT_STEP = 'checkout/START_SET_ACTIVE_CHECKOUT_STEP',
  FINISH_SET_ACTIVE_CHECKOUT_STEP = 'checkout/FINISH_SET_ACTIVE_STEP_COMPONENT',
  SET_CUSTOMER_IS_ADDED_TO_ABANDONED_CART_LIST = 'checkout/SET_CUSTOMER_IS_ADDED_TO_ABANDONED_CART_LIST',
  ADD_CUSTOMER_TO_ABANDONED_CART_LIST = 'checkout/ADD_CUSTOMER_TO_ABANDONED_CART_LIST',
  SHOULD_POST_TO_HUBSPOT = 'checkout/SHOULD_POST_TO_HUBSPOT',
}

export function addCustomerDetailsAndSaveToLocalStorage(customer: Partial<CheckoutReducer.Customer>) {
  return {
    type: CheckoutActionTypes.ADD_CUSTOMER_DETAILS_AND_SAVE_TO_LOCAL_STORAGE,
    payload: customer,
  };
}

export function addShippingDetailsAndSaveToLocalStorage(shippingDetails: CheckoutReducer.ShippingAddress) {
  return {
    type: CheckoutActionTypes.ADD_SHIPPING_DETAILS_AND_SAVE_TO_LOCAL_STORAGE,
    payload: shippingDetails,
  };
}

export function addBillingDetailsAndSaveToLocalStorage(billingDetails: CheckoutReducer.BillingAddress) {
  return {
    type: CheckoutActionTypes.ADD_BILLING_DETAILS_AND_SAVE_TO_LOCAL_STORAGE,
    payload: billingDetails,
  };
}

export function showCartSpinner() {
  return {
    type: CheckoutActionTypes.SHOW_CART_SPINNER,
  };
}

export function hideCartSpinner() {
  return {
    type: CheckoutActionTypes.HIDE_CART_SPINNER,
  };
}

export function showExpressCheckout() {
  return {
    type: CheckoutActionTypes.SHOW_EXPRESS_CHECKOUT,
  };
}

export function hideExpressCheckout() {
  return {
    type: CheckoutActionTypes.HIDE_EXPRESS_CHECKOUT,
  };
}

export function markIsB2b() {
  return {
    type: CheckoutActionTypes.MARK_IS_B2B,
  };
}

export function unMarkIsB2b() {
  return {
    type: CheckoutActionTypes.UNMARK_IS_B2B,
  };
}

export function setCheckoutResponse(checkoutResponse?: MoltinTypes.CheckoutResponse) {
  return {
    type: CheckoutActionTypes.SET_CHECKOUT_RESPONSE,
    payload: checkoutResponse,
  };
}

export function setActiveCheckoutStep(activeCheckoutStep: number) {
  return {
    type: CheckoutActionTypes.START_SET_ACTIVE_CHECKOUT_STEP,
    activeCheckoutStep,
  };
}

export function finishSetActiveCheckoutStep(activeCheckoutStep: number) {
  return {
    type: CheckoutActionTypes.FINISH_SET_ACTIVE_CHECKOUT_STEP,
    activeCheckoutStep,
  };
}

export function addCustomerToAbandonedCartList() {
  return {
    type: CheckoutActionTypes.ADD_CUSTOMER_TO_ABANDONED_CART_LIST,
  };
}
export function setCustomerToAbandonedCartList(customerIsAddedToAbandonedCartList: boolean) {
  return {
    type: CheckoutActionTypes.SET_CUSTOMER_IS_ADDED_TO_ABANDONED_CART_LIST,
    customerIsAddedToAbandonedCartList,
  };
}

export function addCheckoutToHubSpot() {
  return {
    type: CheckoutActionTypes.SHOULD_POST_TO_HUBSPOT,
  };
}
