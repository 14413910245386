import { Button, Link, Typography } from '@remarkable/ark-web';
import { ArrowLeft } from 'phosphor-react';
import { useCallback } from 'react';
import { useConsentBannerReducer } from 'src/components/CookieConsent/consentBannerState';
import { CookieGroupControl } from 'src/components/CookieConsent/CookieGroupControl';
import { useActiveBreakpoint } from 'src/hooks/useActiveBreakpoint';
import { isMobile } from 'src/utils/isMobile';

export const ConsentBannerSettingsView: React.FC<{
  backToMainView(): void;
}> = ({ backToMainView }) => {
  const { state, dispatch } = useConsentBannerReducer();

  const updateSelectionToOnlyNecessary = useCallback(() => {
    dispatch({
      type: 'UPDATE_COOKIE_GROUPS_CONSENT_STATE',
      payload: {
        necessary: true,
        marketing: false,
        performance: false,
      },
    });
  }, [dispatch]);
  const updateSelectionToAllowAll = useCallback(() => {
    dispatch({
      type: 'UPDATE_COOKIE_GROUPS_CONSENT_STATE',
      payload: {
        necessary: true,
        marketing: true,
        performance: true,
      },
    });
  }, [dispatch]);
  const allCookieGroupAllowed = !Object.values(state.cookieGroupsConsentState).some((value) => !value);
  const mobile = isMobile(useActiveBreakpoint());

  if (dispatch === Function.prototype) {
    throw Error('Default value received from useConsentBannerReducer, required context is missing');
  }

  return (
    <div
      className={'p-48 xl:p-64 max-md:p-24 max-md:max-h-[calc(100dvh-16px)] md:max-h-[calc(100dvh-24px)] flex flex-col'}
    >
      <div
        className={
          'flex justify-between pb-24 border-solid border-0 border-b-[1px] border-[var(--ark-interface-divider-default)]'
        }
      >
        <Typography variant={'heading-subtle-lg'} className={'md:heading-subtle-xl'}>
          Privacy settings
        </Typography>
        <Link
          as={'button'}
          className={'underline decoration-[var(--ark-interface-link-underline)]'}
          onClick={allCookieGroupAllowed ? updateSelectionToOnlyNecessary : updateSelectionToAllowAll}
        >
          {allCookieGroupAllowed ? (mobile ? 'Only necessary' : 'Only necessary cookies') : 'Accept all'}
        </Link>
      </div>
      <div className={'overflow-scroll'}>
        <CookieGroupControl
          name={'Necessary'}
          description={
            'Some cookies are necessary for the functioning of the website or for certain features. To ensure you can move between pages without losing information or that you stay logged in.'
          }
          controlDisabled={true}
          checked={state.cookieGroupsConsentState.necessary}
        />
        <CookieGroupControl
          name={'Performance'}
          description={
            'In order to optimize our website and related offers and to better adapt them to the needs of the users, we use cookies to record and analyze the use of our website, potentially beyond one session.'
          }
          checked={state.cookieGroupsConsentState.performance}
          updateChecked={(newValue) =>
            dispatch({ type: 'UPDATE_COOKIE_GROUPS_CONSENT_STATE', payload: { performance: newValue } })
          }
        />
        <CookieGroupControl
          name={'Marketing'}
          description={
            'We and our advertising partners have an interest in targeting advertising as precisely as possible, i.e. only showing it to those we wish to address.'
          }
          checked={state.cookieGroupsConsentState.marketing}
          updateChecked={(newValue) =>
            dispatch({ type: 'UPDATE_COOKIE_GROUPS_CONSENT_STATE', payload: { marketing: newValue } })
          }
        />
      </div>

      <div className={'flex max-sm:flex-col sm:justify-between mt-[32px] max-sm:mt-24 max-sm:gap-16'}>
        <Button
          variant={'tertiary-neutral'}
          size={'medium'}
          onClick={backToMainView}
          className={'max-sm:w-full max-sm:mx-0 max-sm:order-2'}
        >
          <ArrowLeft />
          <Typography variant={'interface-md-demi'} className={'inline-block'}>
            Back
          </Typography>
        </Button>
        <Button
          size={'medium'}
          className={'ml-[24px] max-sm:w-full max-sm:mx-0 max-sm:order-1'}
          onClick={() => dispatch({ type: 'SAVE_CONSENT_SETTINGS' })}
        >
          Save settings
        </Button>
      </div>
    </div>
  );
};
