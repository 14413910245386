import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions/yotpoActions';

const loadScript = () =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    // script.src = `//staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_APP_KEY}/widget.js`;
    script.src = `//staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_APP_KEY}/widget.js`; // for local testing
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(undefined);
    };
  });

function* scriptLoader() {
  try {
    yield put(actions.yotpoScriptLoadStart());
    yield call(loadScript);
    yield put(actions.yotpoScriptLoadEnd());
  } catch (error) {
    console.log(error);
  }
}

export default [takeLatest(actions.YOTPO_SCRIPT_LOAD, scriptLoader)];
