import { Logger } from 'src/utils/logger';
import { promiseInOrder } from 'src/utils/promiseInOrder';
import { v4 as uuidv4 } from 'uuid';

import { StorageKeys } from '../../helpers/localStorage';

export async function cartFetcher<T>(
  path: string,
  method: string,
  body?: any,
  storeHeaders?: { currency?: string; country?: string; region?: string }
): Promise<T> {
  return promiseInOrder('cartFetcher', async () => {
    const timeoutController = new window.AbortController();
    const requestTimeout = window.setTimeout(() => timeoutController.abort(), 20000);
    const url = `${process.env.NEXT_PUBLIC_RM_STORE_API_URL}${path}`;
    const res = await window.fetch(url, {
      method,
      body: body && JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        ...(storeHeaders?.currency && { 'X-STORE-CURRENCY': storeHeaders.currency }),
        ...(storeHeaders?.country && { 'X-STORE-COUNTRY': storeHeaders.country }),
        ...(storeHeaders?.region && { 'X-STORE-REGION': storeHeaders.region }),
      },
      signal: timeoutController.signal,
    });
    window.clearTimeout(requestTimeout);
    if (!res.ok) {
      throw Logger.warn({
        category: Logger.Category.API_REQUEST,
        message: `Cart API request failed with status ${res.status}`,
        context: {
          response: await res.json(),
          path,
          method,
        },
      });
    }
    return res.json();
  });
}

/** Cart id stored in memory to speed up access. Accessing LocalStorage in the render thread can be slow */
let inMemoryCartId: string | null = null;

/**
 * Clears cart id in LocalStorage and memory.
 */
export function clearCartId() {
  inMemoryCartId = null;
  try {
    localStorage.removeItem(StorageKeys.CART_ID);
  } catch (_) {}
}

/**
 * Retrieves cart id from LocalStorage or memory. If a cart id does not already exist,
 * a new uuid is generated and saved as the cart id.
 */
export function getCartId(): string {
  let localValue = inMemoryCartId;
  try {
    localValue = localStorage.getItem(StorageKeys.CART_ID);
  } catch (_) {}
  if (localValue) {
    try {
      // Old cart id in client from previous setup may be JSON stringified:
      return JSON.parse(localValue);
    } catch (_) {
      return localValue;
    }
  } else {
    const cartId = uuidv4();
    inMemoryCartId = cartId;
    try {
      localStorage.setItem(StorageKeys.CART_ID, cartId);
    } catch (_) {}
    return cartId;
  }
}
