export const DISMISS_NOTIFICATION_BAR = 'notificationsbar/DISMISS_NOTIFICATION_BAR';
export const ACTIVATE_NOTIFICATION_BAR = 'notificationsbar/ACTIVATE_NOTIFICATION_BAR';

export const dismiss = () => ({
  type: DISMISS_NOTIFICATION_BAR,
});

export const activate = () => ({
  type: ACTIVATE_NOTIFICATION_BAR,
});
