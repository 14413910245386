import { Types } from '@remarkable/rm-store-types';
import { GTMProductsData } from 'src/contexts/GTMProductsDataContext';
import { discardPriceFormatter, getFlattenedItems } from 'src/helpers/cartHelpers';

import type { CartProduct } from './types';

const getTrackingPrices = (gtmProductsData: GTMProductsData | null): Record<string, number> =>
  gtmProductsData?.epProducts.reduce((acc, cur) => {
    const amount = cur.price.find((price) => price.currency === 'USD')?.amount;
    return { ...acc, [cur.sku]: amount };
  }, {}) ?? {};

/** Returns USD price as dollars instead of cents */
export const parseUSDPrice = (priceInCents: number): number => Math.round(priceInCents) / Math.pow(10, 2);

/** Aggregates CartProducts price value */
export const getTrackingProductsTotal = (products: CartProduct[]): number =>
  products.reduce((acc, { price, quantity }) => (price ? acc + price * quantity : acc), 0);

/** Returns an array containing all the products in the cart along with their USD prices */
export const getTrackingProducts = (
  cart: Types.Store.Cart | undefined,
  gtmProductsData: GTMProductsData
): CartProduct[] => {
  if (!cart) return [];
  const trackingPrices = getTrackingPrices(gtmProductsData);
  return getFlattenedItems(cart, discardPriceFormatter).map((item) => {
    return {
      sku: item.sku,
      quantity: item.quantity,
      name: item.name,
      price: parseUSDPrice(trackingPrices[item.sku]),
      price_formatted: `${parseUSDPrice(trackingPrices[item.sku])}`,
    };
  });
};
