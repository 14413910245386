import { ConsentStatus } from '@remarkable/tracking-browser';
import { initTracker } from '@remarkable/tracking-browser/lib/ampli';
import {
  ConsentBannerReducerState,
  defaultConsentBannerReducerState,
  readConsentBannerReducerStateFromLocalStorage,
} from 'src/components/CookieConsent/consentBannerState';
import { FEATURE_CONSENT_2023_ENABLED } from 'src/components/CookieConsent/featureFlag';
import { isProduction } from 'src/utils/isProduction';

if (isProduction && !process.env.NEXT_PUBLIC_TRACKING_COOKIE_DOMAIN) {
  // Deployment without configured NEXT_PUBLIC_TRACKING_COOKIE_DOMAIN will not be able to share amplitude cookies
  // with my.remarkable
  console.error('Expected cookie domain configuration is absent');
}

/**
 * Takes consentBannerReducerState and translates into ConsentStatus that @remarkable/tracking-browser understands
 * @param consentBannerState
 */
const consentBannerStateToConsentStatus: (consentBannerState: ConsentBannerReducerState) => ConsentStatus = (
  consentBannerState
) => {
  if (!consentBannerState.consentAccepted) {
    return ConsentStatus.PENDING;
  }
  return consentBannerState.cookieGroupsConsentState.performance ? ConsentStatus.CONSENTED : ConsentStatus.REJECTED;
};

const consentStatus: ConsentStatus | null = (() => {
  if (!FEATURE_CONSENT_2023_ENABLED) {
    return ConsentStatus.CONSENTED;
  }
  if (typeof window === 'undefined') {
    return null;
  } else {
    return consentBannerStateToConsentStatus(
      readConsentBannerReducerStateFromLocalStorage() ?? defaultConsentBannerReducerState
    );
  }
})();

export const tracker = initTracker({
  apiEndpoint: process.env.NEXT_PUBLIC_RM_TRACKING_API ?? '',
  consentStatus: typeof window === 'undefined' ? null : consentStatus,
  ...(process.env.NEXT_PUBLIC_TRACKING_COOKIE_DOMAIN
    ? {
        cookieDomain: process.env.NEXT_PUBLIC_TRACKING_COOKIE_DOMAIN,
      }
    : {}),
});

export const updateTrackerConsentStatusWithConsentBannerState = (
  consentBannerReducerState: ConsentBannerReducerState
) => {
  const newConsentState = consentBannerStateToConsentStatus(consentBannerReducerState);
  const previousConsentState = tracker.settings.consentStatus;
  if (newConsentState !== previousConsentState) {
    tracker.updateUserConsentStatus(newConsentState);
  }
  if (previousConsentState !== ConsentStatus.CONSENTED && newConsentState === ConsentStatus.CONSENTED) {
    // Consent given for the first time, need call identify in the tracker
    tracker.identify({});
  }
};
