// https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case-in-javascript
export const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

// https://stackoverflow.com/questions/40710628/how-to-convert-snake-case-to-camelcase-in-my-app
export const snakeToCamelCase = (str: string): string =>
  str.toLowerCase().replace(/([-_][a-z])/g, (group: string) => group.toUpperCase().replace('-', '').replace('_', ''));

/**
 * Takes in any object and convert the text style of all properties
 * Options:
 * From camelCase -> snake_case
 * From snake_case -> camelCase
 * @param object any object
 * @param type toCamel or toSnake
 * @returns converted object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertObjectCase = (object: Record<string, any>, type: 'toCamel' | 'toSnake'): Record<string, any> => {
  const newObject: Record<string, unknown> = {};
  Object.keys(object).forEach((key) => {
    const newKey = type === 'toCamel' ? snakeToCamelCase(key) : camelToSnakeCase(key);
    if (object[key] instanceof Object) {
      newObject[newKey] = convertObjectCase(object[key], type);
    } else {
      newObject[newKey] = object[key];
    }
  });
  return newObject;
};
