import { useEffect } from 'react';

/**
 * Hook to call a function on esc-key!
 * Example use: Close drawer when Esc is pressed.
 * @param handleClose function to call when ESC i pressed
 * @param show (boolean): status for drawer/modal
 */
export const useHandleEscKey = (handleKeyDown: () => void, show: boolean) => {
  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && show) {
        handleKeyDown();
      }
    };

    document.addEventListener('keydown', escFunction);

    return () => document.removeEventListener('keydown', escFunction);
  }, [handleKeyDown, show]);
};
