import { Adapters, Types } from '@remarkable/rm-store-types';
import { PaymentSucceeded } from 'ampli-types';
import { WithGTMProductsData } from 'src/contexts/GTMProductsDataContext';
import { deviceVariants, markerTipsVariants, markerVariants } from 'src/data/productData';
import { getFlattenedItems, getNumberOfDevicesInCart, getSkuQuantities } from 'src/helpers/cartHelpers';
import { calculateVAT, getPriceAmountForSku, getProductGroupBySku } from 'src/helpers/storeHelpers';
import { tracker } from 'src/services/tracker';
import { getCurrentStore } from 'src/state/store';
import { SanityCurrency } from 'src/typings/sanityTypes';
import { Logger } from 'src/utils/logger';
import { sha256Hash } from 'src/utils/sha256Hash';
import { v4 } from 'uuid';

import { roasTrackEvent } from './rm-store-api';
import { getTrackingProducts, getTrackingProductsTotal } from './tracking/utils';

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

/**
 * What: Simple API to adapt events sent from Google Tag Manager to Facebook Pixel.
 * This is solely used to add the eventID metadata to events sent to Facebook
 * when applicable.
 *
 * Why: The current managed Facebook Pixel integration we use in Google Tag Manager
 * does not support the eventData field described here: https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events/
 * To resolve this problem, we add the eventID field to the eventParameters in the integration within GTM,
 * and then move this field over to the eventData object within this adapter.
 *
 * How: When GTM receives an event, we forward some of them to Facebook Pixel. These forwarded events
 * internally call window.fbq. This all happens within the GTM script/container; therefore, even if you do
 * not notice any explicit calls to window.fbq in the site's codebase, this adapter may still be in use
 *
 * Lifetime: This can be removed when the GTM integration we use is updated to support
 * eventData fields, or if we move to another solution later.
 */
const gtmFacebookPixelAdapter = (() => {
  let isAdapterActive = false;
  let adaptedFBQ: typeof window.fbq;
  let originalFBQ: typeof window.fbq;

  // Intercepts window.fbq(...) calls
  const adapter = (fbq: typeof window.fbq, ...fbqArgs: Parameters<typeof window.fbq>) => {
    try {
      // Assumption: the last argument of calls to fbq(...) is usually the eventProperties object (with event details)
      const lastArg = fbqArgs[fbqArgs.length - 1];
      // Check if our assumption is correct. If so, we continue to move properties from eventProperties to eventData
      if (lastArg && typeof lastArg === 'object') {
        const eventProperties = { ...lastArg };
        const argsBeforeProperties = fbqArgs.slice(0, -1);
        // Move all event properties that are prefixed with 'eventData.' to a new object to be passed as a proper eventData arg.
        const eventDataPrefix = 'eventData.';
        const eventData = Object.entries(eventProperties).reduce((acc, [key, value]) => {
          const shouldMove = key.startsWith(eventDataPrefix);
          if (shouldMove) {
            const newKey = key.replace(eventDataPrefix, '');
            acc[newKey] = value;
            delete eventProperties[key];
          }
          return acc;
        }, {});
        return fbq(...argsBeforeProperties, eventProperties, eventData);
      } else {
        return fbq(...fbqArgs);
      }
    } catch (err: any) {
      Logger.warn({
        category: Logger.Category.GOOGLE_TAG_MANAGER,
        message: err.message,
        exception: err,
        context: { fbqArgs },
      });
      return fbq(...fbqArgs);
    }
  };

  // Updates the internal adapter that intercepts window.fbq calls
  const handleFBQUpdate = (fbq: typeof window.fbq) => {
    // Disable automatic PageView tracking (we do this manually & do not want duplicate counts)
    // @see https://developers.facebook.com/ads/blog/post/v2/2017/05/29/tagging-a-single-page-application-facebook-pixel/
    if (fbq) fbq.disablePushState = true;
    if (window.fbq) window.fbq.disablePushState = true;
    // NB: Object.assign is critical here to completely mimic original fbq.
    adaptedFBQ = Object.assign(adapter.bind(fbq, fbq), fbq);
    originalFBQ = fbq;
  };

  // Initialization of adapter on window object.
  // Using Object getter/setter to intercept calls and updates to window.fbq
  if (typeof window !== 'undefined') {
    // First-time initialization of both originalFBQ and adapterFBQ
    handleFBQUpdate(window.fbq);
    Object.defineProperty(window, 'fbq', {
      get: () => (isAdapterActive && (originalFBQ as any) && (adaptedFBQ as any) ? adaptedFBQ : originalFBQ),
      set: handleFBQUpdate,
    });
  }

  // Return a Basic API for enabling/disabling adapter
  return {
    enable: () => (isAdapterActive = true),
    disable: () => (isAdapterActive = false),
  };
})();

// NB: If enabling is delayed after initial page load, there may be some missed events in window.fbq.queue
gtmFacebookPixelAdapter.enable();

export enum pageType {
  ARTICLE = 'Article',
  BUSINESS = 'Business',
  CHECKOUT = 'Checkout',
  CONTENT = 'Content',
  HOME = 'Home',
  OTHER = 'Other',
  PRODUCT_PAGE = 'Product Page',
  SUPPORT = 'Support',
}

export enum EECEventKeys {
  EEC_PRODUCT_DETAIL_VIEW = 'EECproductDetailView',
  EEC_ADD_TO_CART = 'EECaddToCart',
  EEC_REMOVE_FROM_CART = 'EECremoveFromCart',
  EEC_CHECKOUT_OPTION = 'EECcheckoutOption',
  EEC_CHECKOUT_STEP_EXPRESS = 'EECcheckoutStepExpress',
  EEC_CHECKOUT_STEP_1 = 'EECcheckoutStep1',
  EEC_CHECKOUT_STEP_2 = 'EECcheckoutStep2',
  EEC_CHECKOUT_STEP_3 = 'EECcheckoutStep3',
  EEC_CHECKOUT_STEP_4 = 'EECcheckoutStep4',
  EEC_PURCHASE = 'EECpurchase',
}

export const determinePageTypeForGA = (path: string) => {
  if (path.match(/\/store\//)) {
    return pageType.PRODUCT_PAGE;
  } else if (path.match(/\/checkout/)) {
    return pageType.CHECKOUT;
  } else if (path.match(/\/business/)) {
    return pageType.BUSINESS;
  } else if (path === '/') {
    return pageType.HOME;
  } else {
    return pageType.OTHER;
  }
};

export const determineInitialBreakpointForGA = () => {
  if (!window) {
    return null;
  }
  const w = window;
  const d = document;
  const documentElement = d.documentElement;
  const body = d.getElementsByTagName('body')[0];
  const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
  let breakpoint;
  if (width <= 768) {
    breakpoint = 'Mobile';
  } else if (width < 1024) {
    breakpoint = 'Tablet';
  } else if (width < 1366) {
    breakpoint = 'Desktop';
  } else if (width < 1979) {
    breakpoint = 'Desktop1366';
  } else if (width >= 1980) {
    breakpoint = 'Desktop1980';
  }
  return breakpoint;
};

export enum eventKeys {
  PAGE_META = 'Page meta',
  GA_EVENT = 'gaEvent',
  VIRTUAL_PAGE_VIEW = 'Virtual Page View',
}

export interface DataLayerEvent {
  event: eventKeys | EECEventKeys | string;
  [key: string]: any;
  /**
   * Event ID used for Facebook Conversion API de-duplication mainly.
   * Unique for each event. Randomly generated except for purchase events
   * for which the event_id will be the order_id.
   */
  event_id?: string;
}

export interface GADataLayerEvent extends DataLayerEvent {
  eventCategory: eventCategoryEnum;
  eventAction: eventActionEnum | string;
  eventLabel?: string | undefined;
  eventValue?: string | number | undefined;
}

export interface IEECProduct {
  name: string;
  id: string;
  price?: number;
  brand?: string;
  category?: string;
  variant?: string;
  dimension9?: boolean;
  dimension10?: number;
  dimension17?: string;
  dimension18?: string;
  quantity?: number;
}

export interface EECUserData {
  /** SHA256 hashed email */
  em: string;
  /** SHA256 hashed phone */
  ph: string;
}

export interface EECEvent extends DataLayerEvent {
  event: EECEventKeys;
  ecommerce: {
    currencyCode?: string;
    detail?: {
      products: (IEECProduct | null)[];
    };
    add?: {
      products: (IEECProduct | null)[];
    };
    remove?: {
      products: (IEECProduct | null)[];
    };
    checkout?: {
      actionField: { step: number; option?: string };
      products: (IEECProduct | null)[];
    };
    checkout_option?: {
      actionField: { step: number; option: string };
    };
    purchase?: {
      actionField: {
        id: string;
        affiliation: string;
        revenue: number;
        tax: number;
        shipping: number;
        user_data: EECUserData;
        coupon?: string;
      };
      products: (IEECProduct | null)[];
    };
  };
}

export enum eventCategoryEnum {
  B2B_MAIN_PAGE_INTERACTION = 'B2B Main Page Interaction',
  B2B_CONTACT_SALES_TEAM = 'Contact B2B Sales team',
  CTA = 'CTA',
  FAQ = 'FAQ',
  FAQ_NAVIGATION = 'FAQ Navigation',
  MAIN_NAVIGATION = 'Main Navigation',
  MAIN_PAGE_INTERACTION = 'Main Page Interaction',
  MAIN_PAGE_NAVIGATION = 'Main Page Navigation',
  CART_PAGE_NAVIGATION = 'Cart Page Navigation',
  MPF_INTERACTION = 'MPF Interaction',
  MPF_NAVIGATION = 'MPF Navigation',
  NAVIGATION = 'Navigation',
  NEWSLETTER = 'Newsletter',
  CHECKOUT_VAT_VALIDATION = 'Checkout VAT Validation',
  PAGE_INTERACTION = 'Page interaction',
  POPUP_INTERACTION = 'Popup Interaction',
  PRODUCT_PAGE = 'Product page',
  PRODUCT_PAGE_INTERACTION = 'Product Page Interaction',
  PRODUCT_PAGE_NAVIGATION = 'Product Page Navigation',
  SECTION_IMPRESSION = 'Section impression',
  CUSTOM_ECCOMERCE = 'Custom Ecommerce',
  ORDER_CONFIRMATION_PAGE = 'Order confirmation page',
  CHECKOUT_PAGE = 'Checkout page',
  CAREER_PAGES = 'Career pages',
  BLOG_INTERACTION = 'Blog interaction',
  USING_RM_INTERACTION = 'Using rM interaction',
  ENHANCED_E_COMMERCE = 'Enhanced eCommerce',
  CUSTOM_LANDING_PAGE = 'Custom Landing Page Interaction',
  VIDEO_VIEW = 'Video view',
  VIDEO_OPENED = 'Video opened',
}

export enum eventActionEnum {
  BOTTOM = 'Bottom',
  BUTTON = 'Button',
  BUY_NOW = 'Buy now',
  SHARE_REFERRAL = 'Share referral',
  CTA = 'CTA',
  ATF = 'ATF',
  FAQ = 'FAQ',
  SATISFACTION_GUARANTEE = 'Satisfaction Guarantee',
  FOOTER_NAVIGATION = 'Footer navigation',
  FREQUENTLY_ASKED_QUESTIONS = 'Frequently asked questions',
  LEARN_MORE = 'Learn more',
  MAIN_NAVIGATION = 'Main navigation',
  NAVIGATION = 'Navigation',
  ON_PRODUCT_PAGE = 'Product page',
  ON_MPF = 'MPF',
  PICTURE_INTERACTION = 'Picture interaction',
  VIDEO_INTERACTION = 'Video interaction',
  RECOMMENDATION = 'Recommendation',
  REVIEWS = 'Reviews',
  READ_MORE = 'Read more',
  SELECT_OPTION = 'Select option',
  SELECT_YOUR_LOCATION = 'Select your location',
  SLIDER_INTERACTION = 'Slider interaction',
  SUBMIT_SOI = 'Submit SOI',
  VAT_VALIDATION_VALID = 'VAT Validation - Valid',
  VAT_VALIDATION_INVALID = 'VAT Validation - Invalid',
  MARKER_STEP = 'Marker Step',
  MARKER_SELECTOR = 'Marker Selector',
  FOLIO_STEP = 'Folio Step',
  FOLIO_SELECTOR = 'Folio Selector',
  CONNECT_STEP = 'Connect Step',
  CONNECT_SELECTOR = 'Connect Selector',
  CONNECT_STEP_SLIDER_INTERACTION = 'Connect Step - Slider Interaction',
  MARKER_TIPS_SELECTOR = 'Marker Tips Selector',
  PURCHASE = 'Purchase',
  WHO_IS_PAYING = 'Who is paying for your device',
  PRESS_LOGO_HOVER = 'Press logo hover',
  PRESS_LOGO_CLICK = 'Press logo click',
  QUANTITY_DISCOUNT = 'Quantity Discount',
  SEEN_PAYMENT_OPTION = 'Seen payment option',
}

interface EECInputBase {
  country: string;
  currencyDetails: SanityCurrency | null;
  VATPercentage: number;
  shouldOmitVATFromTracking: boolean;
  county?: string;
}

export const getShouldOmitVATFromTracking = () =>
  !!getCurrentStore().getState().staticQuery.siteConfig?.trackOmittingVAT;

const sha256HashPII = (data: string): string => sha256Hash(data.toString().toLowerCase().trim());

export const pushToDataLayer = (event: DataLayerEvent | GADataLayerEvent) => {
  const purchaseOrderID = event.ecommerce?.purchase?.actionField?.id;
  const eventWithID: DataLayerEvent = { ...event, event_id: purchaseOrderID ?? v4(), framework: 'nextjs' };

  const shouldSendToServer =
    eventWithID.event === EECEventKeys.EEC_PURCHASE ||
    eventWithID.event === EECEventKeys.EEC_ADD_TO_CART ||
    eventWithID.event === EECEventKeys.EEC_CHECKOUT_STEP_2 ||
    eventWithID.event === EECEventKeys.EEC_CHECKOUT_STEP_EXPRESS ||
    eventWithID.event === EECEventKeys.EEC_CHECKOUT_STEP_4 ||
    eventWithID.event === EECEventKeys.EEC_PRODUCT_DETAIL_VIEW ||
    eventWithID.event === eventKeys.VIRTUAL_PAGE_VIEW ||
    eventWithID.eventAction === eventActionEnum.WHO_IS_PAYING;

  if (shouldSendToServer) {
    roasTrackEvent(eventWithID);
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventWithID);
};

export const pushGAEventToDataLayerWithCustomFields = (
  eventCategory: eventCategoryEnum,
  eventAction: eventActionEnum | string,
  customFields: { [key: string]: any },
  eventLabel?: string,
  eventValue?: string
): void => {
  const gaEvent: GADataLayerEvent = {
    event: 'gaEvent',
    eventCategory,
    eventAction,
    ...customFields,
    eventLabel,
    eventValue,
  };
  pushToDataLayer(gaEvent);
};

export const pushGAEventToDataLayer = (
  eventCategory: eventCategoryEnum,
  eventAction: eventActionEnum | string,
  eventLabel?: string,
  eventValue?: string | number
): void => {
  const gaEvent: GADataLayerEvent = {
    event: eventKeys.GA_EVENT,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
  };
  pushToDataLayer(gaEvent);
};

export const pushVirtualPageViewToDataLayer = (pagePath: string, customPageType?: string) => {
  const transformMPFPaths = (path: string) => {
    // We want to track which step in MPF we are on
    if (!path.startsWith('/store/configure')) return path;

    const search = path.split('?')[1] ?? '';
    const stepParam = search.split('&').find((param) => param.startsWith('step='));
    if (!stepParam) return path;

    const stepName = stepParam.split('=')[1];
    return `/store/configure/${stepName}`;
  };

  const transformedPath = transformMPFPaths(pagePath);

  const event = {
    event: eventKeys.VIRTUAL_PAGE_VIEW,
    pagePath,
    breakPoint: determineInitialBreakpointForGA(),
    pageType: customPageType ?? determinePageTypeForGA(transformedPath),
  };

  pushToDataLayer(event);
};

function castToEECobject({
  sku,
  country,
  currencyDetails,
  shouldOmitVATFromTracking,
  VATPercentage,
  quantity,
  gtmProductsData,
}: EECInputBase & { sku: string; quantity?: number } & WithGTMProductsData): IEECProduct | null {
  const allEpProducts = gtmProductsData.epProducts;
  const productsDetails = gtmProductsData.productDetails;

  const currency = currencyDetails?.value;
  const currencyExponent = currencyDetails?.exponent ?? 2;
  const unitPrice = currency ? getPriceAmountForSku(sku, currency, allEpProducts) : null;

  if (!unitPrice) {
    return null;
  }

  const amount = shouldOmitVATFromTracking
    ? calculateVAT(unitPrice.amount, country, VATPercentage).exclVAT
    : unitPrice.amount;
  const price = Math.round(amount) / Math.pow(10, currencyExponent);

  const epProduct = allEpProducts?.find((product) => product.sku === sku);
  const sanityProductDetails = productsDetails?.find((i) => i.sku === sku);

  const name = epProduct?.name;

  let productColor;
  let productMaterial;
  let variant;
  const productCategory = productsDetails ? getProductGroupBySku(productsDetails, sku) : undefined;
  if (Adapters.Moltin.Products.isDevice({ sku })) {
    // TODO: when selling refurbished devices again, update this
    variant = deviceVariants.NEW;
  } else if (Adapters.Moltin.Products.isFolio({ sku })) {
    variant = epProduct?.name;
    productColor = sanityProductDetails?.color;
    productMaterial = sanityProductDetails?.material;
  } else if (Adapters.Moltin.Products.isMarker({ sku })) {
    if (sku === 'RM200' || sku === 'RM210') {
      variant = markerVariants.MARKER;
    } else {
      variant = markerVariants.MARKER_PLUS;
    }
  } else if (Adapters.Moltin.Products.isMarkerTip({ sku })) {
    if (sku === 'RM400') {
      variant = markerTipsVariants.RM1_WHITE;
    } else {
      variant = markerTipsVariants.RM1_BLACK;
    }
  }

  // Name is required
  if (!name) {
    return null;
  }
  return {
    name,
    id: sku,
    price,
    brand: 'reMarkable',
    category: productCategory,
    variant,
    dimension9: false,
    dimension10: 0,
    dimension17: productColor,
    dimension18: productMaterial,
    ...(!!quantity && { quantity }),
  };
}

export const pushEECaddBundleToCart = ({
  skus,
  quantity,
  country,
  currencyDetails,
  VATPercentage,
  shouldOmitVATFromTracking,
  county,
  gtmProductsData,
}: EECInputBase & { skus: string[]; quantity?: number } & WithGTMProductsData) => {
  const currency = currencyDetails?.value;
  const eecObjects = skus.map((sku) =>
    castToEECobject({
      sku,
      country,
      currencyDetails,
      shouldOmitVATFromTracking,
      VATPercentage,
      county,
      quantity,
      gtmProductsData,
    })
  );
  if (eecObjects.some((obj) => obj === null) || !currency) {
    return;
  }
  const event: EECEvent = {
    event: EECEventKeys.EEC_ADD_TO_CART,
    ecommerce: {
      currencyCode: currency,
      add: {
        products: eecObjects,
      },
    },
    // We don't want to merge these products with last cart add event
    // Reference: https://www.simoahava.com/analytics/two-simple-data-model-tricks/
    _clear: true,
  };
  pushToDataLayer(event);
};

export const pushEECaddSingleItemToCart = ({
  sku,
  quantity,
  country,
  currencyDetails,
  VATPercentage,
  shouldOmitVATFromTracking,
  county,
  gtmProductsData,
}: EECInputBase & { sku: string; quantity?: number } & WithGTMProductsData) => {
  const eecObjectsArray = [
    castToEECobject({
      sku,
      country,
      currencyDetails,
      shouldOmitVATFromTracking,
      VATPercentage,
      county,
      quantity,
      gtmProductsData,
    }),
  ];
  const currency = currencyDetails?.value;
  if (!eecObjectsArray.length || !currency) {
    return;
  }
  const event: EECEvent = {
    event: EECEventKeys.EEC_ADD_TO_CART,
    ecommerce: {
      currencyCode: currency,
      add: {
        products: eecObjectsArray,
      },
    },
    // We don't want to merge these products with last cart add event
    // Reference: https://www.simoahava.com/analytics/two-simple-data-model-tricks/
    _clear: true,
  };
  pushToDataLayer(event);
};

export const pushEECremoveBundleFromCart = ({
  skus,
  quantity,
  country,
  currencyDetails,
  VATPercentage,
  shouldOmitVATFromTracking,
  county,
  gtmProductsData,
}: EECInputBase & { skus: string[]; quantity?: number } & WithGTMProductsData) => {
  const currency = currencyDetails?.value;
  const eecObjects = skus.map((sku) =>
    castToEECobject({
      sku,
      country,
      currencyDetails,
      VATPercentage,
      shouldOmitVATFromTracking,
      county,
      quantity,
      gtmProductsData,
    })
  );
  if (eecObjects.some((obj) => obj === null) || !currency) {
    return;
  }
  const event: EECEvent = {
    event: EECEventKeys.EEC_REMOVE_FROM_CART,
    ecommerce: {
      currencyCode: currency,
      remove: {
        products: eecObjects,
      },
    },
  };
  pushToDataLayer(event);
};

export const pushEECremoveSingleItemFromCart = ({
  sku,
  quantity,
  country,
  currencyDetails,
  VATPercentage,
  shouldOmitVATFromTracking,
  county,
  gtmProductsData,
}: EECInputBase & { sku: string; quantity?: number } & WithGTMProductsData) => {
  const eecObjectsArray = [
    castToEECobject({
      sku,
      country,
      currencyDetails,
      VATPercentage,
      shouldOmitVATFromTracking,
      county,
      quantity,
      gtmProductsData,
    }),
  ];
  const currency = currencyDetails?.value;
  if (!eecObjectsArray.length || !currency) {
    return;
  }
  const event: EECEvent = {
    event: EECEventKeys.EEC_REMOVE_FROM_CART,
    ecommerce: {
      currencyCode: currency,
      remove: {
        products: eecObjectsArray,
      },
    },
    // We don't want to merge these products with last cart remove event
    // Reference: https://www.simoahava.com/analytics/two-simple-data-model-tricks/
    _clear: true,
  };
  pushToDataLayer(event);
};

export const pushEECproductDetailViewToDatalayer = ({
  sku,
  country,
  currencyDetails,
  VATPercentage,
  shouldOmitVATFromTracking,
  county,
  gtmProductsData,
}: EECInputBase & { sku: string } & WithGTMProductsData) => {
  const currency = currencyDetails?.value;
  const EECProduct = castToEECobject({
    sku,
    country,
    currencyDetails,
    VATPercentage,
    shouldOmitVATFromTracking,
    county,
    gtmProductsData,
  });
  if (!EECProduct || !currency) {
    return;
  }
  const event: EECEvent = {
    event: EECEventKeys.EEC_PRODUCT_DETAIL_VIEW,
    ecommerce: {
      currencyCode: currency,
      detail: {
        products: [EECProduct],
      },
    },
    // We don't want to merge these product(s) with last product detail view event
    // Reference: https://www.simoahava.com/analytics/two-simple-data-model-tricks/
    _clear: true,
  };
  pushToDataLayer(event);
};

export const pushEECcheckoutStep = ({
  cart,
  step,
  eventKey,
  options,
  country,
  currencyDetails,
  VATPercentage,
  shouldOmitVATFromTracking,
  county,
  gtmProductsData,
}: EECInputBase & {
  cart: Types.Store.Cart;
  step: number;
  eventKey: EECEventKeys;
  options?: { newsletterSubscriber: boolean };
} & WithGTMProductsData) => {
  const skusWithQuantity = getSkuQuantities(cart);
  const flattenedEECProducts: (IEECProduct | null)[] = [];
  Object.entries(skusWithQuantity)
    .filter(([sku]) => sku.match(/RM/))
    .forEach(([sku, quantity]) => {
      flattenedEECProducts.push(
        castToEECobject({
          sku,
          country,
          currencyDetails,
          VATPercentage,
          shouldOmitVATFromTracking,
          county,
          quantity,
          gtmProductsData,
        })
      );
    });
  let event: EECEvent;
  if (options && options.hasOwnProperty('newsletterSubscriber')) {
    const option = options.newsletterSubscriber ? 'Subscribed' : 'Not subscribed';
    event = {
      event: eventKey,
      ecommerce: {
        currencyCode: cart.currency,
        checkout: {
          actionField: { step, option },
          products: flattenedEECProducts,
        },
      },
      newsletterSubscriber: true,
    };
  } else {
    event = {
      event: eventKey,
      ecommerce: {
        currencyCode: cart.currency,
        checkout: {
          actionField: { step },
          products: flattenedEECProducts,
        },
      },
      // We don't want to merge these products with last checkout step event
      // Reference: https://www.simoahava.com/analytics/two-simple-data-model-tricks/
      _clear: true,
    };
  }

  pushToDataLayer(event);
};

export const pushEECpurchaseEventToDataLayer = ({
  transactionId,
  revenue,
  tax,
  shippingCost,
  products,
  currencyCode,
  user_data,
}: EECInputBase & {
  transactionId: string;
  revenue: number;
  tax: number;
  shippingCost: number;
  products: IEECProduct[];
  currencyCode: string;
  user_data: EECUserData;
}) => {
  const event: EECEvent = {
    event: EECEventKeys.EEC_PURCHASE,
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id: transactionId,
          affiliation: 'remarkable.com',
          revenue,
          tax,
          shipping: shippingCost,
          user_data,
        },
        products,
      },
    },
    // We don't want to merge these products with last purchase event
    // Reference: https://www.simoahava.com/analytics/two-simple-data-model-tricks/
    _clear: true,
  };
  pushToDataLayer(event);
};

export const sendEventWithValueToGTM = ({
  event,
  eventCategory,
  eventLabel,
  eventValue,
}: {
  event: string;
  eventCategory: string;
  eventLabel: string;
  eventValue: string | number;
}) => {
  window.dataLayer.push({ event, eventCategory, eventLabel, eventValue, eventAction: event });
};

export const pushEECcheckoutOption = (step: any, checkoutOption: string) => {
  const event: EECEvent = {
    event: EECEventKeys.EEC_CHECKOUT_OPTION,
    ecommerce: {
      checkout_option: {
        actionField: { step, option: checkoutOption },
      },
    },
  };
  pushToDataLayer(event);
};

export const trackPurchase = ({
  moltinCheckoutResponse,
  cart,
  orderId,
  country,
  currencyDetails,
  VATPercentage,
  shouldOmitVATFromTracking,
  county,
  gtmProductsData,
  paymentMethod,
}: EECInputBase & {
  moltinCheckoutResponse?: Types.Moltin.Order.GET.Response;
  cart: Types.Store.Cart;
  orderId: string;
  paymentMethod: Types.Moltin.Order.Payment.Methods;
} & WithGTMProductsData) => {
  try {
    let sentTransactions = JSON.parse(localStorage.getItem('orderIds') || '[]');

    if (sentTransactions && sentTransactions.length > 0 && sentTransactions.indexOf(orderId) !== -1) {
      return;
    } else {
      const products = getFlattenedItems(cart, (amount, currency) => ({
        amount,
        currency,
        formatted: '',
      }))
        .filter(Adapters.Moltin.Products.isPhysical)
        .map((item) =>
          castToEECobject({
            sku: item.sku,
            quantity: item.quantity,
            currencyDetails,
            VATPercentage,
            shouldOmitVATFromTracking,
            country,
            county,
            gtmProductsData,
          })
        )
        .filter((obj) => obj !== null) as IEECProduct[];

      const numberOfDevicesPurchased = getNumberOfDevicesInCart(cart);

      if (numberOfDevicesPurchased >= 3) {
        pushGAEventToDataLayer(eventCategoryEnum.CUSTOM_ECCOMERCE, eventActionEnum.PURCHASE, '3 or more reMarkables');
      }

      sentTransactions = [...sentTransactions, orderId];
      localStorage.setItem('orderIds', JSON.stringify(sentTransactions));

      const currency = currencyDetails?.value ?? 'USD';
      const currencyExponent = currencyDetails?.exponent ?? 2;

      // Calculate tax paid on order & revenue
      // We subtract VAT from revenue because it is an "included tax", but not US sales tax because it is an "excluded tax"
      const order = moltinCheckoutResponse?.data;
      const salesTaxAmount = order?.meta.display_price.tax.amount ?? 0;
      const totalWithoutTax = order?.meta.display_price.without_tax.amount ?? 0;
      const discountValue = order?.meta.display_price.discount.amount ?? 0;
      const totalWithoutTaxAndDiscount = totalWithoutTax - discountValue;
      // TODO: use subscription adapter once merged in rm-store-types
      const subAmount = cart.items
        .filter((item) => item.sku.startsWith('SUB'))
        .reduce((sum, item) => sum + item.price.value.amount, 0);
      const totalWithoutTaxDiscountAndSubscription = totalWithoutTaxAndDiscount - subAmount;
      const taxVATAmount = calculateVAT(totalWithoutTaxDiscountAndSubscription, country, VATPercentage).amount;
      const cogsValue = shouldOmitVATFromTracking
        ? totalWithoutTaxDiscountAndSubscription - taxVATAmount
        : totalWithoutTaxDiscountAndSubscription;

      const totalRevenue = Math.round(cogsValue) / Math.pow(10, currencyExponent);
      const totalTax =
        Math.round((shouldOmitVATFromTracking ? taxVATAmount : 0) + salesTaxAmount) / Math.pow(10, currencyExponent);

      const shippingItem = cart.shipping.selected;
      const shippingCost = (shippingItem?.price.amount ?? 0) / Math.pow(10, currencyExponent);

      /**
       * Prepare user data for campaign audience matching
       * Phone number must not have any non-digit characters before hashing
       * @see https://community.tealiumiq.com/t5/Client-Side-Tags/Snap-Pixel-Tag-Setup-Guide/ta-p/30701#toc-hId-443515293 snapchat pixel hashing guidelines
       * @see https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/ facebook pixel hashing guidelines
       */
      const phone = moltinCheckoutResponse?.data?.shipping_address.phone_number.replace(/\D+/gi, () => '') ?? '';
      const email = moltinCheckoutResponse?.data?.customer.email.toLocaleLowerCase().trim() ?? '';
      const user_data: EECUserData = {
        em: sha256HashPII(email),
        ph: sha256HashPII(phone),
      };

      pushEECpurchaseEventToDataLayer({
        transactionId: orderId,
        revenue: totalRevenue,
        tax: totalTax,
        shippingCost,
        products,
        country,
        currencyDetails,
        VATPercentage,
        shouldOmitVATFromTracking,
        county,
        currencyCode: currency as string,
        user_data,
      });

      const trackingProducts = getTrackingProducts(cart, gtmProductsData);
      //Data sent to Amplitude should not contain billing information.
      const purchaseEvent = new PaymentSucceeded({
        payment_method: paymentMethod,
        order_id: orderId,
        revenue: getTrackingProductsTotal(trackingProducts),
        tax: totalTax,
        shipping_cost: shippingCost,
        products: trackingProducts,
        country,
        currency: currency as string,
        county,
      });
      tracker.trackEvent(purchaseEvent);
    }
  } catch (error) {
    console.error(
      'Something went wrong sending ecommerce event to GA.',
      error,
      moltinCheckoutResponse,
      cart,
      orderId,
      country
    );
  }
};
