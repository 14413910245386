import '@stripe/stripe-js';

import { FC } from 'react';
import { Provider } from 'react-redux';
import CountryDetector from 'src/components/CountryDetector';
import { BreakpointProvider } from 'src/contexts/BreakpointContext';
import { GTMProductsDataContext } from 'src/contexts/GTMProductsDataContext';
import { CommonProviderData } from 'src/queries/groq/commonProviderData';
import storeFactory, { internalStoreInstance } from 'src/state/store';

import { CartProvider } from './CartContext';

const WrapWithProviderWhenDataAvailable: FC<{ commonProviderData?: CommonProviderData }> = ({
  children,
  commonProviderData,
}) => {
  if (commonProviderData !== undefined) {
    const gtmProductsData = commonProviderData.gtmProductsData;
    const countryData: any = commonProviderData.countryData;
    const [siteConfig] = commonProviderData.siteConfig;
    const marketingOptIn: any = commonProviderData.marketingOptIn;
    const store = internalStoreInstance || storeFactory({ countryData, siteConfig, marketingOptIn });
    return (
      <Provider store={store}>
        <CartProvider>
          <GTMProductsDataContext.Provider value={gtmProductsData}>{children}</GTMProductsDataContext.Provider>
          <CountryDetector />
        </CartProvider>
      </Provider>
    );
  } else {
    return <>{children}</>;
  }
};

export const CommonProvider: FC<{ commonProviderData?: CommonProviderData }> = ({ children, commonProviderData }) => {
  return (
    <WrapWithProviderWhenDataAvailable commonProviderData={commonProviderData}>
      <BreakpointProvider>{children}</BreakpointProvider>
    </WrapWithProviderWhenDataAvailable>
  );
};
