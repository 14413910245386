import { call, put, select, takeEvery } from 'redux-saga/effects';
import { State } from 'src/redux/reducers';
import * as RmStoreAPI from 'src/services/rm-store-api';
import { trackCheckoutError } from 'src/services/tracking/trackEventMethods';
import { Logger } from 'src/utils/logger';

import { getCartId } from '../../hooks/cart/util';
import * as checkoutActions from '../actions/checkoutActions';

const getCustomer = (state: State): any => state.checkout.customer;
const getCustomerIsAddedToAbandonedCartList = (state: State): boolean =>
  state.checkout.customerIsAddedToAbandonedCartList;

export function* setActiveCheckoutStep({ activeCheckoutStep }: { activeCheckoutStep: number }): Generator {
  if (activeCheckoutStep > 0) {
    yield put(checkoutActions.addCustomerToAbandonedCartList());
  }

  if (activeCheckoutStep < 2) {
    yield put(checkoutActions.setCheckoutResponse(undefined));
  }

  yield put(checkoutActions.finishSetActiveCheckoutStep(activeCheckoutStep));
}

export function* addCustomerToAbandonedCartList(): Generator {
  try {
    const cartId = getCartId();
    const customer: any = yield select(getCustomer);
    const customerIsAddedToAbandonedCartList = yield select(getCustomerIsAddedToAbandonedCartList);

    if (!customer.email.isValid || !cartId) {
      throw new Error('Missing cartId or email not valid.');
    }

    if (!customerIsAddedToAbandonedCartList) {
      yield call(RmStoreAPI.addToMailchimpAbandonCartList as any, customer.email.value, cartId);
      yield put(checkoutActions.setCustomerToAbandonedCartList(true));
    }
  } catch (error: any) {
    Logger.warn({
      category: Logger.Category.CHECKOUT,
      message: 'Failed to add customer to abandoned cart list',
      exception: error,
    });
    trackCheckoutError('Failed to add customer to abandoned cart list', error);
  }
}

export default [
  takeEvery(checkoutActions.CheckoutActionTypes.START_SET_ACTIVE_CHECKOUT_STEP as any, setActiveCheckoutStep),
  takeEvery(checkoutActions.CheckoutActionTypes.ADD_CUSTOMER_TO_ABANDONED_CART_LIST, addCustomerToAbandonedCartList),
];
