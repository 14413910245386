// @refresh reset
import { Adapters, Types } from '@remarkable/rm-store-types';
import { TaxStatus } from 'src/components/Cart/Summary/types';
import { isTypeFolio } from 'src/helpers/typeFolioHelpers';
import { getQuantityDiscount } from 'src/views/Cart/quantity-discount/utils';

import { isConnectSku } from './storeHelpers';

const B2BTHRESHOLD = 4;
const CONNECT_PROMO_KEY = '12_MONTHS_CONNECT_INCLUDED';

export const discardPriceFormatter: Types.Common.PriceFormatterFunction = (amount, currency) => ({
  amount,
  currency,
  formatted: '',
});

export function getCartIsEmpty(cart?: Types.Store.Cart): boolean {
  return (cart?.bundles.length ?? 0) + (cart?.items.length ?? 0) === 0;
}

export function getNumberOfBundlesInCart(cart?: Types.Store.Cart): number {
  return cart?.bundles.reduce((sum, bundle) => sum + bundle.quantity, 0) ?? 0;
}

export function getNumberOfItemsInCart(cart?: Types.Store.Cart): number {
  const numberOfBundles = getNumberOfBundlesInCart(cart);

  const numberOfItems = cart?.items.reduce((sum, item) => sum + item.quantity, 0) ?? 0;
  return numberOfBundles + numberOfItems;
}

export function getAllItemsInCart(cart?: Types.Store.Cart): Types.Store.Cart.Items {
  return cart
    ? [...cart.items, ...cart.bundles.reduce((acc, bundle) => [...acc, ...bundle.items], [] as Types.Store.Cart.Items)]
    : [];
}

const formattedItemName = (itemName: string) => {
  const itemNameList = itemName.split(/[–-]/);

  const returnObj = {
    name: itemNameList[0].trimEnd(),
    itemInfo: '',
  };

  const indexRefurb = itemNameList.indexOf(' Refurbished');

  if (indexRefurb > 0) {
    itemNameList.splice(indexRefurb, 1);
  }

  itemNameList.map((item, index) => {
    if (index != 0) {
      returnObj.itemInfo += ' - ' + item.trim();
    }
  });

  return returnObj;
};

export const getFormattedItemName = (itemName: string): string => {
  return formattedItemName(itemName).name;
};

export const getItemInfo = (itemName: string): string | null => {
  const itemInfo = formattedItemName(itemName).itemInfo;
  return itemInfo == '' ? null : itemInfo;
};

export const getNumberOfDevicesInCart = (cart?: Types.Store.Cart): number => {
  return getAllItemsInCart(cart)
    .filter(Adapters.Moltin.Products.isDevice)
    .reduce((sum, item) => sum + item.quantity, 0);
};

export const getNumberOfNewDevicesInCart = (cart?: Types.Store.Cart): number => {
  return getAllItemsInCart(cart)
    .filter(Adapters.Moltin.Products.isDevice)
    .filter((item) => !Adapters.Moltin.Products.isRefurbished(item))
    .reduce((sum, item) => sum + item.quantity, 0);
};

export const getNumberOfRerubishedDevicesInCart = (cart?: Types.Store.Cart): number => {
  return getAllItemsInCart(cart)
    .filter(Adapters.Moltin.Products.isDevice)
    .filter(Adapters.Moltin.Products.isRefurbished)
    .reduce((sum, item) => sum + item.quantity, 0);
};

export function getFlattenedItems(
  cart: Types.Store.Cart | undefined,
  priceFormatter: Types.Common.PriceFormatterFunction
): Types.Store.Cart.Items {
  return cart ? Adapters.Store.Cart.getFlattenedItems(cart, priceFormatter) : [];
}

export function getSkuQuantities(cart?: Types.Store.Cart): { [key: string]: number } {
  if (!cart) {
    return {};
  }
  return Object.fromEntries(getFlattenedItems(cart, discardPriceFormatter).map((it) => [it.sku, it.quantity]));
}

export function getSkuQuantity(cart: Types.Store.Cart | undefined, sku: string): number {
  return Adapters.Moltin.Products.isBundle({ sku })
    ? cart?.bundles.find((bundle) => bundle.bundle === sku)?.quantity ?? 0
    : cart?.items.find((item) => item.sku === sku)?.quantity ?? 0;
}

export const getCartDeviceThresholdMet = (cart?: Types.Store.Cart, deviceLimit?: number): boolean => {
  if (deviceLimit) {
    return getNumberOfDevicesInCart(cart) >= deviceLimit;
  }
  return getNumberOfDevicesInCart(cart) >= 24;
};

export const getCartDeviceThresholdExceeded = (cart?: Types.Store.Cart, deviceLimit?: number): boolean => {
  if (deviceLimit) {
    return getNumberOfDevicesInCart(cart) > deviceLimit;
  }
  return getNumberOfDevicesInCart(cart) > 24;
};

export function getB2BThresholdMet(cart?: Types.Store.Cart): boolean {
  const numberOfDevices = getNumberOfDevicesInCart(cart);
  return numberOfDevices >= B2BTHRESHOLD;
}

export function getPromotionItems(
  cart: Types.Store.Cart | undefined,
  priceFormatter: Types.Common.PriceFormatterFunction
): { price: string; name: string }[] {
  // Needs to use startsWith, because while the Store API sends one merged entry with sku=BW22_BUNDLE,
  // on the OrderConfirmation page we get the data directly from ElasticPath, which uses the exact PromoCode as sku
  const promotionItems = cart?.discounts.applied.filter((prom) => !prom.sku.startsWith('QTY')) || [];

  const promoItems = promotionItems.map((promotionItem) => ({
    price: promotionItem.price.amount,
    name: promotionItem.name.startsWith('REFERRAL') ? 'Referral discount' : promotionItem.name,
  }));

  const totalDiscountPerPromotion = promoItems.reduce(
    (m, { name, price }) => m.set(name, (m.get(name) || 0) + price),
    new Map<string, number>()
  );

  const formatDiscount = ([name, price]) => ({
    name: name,
    price: priceFormatter(price, cart?.currency || promotionItems[0].price.currency).formatted,
  });

  const formattedPromoItems = Array.from(totalDiscountPerPromotion, formatDiscount);

  const allItems: { price: string; name: string }[] = [];
  allItems.push(...formattedPromoItems);

  const quantityDiscount = getQuantityDiscount(cart);
  const hasActionableDiscount = (quantityDiscount?.currentDiscountValue ?? -1) !== -1;
  if (quantityDiscount && hasActionableDiscount) {
    allItems.push(
      formatDiscount([
        quantityDiscount.currentDiscountCode + '% business discount applied',
        quantityDiscount.currentDiscountValue,
      ])
    );
  }

  return allItems;
}

export function getTaxStatus(cart: Types.Store.Cart | undefined, country: string, isCheckoutCart: boolean): TaxStatus {
  if (cart && cart.tax.amount > 0) {
    return { type: 'required' };
  }
  if (country === 'US') {
    if (isCheckoutCart) {
      return { type: 'address-required' };
    }
    return { type: 'calculated-in-checkout' };
  }
  return { type: 'included' };
}

export function getAmountOfConnectInCart(cart: Types.Store.Cart): number {
  return getFlattenedItems(cart, discardPriceFormatter)
    .filter((item) => isConnectSku(item.sku))
    .reduce((a, b) => a + b.quantity, 0);
}

export function getCartAllAppliedDiscounts(cart?: Types.Store.Cart): number | undefined {
  if (!cart) {
    return;
  }
  const totalDiscountAmount = Math.abs(
    cart.discounts.applied
      .filter((item) => item.sku !== CONNECT_PROMO_KEY)
      .reduce((amount, discount) => amount + discount.price.amount, 0)
  );
  // We use Math.abs here since we want to display a positive price where this is used. Maybe something we want to reconsider
  if (totalDiscountAmount > 0) {
    return totalDiscountAmount;
  }
  return;
}

export function findTypeFolioInCart(cart?: Types.Store.Cart): string | undefined {
  const isTypeFolioPredicate = (item: Types.Store.Cart.Item) => isTypeFolio(item.sku);

  return (
    cart?.items.find(isTypeFolioPredicate)?.sku ??
    cart?.bundles.find((bundle) => bundle.items.find(isTypeFolioPredicate))?.items.find(isTypeFolioPredicate)?.sku
  );
}
