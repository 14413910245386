import { Types } from '@remarkable/rm-store-types';
import { eventActionEnum, eventCategoryEnum, pushGAEventToDataLayer } from 'src/services/googleTagManager';

export type QuantityDiscount = {
  currentDiscountValue?: number;
  currentDiscountValueFormatted?: string;
  currentDiscountCode?: number;
  nextDiscountCode?: number;
  quantityBeforeNextDiscount: number;
};

const QUANTITY_DISCOUNT_5_PERCENT = 'QTY5';
const QUANTITY_DISCOUNT_10_PERCENT = 'QTY10';
const QUANTITY_DISCOUNT_20_PERCENT = 'QTY20';

const getDiscountCodeInNumber = (discountCode: string) => {
  if (discountCode === QUANTITY_DISCOUNT_5_PERCENT) return 5;
  if (discountCode === QUANTITY_DISCOUNT_10_PERCENT) return 10;
  if (discountCode === QUANTITY_DISCOUNT_20_PERCENT) return 20;
  return -1;
};

export function getQuantityDiscount(cart?: Types.Store.Cart): QuantityDiscount | undefined {
  let currentDiscountValue = -1;
  let currentDiscountCode = -1;
  let currentDiscountValueFormatted = '';
  let nextDiscountCode = -1;
  let quantityBeforeNextDiscount = -1;

  const quantityDiscountItem = cart?.discounts.applied.find((discount) => discount.sku.startsWith('QTY'));

  if (quantityDiscountItem) {
    currentDiscountCode = getDiscountCodeInNumber(quantityDiscountItem.sku);
    currentDiscountValue = quantityDiscountItem.price.amount ?? -1;
    currentDiscountValueFormatted = quantityDiscountItem.price.formatted;
  }

  const quantityDiscountNextTier = cart?.discounts.available.find((discount) => discount.sku.startsWith('QTY'));
  if (quantityDiscountNextTier) {
    nextDiscountCode = getDiscountCodeInNumber(quantityDiscountNextTier.sku);
    quantityBeforeNextDiscount = quantityDiscountNextTier.needs.devices;
  }

  const quantityDiscount: QuantityDiscount = {
    currentDiscountValue: currentDiscountValue !== -1 ? currentDiscountValue : undefined,
    currentDiscountValueFormatted: currentDiscountValueFormatted !== '' ? currentDiscountValueFormatted : undefined,
    currentDiscountCode: currentDiscountCode !== -1 ? currentDiscountCode : undefined,
    nextDiscountCode: nextDiscountCode !== -1 ? nextDiscountCode : undefined,
    quantityBeforeNextDiscount: quantityBeforeNextDiscount !== -1 ? quantityBeforeNextDiscount : 0,
  };

  const hasData = Object.keys(quantityDiscount).some((key) => !!quantityDiscount[key]);
  return hasData ? quantityDiscount : undefined;
}

export function getTextualDeviceAmountFromDiscountPercentageNumber(discountCodeNumber: number) {
  switch (discountCodeNumber) {
    case 5:
      return 'five';
    case 10:
      return '10';
  }
}

export function getQuantityDiscountHeaderText(nextDiscount: number) {
  return `Get ${nextDiscount}% off when purchasing ${getTextualDeviceAmountFromDiscountPercentageNumber(
    nextDiscount
  )} or more new devices`;
}

export function getQuantityDiscountButtonText(numberToAdd: number) {
  return `Add ${numberToAdd} ${numberToAdd > 1 ? 'new devices' : 'new device'}`;
}

export function trackNumberOfDevicesInCart(quantity?: number) {
  if (quantity === 5) {
    pushGAEventToDataLayer(
      eventCategoryEnum.CART_PAGE_NAVIGATION,
      eventActionEnum.QUANTITY_DISCOUNT,
      'User has 5 or more devices in cart'
    );
  } else if (quantity === 10) {
    pushGAEventToDataLayer(
      eventCategoryEnum.CART_PAGE_NAVIGATION,
      eventActionEnum.QUANTITY_DISCOUNT,
      'User has 10 or more devices in cart'
    );
  } else if (quantity === 24) {
    pushGAEventToDataLayer(
      eventCategoryEnum.CART_PAGE_NAVIGATION,
      eventActionEnum.QUANTITY_DISCOUNT,
      'User has 24 devices in cart'
    );
  }
}
