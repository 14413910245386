import { DEFAULT_COUNTRY } from 'src/constants';
import { ProductBase, ProductPrice } from 'src/queries/elasticpath/getEPProducts';
import { ProductDetail } from 'src/queries/groq/productDetails';
import { getCurrentStore } from 'src/state/store';
import { SanityCountry } from 'src/typings/sanityTypes';

import { connectPPSku, connectSku } from './connectHelpers';

export function getProductGroupBySku(
  allProductsDetails: { sku: string; groups: string[] }[],
  sku: ProductDetail['sku']
) {
  const product = allProductsDetails.find((p) => p.sku === sku);
  return product?.groups[0];
}

// TODO: move these from here...?
export function isConnectSku(sku: string): boolean {
  return sku === connectSku || sku === connectPPSku;
}

export function getPriceAmountForSku(
  sku: string,
  currency: string,
  allEpProducts?: { sku: string; price: ProductBase['price'] }[]
) {
  return allEpProducts?.find((product) => product.sku === sku)?.price.find((p) => p.currency === currency);
}

export const getCurrentCountryDataEntry = (country: string): SanityCountry | undefined => {
  const { countryData } = getCurrentStore().getState().staticQuery;
  return countryData?.find((c) => c.value === country);
};

export const getVATPercentageFromCountryDataEntry = (
  countryDataEntry: SanityCountry | undefined,
  county: string | undefined
): number => {
  const countryPercentage = countryDataEntry?.vat_percentage ?? 0;

  // For countries with sub-region VAT overrides (f.eks Canadian providences)
  const subregionOverride = countryDataEntry?.county_vat_overrides?.find((c) => c.region_id === county);
  const subregionPercentage = subregionOverride?.vat;

  // County/State VAT takes precedence over country VAT
  const percentage = subregionPercentage ?? countryPercentage;

  return percentage;
};

// /////////////
// TAX
// /////////////
export function calculateVAT(inclVAT: number, currency: string, VATPercentage: number) {
  const exclVAT = inclVAT / (1 + VATPercentage / 100);
  const amount = inclVAT - exclVAT;

  return {
    amount,
    inclVAT,
    exclVAT,
    percentage: VATPercentage,
    currency,
  };
}

/* TODO:
This function is intended to be single point of getting pricing info for a product, that includes promotion values like oldPrice and savedValue.
Promototion values are currently scoped out and will always return 0.

"products" won't be necessary when switching RmStoreApi
*/

export type ProductPricing = {
  sku: string;
  currency: string;
  referralCode: string;
  price: ProductPrice | undefined;
  oldPrice: {
    amount: 0;
    currency: string;
  };
  savedValue: {
    amount: 0;
    currency: string;
  };
  savedPercentage: {
    amount: 0;
    currency: string;
  };
};

export const getProductPricing = (
  sku: string,
  currency: string,
  epProducts: Pick<ProductBase, 'sku' | 'price'>[]
): ProductPricing => {
  const productPrice = epProducts?.find((_) => _.sku === sku)?.price.find((price) => price.currency === currency);

  // Temp: Format price object to match RmStoreApi result
  return {
    sku,
    currency,
    referralCode: '',
    price: productPrice,
    oldPrice: {
      amount: 0,
      currency,
    },
    savedValue: {
      amount: 0,
      currency,
    },
    savedPercentage: {
      amount: 0,
      currency,
    },
  };
};

export const getCountryInfoByValue = (countryData: SanityCountry[], country: string) => {
  const countryInfo = countryData.find((c) => c.value === country);
  const fallbackCountryInfo1 = countryData.find((c) => c.value === DEFAULT_COUNTRY);
  const fallbackCountryInfo2 = countryData[0];
  return countryInfo ?? fallbackCountryInfo1 ?? fallbackCountryInfo2;
};

export const getCountryInfoByIsoCodeNumeric = (countryData: SanityCountry[], regionId: string | undefined) => {
  if (!regionId) {
    return undefined;
  }

  // Default to Spain for Canary Islands and Spain as they have the same 'region_id'
  if (regionId === '000724') {
    return countryData.find((country) => country.value === 'ES');
  }

  /*
  The ISO 3166 numeric code is a three digit number, but the region id passed from the query parameter 'region_id' is prefixed with '000' to satisfy
  Googles requirement of a six-character identifier. It's not perfect, but we only check the end of the string and assume that if the last three characters match,
  it's the same country.
  */
  return countryData.find((country) => regionId.endsWith(country.isoCodeNumeric));
};
