import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_COUNTRY, REMARKABLE_COUNTRY_COOKIE, REMARKABLE_REGION_COOKIE } from 'src/constants';
import { loadState, saveState } from 'src/helpers/localStorage';
import { getCountryInfoByIsoCodeNumeric } from 'src/helpers/storeHelpers';
import { setCountryData } from 'src/redux/actions/countryDetectorActions';
import { setData as setNoShippingModalData } from 'src/redux/actions/noShippingModalActions';
import { State } from 'src/redux/reducers';
import { SanityCountry } from 'src/typings/sanityTypes';

export const GOOGLE_MERCHANT_REGION_ID_QUERY_PARAMETER = 'region_id';

type CountryCodeAvailable = {
  countryCode: string;
  showNoShippingModal: false;
};

type CountryCodeUnavailable = {
  countryCode: string;
  showNoShippingModal: true;
  requestedCountryCode: string | undefined;
};

type CountryCodeResult = CountryCodeAvailable | CountryCodeUnavailable;

const resolveCountryCode = (countryData: SanityCountry[], regionId: string | undefined): CountryCodeResult => {
  const countryCodeFromCookie = Cookies.get(REMARKABLE_COUNTRY_COOKIE);
  const countryCodeFromLocalStorage = loadState('country');

  const countryByRegionId = getCountryInfoByIsoCodeNumeric(countryData, regionId);

  // If a country is found for the region id, give it precedence over local storage and cookie value
  if (countryByRegionId) {
    return {
      countryCode: countryByRegionId.value,
      showNoShippingModal: false,
    };
  }

  if (countryCodeFromLocalStorage) {
    return {
      countryCode: countryCodeFromLocalStorage,
      showNoShippingModal: false,
    };
  }

  const countryInfo = countryData.find((c) => c.value === countryCodeFromCookie);

  // If country data not found in Sanity, we must show the NoShippingModal
  if (countryInfo === undefined) {
    return {
      countryCode: DEFAULT_COUNTRY,
      showNoShippingModal: true,
      requestedCountryCode: countryCodeFromCookie,
    };
  }

  // Default to US is country from cookie is undefined
  return {
    countryCode: countryCodeFromCookie ?? DEFAULT_COUNTRY,
    showNoShippingModal: false,
  };
};

const CountryDetector = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const routerIsReady = router.isReady;
  const regionId = router.query[GOOGLE_MERCHANT_REGION_ID_QUERY_PARAMETER] as string | undefined;
  const sanityCountryData = useSelector((state: State) => state.staticQuery.countryData);
  const countryHasBeenSet = useSelector((state: State) => state.countryDetector.hasBeenSet);

  useEffect(() => {
    if (!routerIsReady || countryHasBeenSet) {
      return; // Use default country until router is ready. Or return if country already is set
    }

    const countryCodeResult = resolveCountryCode(sanityCountryData, regionId);

    if (countryCodeResult.showNoShippingModal) {
      dispatch(
        setNoShippingModalData({
          show: true,
          country: countryCodeResult.requestedCountryCode,
          isNoShipping: true,
        })
      );
    } else {
      // store the country if we ship to it
      saveState('country', countryCodeResult.countryCode);
    }

    dispatch(
      setCountryData({
        country: countryCodeResult.countryCode,
        region: Cookies.get(REMARKABLE_REGION_COOKIE),
      })
    );
  }, [dispatch, regionId, routerIsReady, sanityCountryData, countryHasBeenSet]);

  return null;
};

export default CountryDetector;
