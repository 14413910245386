import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import persistState from 'redux-localstorage';
import createSagaMiddleware from 'redux-saga';

import { rootReducerFactory } from '../redux/reducers';
import { State, StaticQueryState } from '../redux/reducers/index';
import rootSaga from '../redux/sagas';

// There should only be one store instance at a time, which is initialized
// when the page first loads and storeFactory is called. This is it.
export let internalStoreInstance: Store<State> | undefined;

// This helper asserts that a store exists/has been initialized before allowing access.
// Mostly intended to please Typescript, but also for some added run-time safety
// Warning: do not use this to get access to store within react context.
// Use react-redux methods.
export function getCurrentStore(): Store<State> {
  if (internalStoreInstance === undefined) {
    throw new Error('Cannot access redux store before initialization');
  }
  return internalStoreInstance;
}

const sagaMiddleware = createSagaMiddleware();

const enhancers = (mockedState?: State) =>
  typeof window === 'undefined'
    ? [applyMiddleware(sagaMiddleware)]
    : [
        applyMiddleware(sagaMiddleware),
        persistState(['store2'], {
          key: 'store2',
          merge: (initialState: State | undefined | null, persistState: State | undefined | null) => ({
            ...initialState,
            ...persistState,
            ...mockedState,
            store2: {
              ...(initialState?.store2 || {}),
              ...(persistState?.store2 || {}),
              cartLoading: false,
            },
          }),
        }),
      ];

const storeFactory = (staticQueryData: StaticQueryState, mockedState?: State): Store<State> => {
  const rootEnhancer = composeWithDevTools(...enhancers(mockedState));
  const storeInstance = createStore(rootReducerFactory(staticQueryData), rootEnhancer);
  internalStoreInstance = storeInstance;

  sagaMiddleware.run(rootSaga);
  return storeInstance;
};

export default storeFactory;
