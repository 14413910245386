import * as actions from '../actions/yotpoActions';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
}

const initialState = {
  isLoaded: false,
  isLoading: false,
};

export default (state: State = initialState, action): State => {
  switch (action.type) {
    case actions.YOTPO_SCRIPT_LOAD_START:
      return { ...state, isLoading: true };
    case actions.YOTPO_SCRIPT_LOAD_END:
      return { ...state, isLoading: false, isLoaded: true };
    default:
      return { ...state };
  }
};
