import { all } from 'redux-saga/effects';

import affirmSagas from './affirmSagas';
import checkoutSagas from './checkoutSagas';
import countryDetectorSagas from './countryDetectorSagas';
import storeSagas2 from './storeSagas2';
import yotpoSagas from './yotpoSagas';

export default function* () {
  yield all([...countryDetectorSagas, ...yotpoSagas, ...storeSagas2, ...checkoutSagas, ...affirmSagas]);
}
