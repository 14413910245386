import { DEFAULT_COUNTRY } from 'src/constants';

import * as actions from '../actions/countryDetectorActions';

export interface State {
  country: string;
  region?: string;
  hasBeenSet: boolean; // country has been altered from the inital state
}

const initialState: State = {
  country: DEFAULT_COUNTRY,
  region: '',
  hasBeenSet: false,
};

const actionsMap = {
  [actions.SET_COUNTRY_DATA]: (state: State, payload: { countryData: Omit<State, 'hasBeenSet'> }) => {
    const countryData: State = {
      ...payload.countryData,
      hasBeenSet: true,
    };

    if (countryData) {
      return countryData;
    }
    return state;
  },
  [actions.CHANGE_REGION]: (state: State, payload: { region: string }) => ({
    ...state,
    region: payload.region,
  }),
};

export default (state = initialState, action: any = {}): State => {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
};
