const plugin = require('tailwindcss/plugin');
// add svg in here if possible?

module.exports = plugin(({ addComponents, theme }) => {
  addComponents({
    '[data-feedback-content-theme="dark-green"]': {
      '--feedback-content-text-color': theme('colors.grayscale.gray.50'),
      '--feedback-content-background-color': theme('colors.brand.green.800'),
      '--feedback-content-icon-text-color': theme('colors.grayscale.gray.50'),
      '--feedback-content-icon-background-color': theme('colors.brand.green.900'),
    },
    '[data-feedback-content-theme="light-green"]': {
      '--feedback-content-text-color': theme('colors.grayscale.gray.50'),
      '--feedback-content-background-color': theme('colors.brand.green.600'),
      '--feedback-content-icon-text-color': theme('colors.grayscale.gray.50'),
      '--feedback-content-icon-background-color': theme('colors.brand.green.900'),
    },
    '[data-feedback-content-theme="light-sand"]': {
      '--feedback-content-text-color': theme('colors.grayscale.gray.800'),
      '--feedback-content-background-color': theme('colors.brand.sand.100'),
      '--feedback-content-icon-text-color': theme('colors.grayscale.gray.800'),
      '--feedback-content-icon-background-color': theme('colors.brand.sand.500'),
    },
    '[data-feedback-content-theme="sustainability"]': {
      '--feedback-content-fill-color': theme('colors.brand.brown.700'),
    },
    '.feedback-content-container': {
      color: 'var(--feedback-content-text-color)',
      backgroundColor: 'var(--feedback-content-background-color)',
    },
    '.feedback-content-icon': {
      color: 'var(--feedback-content-icon-text-color)',
      backgroundColor: 'var(--feedback-content-icon-background-color)',
    },
    '.feedback-content-sustianbility-icon': {
      fill: 'var(--feedback-content-fill-color)',
    },
  });
});
