import { saveState, StorageKeys } from 'src/helpers/localStorage';
import { CheckoutReducer } from 'src/typings/checkoutTypes';
import { MoltinTypes } from 'src/typings/moltinTypes';

import { CheckoutActionTypes } from '../actions/checkoutActions';

export interface State {
  customer: CheckoutReducer.Customer;
  shippingAddress: CheckoutReducer.ShippingAddress;
  billingAddress: CheckoutReducer.BillingAddress;
  isLoading: boolean;
  isExpressCheckout: boolean;
  isB2bChecked: boolean;
  source?: any;
  checkoutResponse: MoltinTypes.CheckoutResponse | undefined;
  activeCheckoutStep: number;
  customerIsAddedToAbandonedCartList: boolean;
  shouldPostToHubSpot: boolean;
}

const reducerDataInitialState: CheckoutReducer.CheckoutObjectString = {
  value: '',
  errorMessage: '',
  isValid: false,
};

const defaultCountry = '';
const defaultCountryCode = '';

const initialState: State = {
  customer: {
    email: reducerDataInitialState,
    phoneNumber: reducerDataInitialState,
    phoneNumberCountry: {
      value: defaultCountry,
      errorMessage: '',
      isValid: false,
    },
    phoneNumberCountryCode: {
      value: defaultCountryCode,
      errorMessage: '',
      isValid: false,
    },
    allowEmailMarketing: {
      value: false,
      errorMessage: '',
      isValid: false,
    },
  },
  shippingAddress: {
    firstName: reducerDataInitialState,
    lastName: reducerDataInitialState,
    country: {
      value: defaultCountry,
      errorMessage: '',
      isValid: false,
    },
    state: reducerDataInitialState,
    addressLine1: reducerDataInitialState,
    addressLine2: reducerDataInitialState,
    city: reducerDataInitialState,
    zipCode: reducerDataInitialState,
  },
  billingAddress: {
    billingAddressSameAsShipping: {
      value: true,
      errorMessage: '',
      isValid: false,
    },
    billingFirstName: reducerDataInitialState,
    billingLastName: reducerDataInitialState,
    billingCountry: reducerDataInitialState,
    billingState: reducerDataInitialState,
    billingAddressLine1: reducerDataInitialState,
    billingAddressLine2: reducerDataInitialState,
    billingCity: reducerDataInitialState,
    billingZipCode: reducerDataInitialState,
  },
  isLoading: false,
  isExpressCheckout: true,
  isB2bChecked: false,
  source: undefined,
  checkoutResponse: undefined,
  activeCheckoutStep: 0,
  customerIsAddedToAbandonedCartList: false,
  shouldPostToHubSpot: true,
};

export default function reducer(state = initialState, action): State {
  switch (action.type) {
    case CheckoutActionTypes.ADD_CUSTOMER_DETAILS: {
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload,
        },
      };
    }
    case CheckoutActionTypes.ADD_SHIPPING_DETAILS: {
      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          ...action.payload,
        },
      };
    }
    case CheckoutActionTypes.ADD_BILLING_DETAILS: {
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          ...action.payload,
        },
      };
    }
    case CheckoutActionTypes.ADD_CUSTOMER_DETAILS_AND_SAVE_TO_LOCAL_STORAGE: {
      saveState(StorageKeys.CHECKOUT_DETAILS, {
        shippingAddress: { ...state.shippingAddress },
        billingAddress: { ...state.billingAddress },
        customer: {
          ...state.customer,
          ...action.payload,
        },
        shouldPostToHubSpot: state.shouldPostToHubSpot,
      });
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload,
        },
      };
    }
    case CheckoutActionTypes.ADD_SHIPPING_DETAILS_AND_SAVE_TO_LOCAL_STORAGE: {
      saveState(StorageKeys.CHECKOUT_DETAILS, {
        billingAddress: { ...state.billingAddress },
        customer: { ...state.customer },
        shippingAddress: {
          ...state.shippingAddress,
          ...action.payload,
        },
        shouldPostToHubSpot: state.shouldPostToHubSpot,
      });
      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          ...action.payload,
        },
      };
    }
    case CheckoutActionTypes.ADD_BILLING_DETAILS_AND_SAVE_TO_LOCAL_STORAGE: {
      saveState(StorageKeys.CHECKOUT_DETAILS, {
        shippingAddress: { ...state.shippingAddress },
        customer: { ...state.customer },
        billingAddress: {
          ...state.billingAddress,
          ...action.payload,
        },
        shouldPostToHubSpot: state.shouldPostToHubSpot,
      });
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          ...action.payload,
        },
      };
    }
    case CheckoutActionTypes.SHOW_EXPRESS_CHECKOUT: {
      return {
        ...state,
        isExpressCheckout: true,
      };
    }
    case CheckoutActionTypes.HIDE_EXPRESS_CHECKOUT: {
      return {
        ...state,
        isExpressCheckout: false,
      };
    }
    case CheckoutActionTypes.MARK_IS_B2B: {
      return {
        ...state,
        isB2bChecked: true,
      };
    }
    case CheckoutActionTypes.UNMARK_IS_B2B: {
      return {
        ...state,
        isB2bChecked: false,
      };
    }
    case CheckoutActionTypes.CLEAN_STATE: {
      saveState(StorageKeys.CHECKOUT_DETAILS, {
        shippingAddress: { ...initialState.shippingAddress },
        billingAddress: { ...initialState.billingAddress },
        customer: { ...initialState.customer },
        shouldPostToHubSpot: state.shouldPostToHubSpot,
      });

      return { ...initialState };
    }
    case CheckoutActionTypes.SHOW_CART_SPINNER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CheckoutActionTypes.HIDE_CART_SPINNER: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CheckoutActionTypes.SET_SOURCE_OBJECT: {
      return {
        ...state,
        source: action.payload,
      };
    }
    case CheckoutActionTypes.SET_CHECKOUT_RESPONSE: {
      return {
        ...state,
        checkoutResponse: action.payload,
      };
    }

    case CheckoutActionTypes.FINISH_SET_ACTIVE_CHECKOUT_STEP: {
      return {
        ...state,
        activeCheckoutStep: action.activeCheckoutStep,
      };
    }

    case CheckoutActionTypes.SET_CUSTOMER_IS_ADDED_TO_ABANDONED_CART_LIST: {
      return {
        ...state,
        customerIsAddedToAbandonedCartList: action.customerIsAddedToAbandonedCartList,
      };
    }
    case CheckoutActionTypes.ADD_CUSTOMER_TO_ABANDONED_CART_LIST: {
      return {
        ...state,
      };
    }
    case CheckoutActionTypes.SHOULD_POST_TO_HUBSPOT: {
      saveState(StorageKeys.CHECKOUT_DETAILS, {
        ...state,
        shouldPostToHubSpot: false,
      });
      return {
        ...state,
        shouldPostToHubSpot: false,
      };
    }
    default: {
      return state;
    }
  }
}
