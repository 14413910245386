import { State } from '../reducers/countryDetectorReducer';

export const SET_COUNTRY_DATA = 'COUNTRY/SET_COUNTRY_DATA';
export const CHANGE_REGION = 'COUNTRY/CHANGE_REGION';
export const CHANGE_COUNTRY = 'COUNTRY/CHANGE_COUNTRY';

export const setCountryData = (countryData: Omit<State, 'hasBeenSet'>) => {
  return {
    type: SET_COUNTRY_DATA,
    countryData,
  };
};
export const changeCountry = (country: string) => {
  return {
    type: CHANGE_COUNTRY,
    country,
  };
};
export const changeRegion = (region: string) => {
  return {
    type: CHANGE_REGION,
    region,
  };
};
