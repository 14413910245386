import { CableSkus, FolioSkus, MarkerSkus, MarkerTipSkus, TypeFolioSkus } from 'src/data/productData';

type DefaultFolio = {
  TYPE_FOLIO: TypeFolioSkus;
  FOLIO: Extract<FolioSkus, 'RM310' | 'RM312' | 'RM313' | 'RM314'>;
};

export const DEFAULT_COUNTRY = 'US';
export const REMARKABLE_COUNTRY_COOKIE = 'rm-country';
export const REMARKABLE_REGION_COOKIE = 'rm-region';

export const DEFAULT_MARKER: MarkerSkus = 'RM212';
export const DEFAULT_MARKER_TIP: MarkerTipSkus = 'RM413';
export const DEFAULT_CABLE: CableSkus = 'RM610';
export const DEFAULT_FOLIO: DefaultFolio = {
  TYPE_FOLIO: 'RM810',
  FOLIO: 'RM312',
};
