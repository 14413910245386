import { Types } from '@remarkable/rm-store-types';
import retry from 'async-retry';
import { saveState, StorageKeys } from 'src/helpers/localStorage';
import { getCartId } from 'src/hooks/cart/util';
import { TalonOneCustomerSession } from 'src/hooks/useApplyReferralCode';
import * as ElasticPathTypes from 'src/typings/elasticPathTypes';
import * as MailchimpTypes from 'src/typings/mailchimpTypes';
import { Logger } from 'src/utils/logger';

import { convertObjectCase } from '../../utils/caseTransformer';
import { AddressFormData } from '../../views/Checkout/CheckoutSteps-v2/types';
import { PaypalOrder } from '../../views/Checkout/paypal/helpers';
import { DataLayerEvent } from '../googleTagManager';
import { convertFieldNameToLocal, getGenericAddress, isNewEcomEnabled } from './helpers';

const RmStoreFetch = async (query: string, fetchOpts?: RequestInit) => {
  return retry(() => fetch(`${process.env.NEXT_PUBLIC_RM_STORE_API_URL}/${query}`, fetchOpts), { retries: 5 });
};

// /////////////////////////
// ROAS Server-side tracking
// /////////////////////////
const roasTrackEvent = async (event: DataLayerEvent): Promise<void> => {
  try {
    // For tracking, we don't really care if requests fail
    // We try our best to optimistically track, otherwise we move on
    const path = window.location.href.replace(window.location.origin, '');
    const body = JSON.stringify({ path, event });
    const response = await RmStoreFetch(`roas/event`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    if (response.status > 200) throw new Error(`Status code ${response.status}`);
  } catch (err: any) {
    Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to track ROAS event',
      exception: err,
    });
  }
};

// /////////////////////////
// Cart
// /////////////////////////

const applyReferralCode = async (
  referralCode: string,
  currency: string,
  cartId: string
): Promise<TalonOneCustomerSession> => {
  try {
    if (!referralCode) throw new Error('Missing input!');

    const response = await RmStoreFetch(`carts/${cartId}/referral`, {
      method: 'POST',
      headers: { [Types.Common.Http.Headers.currency]: currency, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        referralCode,
      }),
    });
    const data = await response.json();

    if (response?.status > 200) throw new Error(data.message);

    return data;
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to apply referral code',
      exception: error,
    });
  }
};

/**
 * Detect the correct endpoint version
 * - v2: uses EP. Default
 * - v3: uses CT. Feature gated
 */
const endpointVersion = isNewEcomEnabled ? 'v3' : 'v2';

const applyPromotionCode = async (promotionCode: string, currency: string, cartId: string): Promise<Response> => {
  try {
    if (!promotionCode) throw new Error('Missing input!');

    const response = await RmStoreFetch(`${endpointVersion}/carts/${cartId}/connect-promotion`, {
      method: 'POST',
      headers: { [Types.Common.Http.Headers.currency]: currency, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        promotionCode,
      }),
    });
    const data = await response.json();

    if (response?.status > 200) throw new Error(data.message);

    return data;
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to apply promotion code',
      exception: error,
    });
  }
};

// /////////////////////////
// Paypal
// /////////////////////////
export const updatePaypalShipping = async (
  cartId: string,
  paypalOrderId: string,
  address: {
    city: string;
    country_code: string;
    postal_code: string;
    state: string;
  }
): Promise<{
  cart: ElasticPathTypes.ResourcePage<ElasticPathTypes.CartItem>;
  order: PaypalOrder;
}> => {
  try {
    const response = await RmStoreFetch('paypal/shipping', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        paypal_order_id: paypalOrderId,
        cart_id: cartId,
        shipping: address,
      }),
    });
    return response.json();
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to update paypal shipping',
      exception: error,
    });
  }
};

const checkoutCart = async (
  body: {
    customer: ElasticPathTypes.CheckoutCustomerObject;
    billing_address: ElasticPathTypes.Address;
    shipping_address: ElasticPathTypes.Address;
    pay_by_invoice?: boolean;
    is_express_checkout?: boolean;
  },
  currency?: string
): Promise<Types.Moltin.Cart.Checkout.POST.Response> => {
  try {
    const cartId = getCartId();
    const headers = {
      'Content-Type': 'application/json',
      ...(currency ? { [Types.Common.Http.Headers.currency]: currency } : {}),
    };
    const res = await RmStoreFetch(`carts/${cartId}/checkout`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });

    const data = await res.json();
    if (!res.ok) {
      throw new Error(JSON.stringify(data));
    }

    // TODO: temporary workaround until order rewrite is complete
    // Save checkout response to localStorage so it is available to OC
    // Rewrite once order v2 endpoints are written
    saveState(StorageKeys.LAST_ORDER, data);

    return data;
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to checkout cart',
      exception: error,
    });
  }
};

const validateAddress = async (
  address: AddressFormData
): Promise<{
  valid: boolean;
  data: Types.Store.Address.Verification.POST.Response | undefined;
}> => {
  try {
    const body = JSON.stringify(convertObjectCase(getGenericAddress(address), 'toSnake'));
    const res = await RmStoreFetch(`v1/checkout/verify-address`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body,
    });

    if (res.ok) {
      // API returns 204 (No content), we should not try to read json
      return { valid: res.ok, data: undefined };
    }

    const data = await res.json();

    data.errors = data.errors.map((e) => {
      e.field = convertFieldNameToLocal(e.field);
      return e;
    });

    if (res.status === 400) {
      return { valid: res.ok, data };
    }

    // If the status code is not 204 or 400 throw error
    throw new Error(JSON.stringify(data));
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to validate address',
      exception: error,
    });
  }
};

// /////////////////////////
// Products
// /////////////////////////

const getProductReferralDiscounts = async (
  sku: string
): Promise<{ skuHasDiscount: false } | { skuHasDiscount: true; discounts: { currency: string; amount: number }[] }> => {
  try {
    const response = await RmStoreFetch(`products/${sku}/referral/discounts`);
    const data = await response.json();

    if (response?.status > 200) throw Error(data.message);

    return data;
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to get product referral discounts',
      exception: error,
    });
  }
};

// /////////////////////////
// Talon one
// /////////////////////////
const updateIdOnReferral = async (customerId: string, currency: string): Promise<void> => {
  try {
    const cartId = getCartId();
    const response = await RmStoreFetch(`talonone/update_customer_id/${cartId}`, {
      method: 'POST',
      headers: { [Types.Common.Http.Headers.currency]: currency, 'Content-Type': 'application/json' },
      body: JSON.stringify({
        customerID: customerId,
      }),
    });
    const data = await response.json();

    if (response?.status > 200) throw new Error(data.message);
  } catch (error: any) {
    Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to update customer id on referral',
      exception: error,
    });
  }
};

// /////////////////////////
// Stripe
// /////////////////////////
const authorizePayment = async (orderId: string): Promise<void> => {
  try {
    await RmStoreFetch(`orders/${orderId}/payments`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          gateway: 'manual',
          method: 'authorize',
        },
      }),
    });
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to authorize payment',
      context: { orderId },
      exception: error,
    });
  }
};

// /////////////////////////
// Mailchimp
// /////////////////////////

const addToMailchimpAbandonCartList = async (email: string, cartId: string): Promise<void> => {
  try {
    await RmStoreFetch(`mailchimp/add-to-abandoned-cart-list`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        cartId,
      }),
    });
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to add cart to abandoned cart list',
      context: { cartId },
      exception: error,
    });
  }
};

const updateMailchimpMember = async (
  email: string,
  tags: string[],
  mergeFields?: { [key in keyof typeof MailchimpTypes.MailchimpMergefieldsKeys]?: string }
): Promise<void> => {
  try {
    await RmStoreFetch(`mailchimp/update-list-member`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        tags,
        mergeFields,
      }),
    });
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to update mailchimp list member',
      context: { tags },
      exception: error,
    });
  }
};

const addCampaignIdToOrder = async (orderId: string, campaignId: string): Promise<void> => {
  try {
    await RmStoreFetch(`mailchimp/add-campaign-id-to-order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        campaignId,
      }),
    });
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to update mailchimp order.',
      context: { campaignId },
      exception: error,
    });
  }
};

const addCustomerToNewsletter = async (
  email: string,
  country: string,
  latitude: number | undefined,
  longitude: number | undefined
): Promise<void> => {
  console.log('mailchimp/add-customer-to-newsletter', { email });
  try {
    await RmStoreFetch(`mailchimp/add-customer-to-newsletter`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        country,
        latitude: latitude,
        longitude: longitude,
      }),
    });
  } catch (error: any) {
    throw Logger.warn({
      category: Logger.Category.API_REQUEST,
      message: 'Failed to add customer to newsletter.',
      context: { email, country, latitude, longitude },
      exception: error,
    });
  }
};

const getProducts = async (): Promise<Types.Moltin.Product[]> => {
  const data = await RmStoreFetch(`products`, {
    headers: { Authorization: process.env.RM_STORE_API_VERCEL_INTERNAL_KEY ?? '' },
  });
  const body = await data.json();
  return body.products;
};

const getPromotions = async (): Promise<Types.Moltin.Promotion[]> => {
  const data = await RmStoreFetch(`v1/promotions`);
  const body = await data.json();
  return body.data;
};

export {
  roasTrackEvent,
  checkoutCart,
  validateAddress,
  getProductReferralDiscounts,
  applyReferralCode,
  applyPromotionCode,
  authorizePayment,
  updateIdOnReferral,
  addToMailchimpAbandonCartList,
  addCampaignIdToOrder,
  addCustomerToNewsletter,
  updateMailchimpMember,
  getProducts,
  getPromotions,
};
